import { Container } from '@mui/material'
import { useGetAllPrivcayQuery } from '../src/APIs/privacyApi'
import { MultipleImages } from './MultipleImages'
import { SingleImage } from './SingleImage'
import { NoImage } from './NoImage'
import { Types } from './PrivacyTypes'
export const Privacy = (type: Types) => {
  const { data } = useGetAllPrivcayQuery(undefined)
  console.log({data});
  
  return (
    <Container sx={{ my: 8 }}>
      {/* {type.multipleImages && (
        <MultipleImages data={data?.data[0]} imgs={type.images} />
      )}
      {type.singleImage && <SingleImage data={data?.data[0]} />} */}
      {type.noImage && <NoImage data={data?.data[0]} />}
    </Container>
  )
}

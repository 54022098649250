import { Box, Stack, Typography } from '@mui/material'
// eslint-disable-next-line import/no-unresolved
import { AboutColors, serviceItem } from '../AboutTypes'
import { useTranslation } from 'react-i18next'
interface servicesProps {
  colors: AboutColors
}
export const Services = ({ colors }: servicesProps) => {
  const [, { language: lang }] = useTranslation()
  const data = [
    {
      title_en: 'lorem ipsum',
      title_ar: 'لورم ایپسوم',
      desc_en: 'lorem ipsum corencs dsk',
      desc_ar: 'لورم ایپسوم متن ساختگی',
    },
    {
      title_en: 'lorem ipsum',
      title_ar: 'لورم ایپسوم',
      desc_en: 'lorem ipsum corencs dsk',
      desc_ar: 'لورم ایپسوم متن ساختگی',
    },
    {
      title_en: 'lorem ipsum',
      title_ar: 'لورم ایپسوم',
      desc_en: 'lorem ipsum corencs dsk',
      desc_ar: 'لورم ایپسوم متن ساختگی',
    },
  ]
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={'space-between'}
      columnGap={5}
      rowGap={2}
      sx={{ direction: lang === 'en' ? 'ltr' : 'rtl' }}
    >
      {data?.map((item: serviceItem, index: number) => (
        <Stack
          key={item?.title_en}
          flex={1}
          direction={'row'}
          alignItems={'center'}
          // spacing={2}
          gap={3}
          sx={{
            bgcolor: index === 1 ? colors.bgColor1 : colors.bgColor2,
            py: { xs: 2, lg: 2.5 },
            px: { xs: 2, lg: 6 },
          }}
        >
          <Box sx={{ bgcolor: 'black', width: '20px', height: '20px' }} />
          <Box>
            <Typography
              sx={{
                fontSize: { xs: '1.1rem', md: '1.4rem', lg: '1.8rem' },
                color: colors.titleColor,
              }}
            >
              {lang === 'en' ? item?.title_en : item?.title_ar}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '.9rem', md: '1.1rem', lg: '1.4rem' },
                color: colors.descColor,
              }}
            >
              {lang === 'en' ? item?.desc_en : item?.desc_ar}
            </Typography>
          </Box>
        </Stack>
      ))}
    </Stack>
  )
}

import { Box, Container, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Card } from '../../Cards/Card'
import { use } from 'i18next'
import {
  useGetMostNewiestProductsQuery,
  useGetMostSellingProductsQuery,
} from '../../src/APIs/ProductApis'
import { getData } from './Data'
import { useTranslation } from 'react-i18next'
import { CardColors } from '../../Cards/CardTypes'
export type FixedCardProps = {
  title_en: string | undefined
  title_ar: string | undefined
  card: {
    colors: CardColors
    wide?: boolean
    border?: boolean
    borderRadius?: boolean
    width?:
      | string
      | number
      | {
          xs?: string | number
          sm?: string | number
          md?: string | number
          lg?: string | number
        }
    height?:
      | string
      | number
      | {
          xs?: string | number
          sm?: string | number
          md?: string | number
          lg?: string | number
        }
    title?: boolean
    desc?: boolean
    button?: boolean
    favorite?: boolean
    hoverWithFav?: boolean
    hover?: boolean
  }
}

const FixedCard = (props: FixedCardProps) => {
  const [, { language: lang }] = useTranslation()
  const Data = getData(props.title_en)

  return (
    <Box my={5}>
      {Data?.length > 0 && (
        <>
          <Box
            sx={{
              textAlign: 'center',
              mb: 7,
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '1.8rem', sm: '2.5rem', lg: '4rem' },
                fontWeight: 'bold',
                color: '#544e5e',
              }}
            >
              {lang === 'en' ? props.title_en : props.title_ar}
            </Typography>
          </Box>

          <Box>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              width={'80%'}
              mx={'auto'}
              justifyContent={{ xs: 'center', md: 'space-around' }}
              alignItems={{ xs: 'center', md: 'center' }}
              gap={2}
            >
              {Data?.map((item: any) => (
                <Box>
                  <Card
                    data={item}
                    colors={props.card.colors}
                    border={props.card.border}
                    borderRadius={props.card.borderRadius}
                    wide={props.card.wide}
                    width={props.card.width}
                    height={props.card.height}
                    title={props.card.title}
                    desc={props.card.desc}
                    button={props.card.button}
                    favorite={props.card.favorite}
                    hoverWithFav={props.card.hoverWithFav}
                    hover={props.card.hover}
                  />
                </Box>
              ))}
            </Stack>
          </Box>
        </>
      )}
    </Box>
  )
}

export default FixedCard

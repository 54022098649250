import { Box } from '@mui/material'
import React from 'react'
import MostSellerCollection from './MostSellerCollection'
import MostSellerScroll from './MostSellerScroll'
import NewestScroll from '../Newest/NewestScroll'
import { sectionsCardType } from '../HomeTypes'
import OffersCollection from '../Offers/OffersCollection'
import TrendingCollection from '../Trending/TrendingCollection'
import OffersScroll from '../Offers/OffersScroll'
import TrendingScroll from '../Trending/TrendingScroll'
import NewestCollection from '../Newest/NewestCollection'

interface MostSellerProps {
type:string
   backgroundColor?:string
   typeScroll?:string
}

const MostSeller = (props:sectionsCardType) => {
   
  
    
  return (
    <Box mb={15}sx={{
        backgroundColor:props.backgroundColor?props.backgroundColor:'#ffff',
      }} >
          {props.type==='MostSellerCollections'&&props.typeCollections==="Newest"&&(
              <NewestCollection card={props.card}  title={props.type} color={props.color} ADS={props.ADS} BtnCollections={props.BtnCollections}/>
          )}
          {props.type==='MostSellerCollections'&&props.typeCollections==="MostSeller"&&(
              <MostSellerCollection card={props.card}  title={props.type} color={props.color} ADS={props.ADS} BtnCollections={props.BtnCollections}/>
          )}
          {props.type==='MostSellerCollections'&&props.typeCollections==="Offers"&&(
              <OffersCollection card={props.card}  title={props.type} color={props.color} ADS={props.ADS} BtnCollections={props.BtnCollections}/>
          )}
          {props.type==='MostSellerCollections'&&props.typeCollections==="Trending"&&(
              <TrendingCollection card={props.card}  title={props.type} color={props.color} ADS={props.ADS} BtnCollections={props.BtnCollections}/>
          )}
  
  
  
          {/* scroll Newest  */}
          {props.type==='MostSellerScroll'&&props.typeScroll==="Newest"&&(
              <NewestScroll card={props.card} borderRaduisBtn={props.borderRadiusBtn} color={props.color} BtnCollections={props.BtnCollections}/>
          )}
  
          {props.type==='MostSellerScroll'&&props.typeScroll==="MostSeller"&&(
              <MostSellerScroll card={props.card} color={props.color} title={props.type}/>
          )}
         
  
          {props.type==='MostSellerScroll'&&props.typeScroll==="Offers"&&(
              <OffersScroll card={props.card} color={props.color} title={props.type}/>
          )}
          {props.type==='MostSellerScroll'&&props.typeScroll==="Trending"&&(
              <TrendingScroll card={props.card} color={props.color} title={props.type}/>
          )}
       
      </Box>
  )
}

export default MostSeller
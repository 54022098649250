import { Box, Button } from '@mui/material'
import React from 'react'
import VideoBack from './BackVideo/VideoBack'
import { heroVideoProps } from './HeroTypes'
import { useNavigate } from 'react-router-dom'

const Hero5 = (props:heroVideoProps) => {
  const navigate=useNavigate()
  const colors = {
    primary: 'white',
    secondary: '#e3e3e3',
    backgroundPrimary: '#1f1f1f',
    backgroundSecondary: '#d2d9d5',
  }

  return (
    <Box
      sx={{
        minHeight: { xs: '85vh', md: '100vh' },
        width: '100%',
        position: 'relative',
      }}
    >
        
      {/* Video */}
      <VideoBack video={props.backgroundVideo}/>

      <Box
        sx={{
          position: 'absolute',
          bottom: { xs: '20%', md: '10%' },
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <Button
         onClick={()=>navigate("/aboutUs")}
          variant="contained"
          sx={{
            color:props.Btn?.colorBtn?props.Btn.colorBtn: colors.primary,
            backgroundColor:props.Btn?.backgroundColorBtn?`${props.Btn.backgroundColorBtn} !important`: `${colors.backgroundPrimary} !important`,
            p: '12px 45px',
            borderRadius:props.Btn?.borderRaduisBtn? '50px':'5px',
            fontSize: { xs: '1rem', md: '1.2rem' },
            textTransform: 'capitalize'
          }}
        >
          {props.Btn?.titleBtn?props.Btn.titleBtn: 'lorem'}
        </Button>
      </Box>
    </Box>
  )
}

export default Hero5

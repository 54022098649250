import React from 'react'
import { VisionProps } from './VisionType'
import { Box, Typography, Grid, Button, Stack } from '@mui/material'
import { useGetAboutUsDataQuery } from '../../../src/APIs/aboutUsApi'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Vision = (props: VisionProps) => {
  const { data, isSuccess, isError, error } = useGetAboutUsDataQuery(undefined)
  const [_, { language: lang }] = useTranslation()
  const navigate = useNavigate()
  const desc = data?.data[0][`description_${lang}`]
  const title = data?.data[0][`title_${lang}`]
  const colors = {
    primary: 'gray',
    secondary: '#e3e3e3',
    backgroundPrimary: '#292a2d',
    backgroundSecondary: '#97979763',
  }

  return (
    <Box
      sx={{
        direction: lang === 'en' ? 'ltr' : 'rtl',
        mt:10,
      }}
    >
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {isSuccess && data?.data.length > 0 && (
        <>
        <Box
            sx={{
              textAlign: 'center',
              mb: 5,
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '30px', md: '35px', lg: '45px' },
                fontWeight: 'bold',
                color: props.color?.title
              }}
            >
              {lang === 'en' ? 'Vision' : 'رؤيتنا'}
            </Typography>
          </Box>
        <Grid
          container
          gap={{ xs: 2, md: 0 }}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
            width: { xs: '100%', md: '90%' },
            mx: 'auto',
          }}
        >
          {/* Description */}

          <Grid
            item
            xs={12}
            md={6}
            px={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              width={{ xs: 1, md: 1 }}
              mx={'auto'}
              sx={{
                px: 3,
                textAlign: { xs: 'center', md: 'initial' },
                height: '100%',
                backgroundColor: props.background
                  ? props.background
                  : colors.backgroundSecondary,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box mt={5}>
                {/* Main Title */}
                <Typography
                  variant={'h3'}
                  sx={{
                    color: props.color?.title
                      ? props.color.title
                      : colors.primary,
                    fontSize: { xs: '30px', sm: '25px', lg: '40px' },
                    fontWeight: 'bold',
                  }}
                >
                  {title}
                </Typography>

                {/* subTitle */}
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    fontSize: { xs: '15px', md: '17px', lg: '20px' },
                    color: props.color?.subTitle
                      ? props.color.subTitle
                      : `${colors.primary}`,
                    wordBreak: 'break-word',
                  }}
                  // i need to make it end "..."
                  dangerouslySetInnerHTML={{
                    __html: desc ? `${desc.slice(0, 100)}` : '',
                  }}
                ></Typography>
              </Box>

              {props.button && (
                <Box
                  sx={{
                    mt: 1,
                    mb: 2,
                  }}
                >
                  <Button
                    onClick={() => navigate('/aboutUs')}
                    variant="contained"
                    sx={{
                      // fontSize: { xs: '15px', md: '18px' },
                      color: props.button.color
                        ? `${props.button.color}`
                        : `${colors.secondary}!important`,
                      backgroundColor: props.button.backgroundColor
                        ? `${props.button.backgroundColor} !important`
                        : `${colors.backgroundPrimary} !important`,
                      p: { xs: '10px 20px', lg: '8px 40px' },
                      borderRadius: props.button.borderRadius ? '50px' : '5px',
                      textTransform: 'capitalize',
                    }}
                  >
                    {lang === 'en' ? 'Read More' : 'اقرأ المزيد'}
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>

          {/* image or video */}

          <Grid item xs={12} md={6} px={2}>
            {props.image && (
              <Box>
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  src={props.image}
                  alt="about image"
                />
              </Box>
            )}

            {props.video && (
              <Box height={"100%"}>
                <video
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  src={props.video}
                  autoPlay
                  muted
                  loop
                />
              </Box>
            )}
          </Grid>
        </Grid>
        </>
      )}
    </Box>
  )
}

export default Vision

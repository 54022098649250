import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from './baseUrl'

const privacyApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  reducerPath: 'privacy',
  tagTypes: ['Privacy'],
  endpoints: (builder) => ({
    getAllPrivcay: builder.query({
      query: () => `/sections?type=privacy`,
      providesTags: ['Privacy'],
    }),
  }),
})
export const { useGetAllPrivcayQuery } = privacyApi
export default privacyApi

import React from 'react'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import image from '../hero.jpg'
import data from '../data.json'
import { useTranslation } from 'react-i18next'
import { useGetAllSlidersQuery } from '../../src/APIs/SectionApis'
import { heroSolidProps } from './HeroTypes'
import { useNavigate } from 'react-router-dom'
import { imageBaseUrl } from '../../src/App'
const Hero4 = (props: heroSolidProps) => {
  const navigate = useNavigate()
  const [_, { language: lang }] = useTranslation()
  const { data, isError,isLoading,error } = useGetAllSlidersQuery(undefined)
  const dataHero = data?.data.length - 1 //last item in array
  const colors = {
    primary: 'gray',
    secondary: '#e3e3e3',
    backgroundPrimary: '#292a2d',
    backgroundSecondary: '#d2d9d5',
  }

  return (
    <Box minHeight={"100vh"}>
     {isLoading && <span className="loader"></span>}
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
           {(error as any)?.data&& (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isLoading && !isError && (

      <Box
        sx={{
          height: { xs: '80vh', md: '100vh' },
          width: '100%',
          background: props.backgroundColor
            ? props.backgroundColor
            : 'linear-gradient(90deg ,#d3d3d3 35%,white 30%)',
          position: 'relative',
        }}
      >
        <Grid
          container
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          {/* image */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              alignItems: 'center',
              display: 'flex',
              px: 3,
              justifyContent:{xs:"center",md:'flex-end'}
            }}
          >
            <Box sx={{
              width: { xs:0.7, md: 0.6,lg:0.8,xl:0.9 },
              height: { xs: 0.8, md: 0.6 ,lg:0.8,xl:0.8},
            }}>
              <img
               src={`${imageBaseUrl}${data?.data[dataHero].image}`}
                alt="hero"
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          </Grid>

          {/* content */}
          <Grid
            item
            xs={12}
            md={6}
            display={'flex'}
            alignItems={{ xs: 'start', md: 'center' }}
            justifyContent={'center'}
            sx={{
             
              
            }}
          >
            <Box
              width={{ xs: 1, xl: 0.7 }}
              textAlign={{ xs: 'center', md:lang==="en"? 'left':"right" }}
              sx={{
                p: 2,
                ml: { xs: 0, md: -2 },
              }}
            >
              {/* Title */}
              <Typography
                variant="h1"
                sx={{
                  mb: 2,
                  color: props.color?.titleColor
                    ? props.color?.titleColor
                    : colors.primary,
                  fontWeight: 'bold',
                  fontSize: {
                    xs: '1.8rem',
                    sm: '2.3rem',
                    md: '2.5rem',
                    lg: '3.2rem',
                    xl: '4rem',
                  },
                }}
              >
                {data?.data[dataHero][`title_${lang}`]}
              </Typography>

              {/* sub title */}
              <Typography
                variant="h6"
                sx={{
                  color: props.color?.subTitleColor
                    ? props.color?.subTitleColor
                    : colors.primary,
                  fontSize: {
                    xs: '1rem',
                    sm: '1.2rem',
                    md: '1.3rem',
                  },
                  wordBreak: 'break-word',
                }}
              >
                {data?.data[dataHero][`description_${lang}`]}
              </Typography>

              {/* Button */}

              <Box
                sx={{
                  textAlign: 'center',
                  mt: 2,
                }}
              >
                <Button
                  onClick={() => navigate('/aboutUs')}
                  variant="contained"
                  sx={{
                    mt: 2,
                    color: props.Btn?.colorBtn
                      ? props.Btn?.colorBtn
                      : colors.secondary,
                    backgroundColor: props.Btn?.backgroundColorBtn
                      ? `${props.Btn?.backgroundColorBtn}!important`
                      : `${colors.backgroundPrimary} !important`,
                    p: '12px 50px',
                    borderRadius: props.Btn?.borderRaduisBtn ? '25px' : '5px',
                    textTransform: 'capitalize',
                  }}
                >
                  {props.Btn?.titleBtn ? props.Btn.titleBtn : 'lorem'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      )}
    </Box>
  )
}

export default Hero4

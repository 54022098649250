// eslint-disable-next-line import/no-unresolved
import { Box, CardMedia, Stack, Typography } from '@mui/material'
// eslint-disable-next-line import/no-unresolved
import { AboutUsTypes } from './AboutTypes'

import { useTranslation } from 'react-i18next'
import img1 from '../src/assets/about1.jpg'
import img2 from '../src/assets/about2.jpg'

export const AboutUs1 = ({
  colors,
  data,
  isLoading,
  imgs = [img1, img2],
}: AboutUsTypes) => {
  console.log(data)

  const descLength = data?.description_en.length
  const [, { language: lang }] = useTranslation()
  return (
    <Box
      sx={{
        width: 1,
        mb: 20,
        direction: lang === 'en' ? 'ltr' : 'rtl',
        minHeight: '100vh',
      }}
    >
      {isLoading && <span className="loader"></span>}

      {!data && (
        <Box sx={{
          width: '100%',
          height: '50vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Typography align="center"  color="error" fontSize={20}>
            {lang === 'en' ? 'no section added yet' : 'لا يوجد قسم حتي الان'}
          </Typography>
        </Box>
      )}
      {data && descLength > 0 && (
        <>
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            alignItems={'center'}
            sx={{
              width: { xs: '100%', md: '90%' },
              mx: 'auto',
              mb: 10,
              gap: 5,
              // height: '500px',
            }}
          >
            <Box
              sx={{
                lineHeight: 2,
                fontSize: { md: 18, lg: 20, xl: 25 },
                width: { xs: 0.95, md: '40%' },
                color: colors.descColor,
              }}
              dangerouslySetInnerHTML={{
                __html:
                  lang === 'en'
                    ? data?.description_en.slice(0, descLength / 2)
                    : data?.description_ar.slice(0, descLength / 2),
              }}
            />
            <CardMedia
              component={'img'}
              src={data?.image}
              sx={{
                display: 'block',
                width: { xs: '100%', md: '60%' },
                height: {
                  md: 450,
                  xs: 300,
                },
                objectFit: 'contain',
              }}
            />
          </Stack>
          <Box
            sx={{
              position: 'relative',
              width: 1,
              height: { md: '60vh' },
              // background: 'rgb(241,247,247)',
              background: {
                md:
                  lang === 'en'
                    ? `linear-gradient(90deg,${colors.bgColor1} 38%,${colors.bgColor2} 38%)`
                    : `linear-gradient(90deg,${colors.bgColor1} 62%,${colors.bgColor2} 62%)`,
                xs: `linear-gradient(180deg,${colors.bgColor1} 50%,${colors.bgColor2} 50%)`,
              },
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              px: 3,
            }}
          >
            <Stack
              direction={{
                xs: 'column-reverse',
                md: 'row',
              }}
              alignItems={'center'}
              sx={{
                position: { xs: 'static', md: 'absolute' },
                left: lang == 'en' ? { md: '20%', lg: '26%', xl: '30%' } : '0',
                right: lang == 'ar' ? { md: '20%', lg: '26%', xl: '30%' } : '0',
                height: '100%',
                width: { md: '80%', lg: '70%' },
                py: 7,
                columnGap: 4,
              }}
            >
              <CardMedia
                component={'img'}
                src={imgs[0]}
                sx={{
                  display: 'block',
                  width: { xs: '200px', md: '250px', lg: '300px' },
                  height: { xs: '300px', md: '100%' },
                  color: colors.descColor,
                }}
              />
              <Box
                sx={{
                  lineHeight: 2,
                  fontSize: { md: 18, lg: 20, xl: 25 },
                  px: 1,
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    lang === 'en'
                      ? data?.description_en.slice(0, descLength / 2)
                      : data?.description_ar.slice(0, descLength / 2),
                }}
              />
            </Stack>
          </Box>
        </>
      )}
    </Box>
  )
}

import React from 'react'
import { Box } from '@mui/material'
import { colorHero, heroBtn } from './HeroTypes'
// only BACKGROUND IMAGE
import HeroBack1 from './HeroSectionBackground/Hero1'
import HeroBack2 from './HeroSectionBackground/Hero2'
import HeroBack3 from './HeroSectionBackground/Hero3'
import HeroBack4 from './HeroSectionBackground/Hero4'
import HeroBack5 from './HeroSectionBackground/Hero5'
import HeroBack6 from './HeroSectionBackground/Hero6'

// ================================================

// only VIDEO BACKGROUND
import HeroVideo1 from './HeroSectionVideoBack/Hero1'
import HeroVideo2 from './HeroSectionVideoBack/Hero2'
import HeroVideo3 from './HeroSectionVideoBack/Hero3'
import HeroVideo4 from './HeroSectionVideoBack/Hero4'
import HeroVideo5 from './HeroSectionVideoBack/Hero5'
import HeroVideo6 from './HeroSectionVideoBack/Hero6'

// ================================================

// Solid Background

import HeroSolid1 from './HeroSectionSolidBack/Hero1'
import HeroSolid2 from './HeroSectionSolidBack/Hero2'
import HeroSolid3 from './HeroSectionSolidBack/Hero3'
import HeroSolid4 from './HeroSectionSolidBack/Hero4'
import HeroSolid5 from './HeroSectionSolidBack/Hero5'
import HeroSolid6 from './HeroSectionSolidBack/Hero6'

export type heroProps = {
  hero: string
  backgroundImage?: string
  backgroundColor?: string
  image?: string
  Btn?: heroBtn
  color?: colorHero
  backgroundVideo?: string
}
const Heros = (props: heroProps) => {
  return (
    <Box>
      {props.hero === 'HeroBack1' && (
        <HeroBack1
          backgroundImage={props.backgroundImage}
          color={props.color}
          Btn={props.Btn}
        />
      )}
      {props.hero === 'HeroBack2' && (
        <HeroBack2
          backgroundImage={props.backgroundImage}
          color={props.color}
          Btn={props.Btn}
        />
      )}
      {props.hero === 'HeroBack3' && (
        <HeroBack3
          backgroundColor={props.backgroundColor}
          backgroundImage={props.backgroundImage}
          color={props.color}
          Btn={props.Btn}
        />
      )}
      {props.hero === 'HeroBack4' && (
        <HeroBack4
          background={props.backgroundImage}
          Btn={props.Btn}
          color={props.color}
          heroSlideType='multi'
        />
      )}
      {props.hero === 'HeroBack5' && (
        <HeroBack5
          backgroundImage={props.backgroundImage}
          color={props.color}
          Btn={props.Btn}
        />
      )}
      {props.hero === 'HeroBack6' && (
        <HeroBack6
          backgroundColor={props.backgroundColor}
          backgroundImage={props.backgroundImage}
          color={props.color}
          Btn={props.Btn}
        />
      )}

      {/* video */}
      {props.hero === 'HeroVideo1' && (
        <HeroVideo1
          backgroundVideo={props.backgroundVideo}
          color={props.color}
          Btn={props.Btn}
        />
      )}
      {props.hero === 'HeroVideo2' && (
        <HeroVideo2
          backgroundVideo={props.backgroundVideo}
          color={props.color}
          Btn={props.Btn}
        />
      )}
      {props.hero === 'HeroVideo3' && (
        <HeroVideo3
          backgroundVideo={props.backgroundVideo}
          color={props.color}
          Btn={props.Btn}
        />
      )}
      {props.hero === 'HeroVideo4' && (
        <HeroVideo4
          backgroundVideo={props.backgroundVideo}
          color={props.color}
          Btn={props.Btn}
        />
      )}
      {props.hero === 'HeroVideo5' && (
        <HeroVideo5
          backgroundVideo={props.backgroundVideo}
          color={props.color}
          Btn={props.Btn}
        />
      )}
      {props.hero === 'HeroVideo6' && (
        <HeroVideo6
          backgroundVideo={props.backgroundVideo}
          color={props.color}
          Btn={props.Btn}
        />
      )}

      {/* solid */}
      {props.hero === 'HeroSolid1' && (
        <HeroSolid1
          image={props.image}
          color={props.color}
          Btn={props.Btn}
          backgroundColor={props.backgroundColor}
        />
      )}
      {props.hero === 'HeroSolid2' && (
        <HeroSolid2
          image={props.image}
          color={props.color}
          Btn={props.Btn}
          backgroundColor={props.backgroundColor}
        />
      )}
      {props.hero === 'HeroSolid3' && (
        <HeroSolid3
          image={props.image}
          color={props.color}
          Btn={props.Btn}
          backgroundColor={props.backgroundColor}
        />
      )}
      {props.hero === 'HeroSolid4' && (
        <HeroSolid4
          image={props.image}
          color={props.color}
          Btn={props.Btn}
          backgroundColor={props.backgroundColor}
        />
      )}
      {props.hero === 'HeroSolid5' && (
        <HeroSolid5
          image={props.image}
          color={props.color}
          Btn={props.Btn}
          backgroundColor={props.backgroundColor}
        />
      )}
      {props.hero === 'HeroSolid6' && (
        <HeroSolid6
          image={props.image}
          color={props.color}
          Btn={props.Btn}
          backgroundColor={props.backgroundColor}
        />
      )}
    </Box>
  )
}

export default Heros

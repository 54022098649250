import { Box, Container, Typography, Button, Stack } from '@mui/material'
import React from 'react'
import Contact from './ContactData/ContactData'
import { useContactMutation } from '../../src/APIs/contactsApis'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { ContactUsType } from './ContactData/ContactUsType'
import ContactTextInput from './ContactData/ContactInput'
import { ContactUsProps } from '../contactType'
import { toast } from 'react-toastify'

const ContactForm = (props: ContactUsProps) => {
  console.log(props.colors)

  const [_, { language: lang }] = useTranslation()
  const [contact] = useContactMutation()
  const { contactData, contactValidation } = Contact()

  const formik = useFormik<ContactUsType>({
    initialValues: contactData,
    validationSchema: contactValidation,
    onSubmit: (values: any) => {
      console.log(values)
      formik.resetForm()
      contact(values)
        .unwrap()
        .then((res: any) => {
          toast.success(res[`success_${lang}`])
        })
        .catch((err: any) => {
          toast.error(err[`error_${lang}`])
        })
    },
  })

  return (
    <Box>
      <Container>

      <Box
        sx={{
          direction: lang === 'en' ? 'ltr' : 'rtl',
         
          mx: 'auto',
          mb: 2,
          py: 2,
          mt: { xs: 0, md: 2 },
          
        }}
      >
        <form onSubmit={formik.handleSubmit} >
        
          <Stack
         
            direction={{ xs: 'column', sm: 'row' }}
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Box width={{ xs: '100%', md: '50%' }}>
              <ContactTextInput
                value={formik.values.name}
                name={'name'}
                placeholder={lang === 'en' ? 'Name' : 'الاسم'}
                error={formik.errors.name}
                touched={formik.touched.name}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                label={lang === 'en' ? 'Name' : 'الاسم'}
                colors={props.colors}
              />
            </Box>

            <Box width={{ xs: '100%', md: '50%' }}>
              <ContactTextInput
                value={formik.values.email}
                name={'email'}
                placeholder={lang === 'en' ? 'Email' : 'البريد الالكتروني'}
                error={formik.errors.email}
                touched={formik.touched.email}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                label={lang === 'en' ? 'Email' : 'البريد الالكتروني'}
                colors={props.colors}
              />
            </Box>
          </Stack>

          <Box>
            <ContactTextInput
              value={formik.values.phone}
              name={'phone'}
              placeholder={lang === 'en' ? 'Phone' : 'رقم الهاتف'}
              error={formik.errors.phone}
              touched={formik.touched.phone}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              label={lang === 'en' ? 'Phone' : 'رقم الهاتف'}
              colors={props.colors}
            />
          </Box>
          <Container>
            {/* type of connections */}
            <Box>
              <Box my={2}></Box>
              <select
                value={formik.values.contactType}
                name="contactType"
                style={{
                  width: '100%',
                  backgroundColor: props.colors?.backgroundInputColor
                    ? `${props?.colors?.backgroundInputColor} `
                    : 'transparent',
                  border: props.colors?.borderColor
                    ? props.colors?.borderColor
                    : `transparent`,
                  borderRadius: props.colors?.borderRadiusInput
                    ? '50px'
                    : '0px',
                  padding: '14px',
                  fontSize: '20px',
                  color: formik.values.contactType
                    ? props.colors.color
                    : '#333',
                  outline: 'none',
                  marginTop: 6,
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option
                  value="select one"
                  hidden
                  style={{
                    color: props.colors.color,
                    fontSize: '15px',
                    fontWeight: 'bold',
                    
                  }}
                >
                  {lang === 'en' ? 'Select Contact Type' : 'أختار نوع التواصل'}
                </option>
                <option
                  value="complaints"
                  style={{
                    color: props.colors.color ? props.colors.color : '#000',
                    fontSize: '15px',
                    fontWeight: 'bold',
                  }}
                >
                  {lang === 'en' ? 'Complaints' : 'الشكاوي'}
                </option>
                <option
                  value="suggestions"
                  style={{
                    color: props.colors.color ? props.colors.color : '#000',
                    fontSize: '15px',
                    fontWeight: 'bold',
                  }}
                >
                  {lang === 'en' ? 'Suggestions' : 'الاقتراحات'}
                </option>
                <option
                  value="customerService"
                  style={{
                    color: props.colors.color ? props.colors.color : '#000',
                    fontSize: '15px',
                    fontWeight: 'bold',
                  }}
                >
                  {lang === 'en' ? 'Customer Service' : 'خدمة العملاء'}
                </option>
              </select>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'red',
                }}
              >
                {formik.errors.contactType && formik.touched.contactType
                  ? formik.errors.contactType
                  : undefined}
              </Typography>
            </Box>

            {/* Message */}

            <Box sx={{ mt: 3 }}>
            
              <textarea
                value={formik.values.message}
                name="message"
                placeholder={lang === 'en' ? 'Write your message' : 'اكتب رسالتك'}
                style={{
                  width: '100%',
                  padding: '12px 16px',
                  height: '150px',
                  outline: 'none',
                  border: props.colors?.borderColor
                    ? props.colors?.borderColor
                    : `transparent`,
                  borderRadius: props.colors?.borderRadiusInput
                    ? '20px'
                    : '0px',
                    backgroundColor: props.colors?.backgroundInputColor
                    ? `${props?.colors?.backgroundInputColor} `
                    : 'transparent',
                    fontSize:"20px",
                    color: props.colors?.color?props.colors?.color:"#333"
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'red',
                }}
              >
                {formik.errors.message && formik.touched.message
                  ? formik.errors.message
                  : undefined}
              </Typography>
            </Box>
          </Container>
          {/* Button */}
          <Box
            sx={{
              mt: 4,
              textAlign: 'center',
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: props.colors?.backgroundBtn
                  ? `${props.colors?.backgroundBtn} !important`
                  : 'black !important',
                color: props.colors?.colorBtn?`${props.colors?.colorBtn}`:'#fff !important',
                fontWeight: 'bold',
                fontSize: { xs: '20px', md: '26px' },
                borderRadius: props.colors?.borderRadiusBtn?"20px":"2px",
                transition: 'all 0.3s',
                textTransform: 'capitalize',
                px: {
                  md: '60px',
                  xs: '25px',
                },
                py: {
                  md: '8px',
                  xs: '6.5px',
                },
                '&:active': {
                  transform: 'scale(0.9)',
                },
              }}
              type="submit"
            >
              {lang === 'en' ? 'Send' : 'ارسال'}
            </Button>
          </Box>
        </form>
      </Box>
      </Container>
    </Box>
  )
}

export default ContactForm

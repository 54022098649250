import { Box, Typography } from '@mui/material'
import React from 'react'
import { heroSolidProps } from './HeroTypes'
import { useTranslation } from 'react-i18next'
import { useGetAllSlidersQuery } from '../../src/APIs/SectionApis'

const Hero3 = (props: heroSolidProps) => {
  const [_, { language: lang }] = useTranslation()
  const { data, isError,error,isLoading } = useGetAllSlidersQuery(undefined)
  const dataHero = data?.data.length - 1 //last item in array
  const colors = {
    primary: 'gray',
    secondary: '#e3e3e3',
    backgroundPrimary: '#292a2d',
    backgroundSecondary: '#d2d9d5',
  }

  return (
    <Box sx={{
      minHeight:"100vh"
    }}>
        {isError && (
        <Box
        sx={{
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          minHeight:"100vh"
        }}
        >

        <Typography
          fontSize={'1.5rem'}
          my={10}
          textAlign={'center'}
          color="error"
        >
          {(error as any)?.data&& (error as any)?.data[`error_${lang}`]}
        </Typography>
        </Box>
      )}
      {!isLoading && !error&& data?.data.length > 0 && (

      <Box
        sx={{
          height: { xs: '80vh', md: '100vh' },
          width: '100%',
          background: props.backgroundColor
            ? props.backgroundColor
            : 'linear-gradient(90deg ,#d3d3d3 30%,white 30%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          width={{ xs: 1, sm: 0.7, md: 0.4 }}
          textAlign={{ xs: 'center', sm:lang==="en"? 'left':"right" }}
          ml={{ xs: 0, sm: 10, md: 20 }}
          px={2}
       
        >
          <Typography
            variant="h1"
            sx={{
              mb: 2,
              color: props.color?.titleColor
                ? props.color?.titleColor
                : colors.primary,
              fontWeight: 'bold',
              fontSize: {
                xs: '1.8rem',
                sm: '2.3rem',
                md: '2.5rem',
                lg: '3.2rem',
                xl: '4rem',
              },
            }}
          >
            {data?.data[dataHero][`title_${lang}`]}
          </Typography>

          {/* sub title */}
          <Typography
            variant="h6"
            sx={{
              color: props.color?.subTitleColor
                ? props.color?.subTitleColor
                : colors.primary,
              fontSize: {
                xs: '1rem',
                sm: '1.2rem',
                md: '1.3rem',
              },
              wordBreak: 'break-word',
            }}
          >
            {data?.data[dataHero][`description_${lang}`]}
          </Typography>
        </Box>
      </Box>
      )}
    </Box>
  )
}

export default Hero3

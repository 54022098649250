import { FooterProps } from './FooterTypes'
import Amazon from './footer-types/Amazon'
import Drajite from './footer-types/Drajite'
import Jarir from './footer-types/Jarir'
import Noon from './footer-types/Noon'
import Tomoor from './footer-types/Tomoor'

export interface ICategory {
  image: string
  imageUrl: string
  name_ar: string
  name_en: string
  _id: string
}

export const links = [
  {
    ar: 'عننا',
    en: 'About Us',
    path: '/aboutUs',
  },
  {
    ar: 'تواصل معنا',
    en: 'Contact Us',
    path: '/contactUs',
  },
  {
    ar: 'السياسة والخصوصية',
    en: 'Privacy Policy',
    path: '/privacyPolicy',
  },
]

function Footer({ type, information, ...props }: FooterProps) {
  if (type === 'tomoor') {
    return <Tomoor {...props} />
  }

  if (type === 'jarir') {
    return <Jarir information={information} {...props} />
  }

  if (type === 'noon') {
    return <Noon {...props} />
  }

  if (type === 'amazon') {
    return <Amazon {...props} />
  }

  if (type === 'drajite') {
    return <Drajite {...props} information={information} />
  }

  return <h1>please select a valid type</h1>
}

export default Footer

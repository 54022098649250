import {
  Box,
  Button,
  CardMedia,
  Fade,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { CardProps } from './CardTypes'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  useAddToSavedProductMutation,
  useGetAllSavedProductsQuery,
} from '../src/APIs/SavedProductApi'
import { toast } from 'react-toastify'
import { imageBaseUrl } from '../src/App'
import { useAddToCartMutation } from '../src/APIs/cartApi'
export const Card = (props: CardProps) => {
  const [hover, setHover] = useState(false)
  const [, { language: lng }] = useTranslation()
  const nav = useNavigate()
  const [toFavorite] = useAddToSavedProductMutation()
  const { data: favPros } = useGetAllSavedProductsQuery(undefined)
  const proInFav = favPros?.data.favourite.find(
    (f: any) => f._id === props.data._id
  )
  const [addToCart] = useAddToCartMutation()
  const cartData = {
    quantity: 1,
    properties: [],
    id: props.data._id,
  }

  return (
    <Paper
      
      elevation={4}
      sx={{
        display: props.wide ? 'flex' : 'block',
        flexDirection: props.wide ? 'row' : 'column',
        border: props.border ? `3px solid ${props.colors?.borderColor}` : null,
        borderRadius: props.borderRadius ? 4 : null,
        width: props.width ? props.width : '100%',
        height: props.height ? props.height : '100%',
        bgcolor: props.colors?.backgroundColor,
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => nav(`/products/${props.data._id}`)}
    >
      {props.favorite && (
        <FavoriteIcon
          sx={{
            position: 'absolute',
            top: 10,
            color: proInFav ? 'tomato' : 'transparent',
            stroke: 'tomato',
            strokeWidth: proInFav ? 0 : 1,
            right: 10,
            fontSize: '2.3rem',
            cursor: 'pointer',
          }}
          onClick={(e) => {
            e.stopPropagation(),
              toFavorite(props.data._id)
                .unwrap()
                .then((res) => {
                  toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
                })
                .catch((e) =>
                  toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
                )
          }}
        />
      )}
      {props.data.images && props.data.images[0] && (
        <CardMedia
          component={'img'}
          src={imageBaseUrl + props?.data?.images[0]}
          sx={{
            width: props.wide ? '40%' : '100%',
            height: props.wide
              ? '100%'
              : props.title && props.desc && props.button
              ? '60%'
              : props.button &&
                !props.title &&
                !props.desc &&
                !props.hoverWithFav &&
                !props.hover
              ? '80%'
              : !props.button && props.title && props.desc
              ? '70%'
              : '100%',
          }}
        />
      )}
      <Stack
        sx={{
          alignItems: props.wide ? 'start' : 'center',
          justifyContent: props.wide ? 'center' : 'end',
          width: '100%',
          height: props.wide
            ? '100%'
            : props.title && props.desc && props.button
            ? '40%'
            : props.button &&
              !props.title &&
              !props.desc &&
              !props.hoverWithFav &&
              !props.hover
            ? '20%'
            : !props.button && props.title && props.desc
            ? '30%'
            : '0%',
          mx: props.wide ? { md: 5, xs: 0 } : 0,
          px: 1,
          // gap: 1,
        }}
      >
        <>
          {props.title && (
            <Typography
              sx={{
                color: props.colors.titleColor,
                fontWeight: 'bold',
                fontSize: { xs: '0.7rem', md: '1rem' },
              }}
            >
              {lng === 'en'
                ? props.data[`title_${lng === 'en' ? 'en' : 'ar'}`]
                : props.data.title_ar}
            </Typography>
            //  check if desc length is more than 90 slice 90 character and add dots
          )}
          {props.desc && (
            <Box
              className="desc"
              sx={{
                // fontSize: { md: '15px !important', xs: '10px !important' },
                fontWeight: 'normal',
                color: props.colors.descColor,
                wordBreak: 'break-word',
                overflow: 'hidden',
              }}
              dangerouslySetInnerHTML={{
                __html:
                  props.data[
                    `description_${lng === 'en' ? 'en' : 'ar'}`
                  ]?.split(' ').length > 3
                    ? `${props.data[`description_${lng === 'en' ? 'en' : 'ar'}`]
                        ?.split(' ')
                        .slice(0, 3)
                        .join(' ')} ...`
                    : props.data[`description_${lng === 'en' ? 'en' : 'ar'}`],
              }}
            />
          )}
        </>
        {props.button && !props.hover && !props.hoverWithFav && (
          <Button
            variant={props.colors.borderColor ? 'outlined' : "contained"}
            sx={{
              bgcolor: `${props.colors.buttonBackgroundColor} !important`,
              color: props.colors.buttonTextColor,
              borderRadius: props.borderRadius ? 4 : 0,
              borderColor: props.colors.borderColor ? props.colors.borderColor : 'transparent',
              '&:hover': {
                borderColor: props.colors.borderColor ? `${props.colors.borderColor} !important` : 'transparent',

              },
              px: { md: 5, xs: 0 },
              my: 1,
              fontSize: { md: 'initial', xs: '8px' },
            }}
            onClick={(e) => {
              e.stopPropagation(),
                addToCart(cartData)
                  .unwrap()
                  .then((res) =>
                    toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
                  )
                  .catch((e) =>
                    toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
                  )
            }}
          >
            {lng === 'en' ? 'Add to cart' : 'أضف إلى السلة'}
          </Button>
        )}
      </Stack>
      {(props.hover || props.hoverWithFav) && (
        <Fade in={hover} timeout={900}>
          <Stack
            sx={{
              color:
                !props.hoverWithFav && !props.button
                  ? props.colors.titleColor || props.colors.descColor || 'white'
                  : 'white',
              background: props.colors.hoverColor,
              position: 'absolute',
              bottom: 0,
              width: '100%',
              p: 2,
              px: !props.button && !props.favorite ? 5 : 2,
              gap: 1,
              boxSizing: 'border-box',
              height: 'fit-content',
            }}
          >
            <Stack
              direction={'row'}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Stack>
                <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bolder', color: props.colors.titleColor }}>
                  {lng === 'en'
                    ? props.data[`title_${lng === 'en' ? 'en' : 'ar'}`]
                    : props.data.title_ar}{' '}
                </Typography>
                <Box
                  className="desc"
                  sx={{
                    fontSize: '15px !important',
                    fontWeight: 'normal',
                    color: props.colors.descColor,
                    wordBreak: 'break-word',
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      props.data[
                        `description_${lng === 'en' ? 'en' : 'ar'}`
                      ]?.split(' ').length > 3
                        ? `${props.data[
                            `description_${lng === 'en' ? 'en' : 'ar'}`
                          ]
                            ?.split(' ')
                            .slice(0, 3)
                            .join(' ')} ...`
                        : props.data[
                            `description_${lng === 'en' ? 'en' : 'ar'}`
                          ],
                  }}
                />
              </Stack>
              {props.hoverWithFav ? (
                <FavoriteIcon
                  sx={{
                    color: proInFav ? 'tomato' : 'white',

                    fontSize: '2.5rem',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.stopPropagation(),
                      toFavorite(props.data._id)
                        .unwrap()
                        .then((res) => {
                          toast.success(
                            res[`success_${lng === 'en' ? 'en' : 'ar'}`]
                          )
                        })
                        .catch((e) =>
                          toast.error(
                            e.data[`error_${lng === 'en' ? 'en' : 'ar'}`]
                          )
                        )
                  }}
                />
              ) : (
                props.hover &&
                props.button && (
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: `${props.colors.buttonBackgroundColor} !important`,
                      color: props.colors.buttonTextColor,
                      // borderRadius: 4,
                      px: 5,
                      width: '30%',
                      // alignSelf: 'center',
                    }}
                    onClick={() =>
                      addToCart(cartData)
                        .unwrap()
                        .then((res) =>
                          toast.success(
                            res[`success_${lng === 'en' ? 'en' : 'ar'}`]
                          )
                        )
                        .catch((e) =>
                          toast.error(
                            e.data[`error_${lng === 'en' ? 'en' : 'ar'}`]
                          )
                        )
                    }
                  >
                    {lng === 'en' ? 'Add to cart' : 'أضف إلى السلة'}
                  </Button>
                )
              )}
            </Stack>
            {props.button && props.hoverWithFav && (
              <Button
                variant="contained"
                sx={{
                  bgcolor: `${props.colors.buttonBackgroundColor} !important`,
                  color: props.colors.buttonTextColor,
                  borderRadius: 4,
                  px: 5,
                  width: '70%',
                  alignSelf: 'center',
                }}
                onClick={() =>
                  addToCart(cartData)
                    .unwrap()
                    .then((res) =>
                      toast.success(
                        res[`success_${lng === 'en' ? 'en' : 'ar'}`]
                      )
                    )
                    .catch((e) =>
                      toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
                    )
                }
              >
                {lng === 'en' ? 'Add to cart' : 'أضف إلى السلة'}
              </Button>
            )}
          </Stack>
        </Fade>
      )}
    </Paper>
  )
}

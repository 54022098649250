import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from './baseUrl'

const aboutUsApi = createApi({
  reducerPath: 'aboutUsApi',
  tagTypes: ['AboutUs'],
  baseQuery: fetchBaseQuery({
    // baseUrl: 'https://bookstore.work:8080/api/v1',
    // baseUrl: 'https://bookstore.work:8080/api/v1',
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getAboutUsData: builder.query({
      query: () => `/sections?type=aboutus`,
      providesTags: ['AboutUs'],
    }),
  }),
})

export const { useGetAboutUsDataQuery } = aboutUsApi
export default aboutUsApi

// eslint-disable-next-line import/no-unresolved
import { Box, CardMedia, Stack } from '@mui/material'
// eslint-disable-next-line import/no-unresolved
import { AboutUsTypes } from './AboutTypes'

import { useTranslation } from 'react-i18next'
import { Services } from './pulicComponents/Services'
interface AboutProps extends AboutUsTypes {
  servicesSection: boolean
}
export const AboutUs2 = ({
  colors,
  data,
  imgs,
  servicesSection,
}: AboutProps) => {
  const [, { language: lang }] = useTranslation()
  const descLength = data?.description_en.length
  return (
    <Box sx={{ p: { xs: 1, md: 3 }, mt: { xs: '100px', md: 0 } }}>
      <Stack
        direction={{ xs: 'column', md: 'row-reverse' }}
        spacing={2}
        sx={{ direction: lang === 'en' ? 'ltr' : 'rtl' }}
      >
        <Stack direction={'row'} flex={1} spacing={1} sx={{ height: '80vh' }}>
          <Box sx={{ height: '80vh' }}>
            {[imgs[0], imgs[1]].map((img, index) => (
              <CardMedia
                key={index}
                component={'img'}
                // src={data?.image}
                src={img}
                sx={{
                  display: 'block',
                  width: '95%',
                  height: '250px',
                  mb: 1,
                }}
              />
            ))}
          </Box>
          <Box sx={{ alignSelf: 'center' }}>
            {[data?.image, imgs[2]].map((img, index) => (
              <CardMedia
                key={index}
                component={'img'}
                // src={data?.image}
                src={img}
                sx={{
                  display: 'block',
                  width: '95%',
                  height: '250px',
                  mb: 1,
                }}
              />
            ))}
          </Box>
        </Stack>
        <Box
          flex={1}
          sx={{
            lineHeight: 2,
            fontSize: { md: 18, lg: 20, xl: 25 },

            color: colors.descColor,
          }}
          dangerouslySetInnerHTML={{
            __html:
              lang === 'en'
                ? data?.description_en.slice(0, descLength / 2)
                : data?.description_ar.slice(0, descLength / 2),
          }}
        />
      </Stack>
      <Box
        sx={{
          lineHeight: 2,
          fontSize: { md: 18, lg: 20, xl: 25 },
          color: colors.descColor,
          textAlign: lang == 'en' ? 'left' : 'right',
        }}
        dangerouslySetInnerHTML={{
          __html:
            lang === 'en'
              ? data?.description_en.slice(descLength / 2, descLength)
              : data?.description_ar.slice(descLength / 2, descLength),
        }}
      />
      {servicesSection && (
        <Box sx={{ px: { xs: 3, md: 0 } }}>
          <Services colors={colors} />
        </Box>
      )}
    </Box>
  )
}

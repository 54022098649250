import { Button, FormLabel, Input } from '@mui/joy'
import {
  Autocomplete,
  Box,
  Container,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useAddOrderMutation } from '../../../src/APIs/ordersApi'
import { useGetUserCodeMutation } from '../../../src/APIs/verifiedCodeApi'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useGetAllCartsQuery } from '../../../src/APIs/cartApi'
import { BillingProps } from './checkTypes'
import { cityEnglish } from '../../../providers/city-english'
import VerifiedCode from '../../../verification/VerifiedCode'

const BillingDetails = (props: BillingProps) => {
  const [openModal, setOpenModal] = useState(false)
  const [_, { language, changeLanguage }] = useTranslation()
  const [addOrder] = useAddOrderMutation()
  const { refetch } = useGetAllCartsQuery(undefined)
  const [getUserCode] = useGetUserCodeMutation()
  const navigate = useNavigate()

  const handelerCode = (code: string) => {

    getUserCode({code })
      .unwrap()
      .then((res) => {
        refetch()
        toast.success(res[`success_${language}`])
        if (res.paymentType === 'cash') {
          navigate('/thankYou')
        } else {
          navigate('/payment-order')
        }
      })
      .catch((err) => {
        toast.error(err.data[`error_${language}`])
      })
 
      
  }
  const formik = useFormik({
    initialValues: {
      city: '',
      name: '',
      area: '',
      address: '',
      postalCode: '',
      phone: '',
      email: '',
      orderNotes: '',
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .required(language === 'en' ? 'Name Required' : 'الاسم مطلوب')
        .matches(
          /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
          language === 'en'
            ? 'Name must be in English'
            : 'يجب أن يكون الاسم باللغة الإنجليزية'
        ),

      area: yup
        .string()
        .required(language === 'en' ? 'Area Required' : 'المنطقة مطلوبة'),
      address: yup
        .string()
        .required(language === 'en' ? 'Address Required' : 'العنوان مطلوب'),
      city: yup
        .string()
        .required(language === 'en' ? 'City Required' : 'المدينة مطلوبة'),
      phone: yup
        .string()
        .required(language === 'en' ? 'Phone Required' : 'الهاتف مطلوب')
        .matches(
          /^[0-9]/,
          language === 'en'
            ? 'Phone must start with country code and only numbers'
            : 'يجب أن يبدأ الهاتف برمز البلد وأرقام فقط'
        ),
      email: yup
        .string()
        .required(
          language === 'en' ? 'Email Required' : 'البريد الالكتروني مطلوب'
        )
        .email(
          language === 'en' ? 'Email is invalid' : 'البريد الالكتروني غير صالح'
        ),
      postalCode: yup
        .string()
        .required(
          language === 'en' ? 'Postal Code Required' : 'الرمز البريدي مطلوب'
        ),
      orderNotes: yup.string().optional(),
    }),
    onSubmit: (values) => {
      console.log('values in jariri', values)
      if (values.orderNotes === '') {
        delete values.orderNotes
      }
      addOrder({ ...values })
        .unwrap()
        .then((res) => {
          setOpenModal(true)
        })
        .catch((err) => {
          toast.error(err.data[`error_${language}`])
        })
    },
  })
  const Data = useMemo(() => cityEnglish, [cityEnglish, language])
  const { handleBlur, handleChange } = formik
  return (
    <Box
      sx={{
        direction: language === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Container
        sx={{
          py: 2,
        }}
      >
        <Box
          sx={{
            borderBottom: 2,
            borderColor: props.color ? props.color : 'gray',
            py: 2,

            marginBottom: 4,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '25px', md: '30px' },
              color: props.color?.title,
            }}
          >
            {language === 'en' ? 'Billing Details' : 'تفاصيل الفاتورة'}
          </Typography>
        </Box>

        {/* form */}

        <form onSubmit={formik.handleSubmit}>
          <Box mt={4}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Name' : 'الاسم'}
            </FormLabel>
            <Input
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              onChange={formik.handleChange}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `1px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
              }}
            />
            {formik.touched.name && formik.errors.name ? (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.name}
              </Typography>
            ) : null}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'City' : 'المدينة'}
            </FormLabel>
            {/* <Input
              name="city"
              onBlur={formik.handleBlur}
              value={formik.values.city}
              onChange={formik.handleChange}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `1px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
              }}
            /> */}
            <Autocomplete
              disablePortal
              clearOnBlur
              onBlur={formik.handleBlur}
              // value={formik.values.city}
              getOptionLabel={(option) =>
                language === 'en' ? option.en : option.en
              }
              onChange={(e, value) => {
                formik.setFieldValue('city', value?.en)
              }}
              id="combo-box-demo"
              options={Data}
              sx={{
                width: '100%',
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
                '.MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-fullWidth.MuiInputBase-root.MuiOutlinedInput-root.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root':
                  {
                    border: `1px solid ${
                      props.color?.label ? props.color?.label : 'gray'
                    }`,
                  },
              }}
              renderInput={(params) => <TextField name="city" {...params} />}
            />

            {formik.touched.city && formik.errors.city && (
              <Typography sx={{ color: 'red', mt: 1 }}>
                {formik.errors.city}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Area' : 'المنطقة'}
            </FormLabel>
            <Input
              name="area"
              onBlur={formik.handleBlur}
              value={formik.values.area}
              onChange={formik.handleChange}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `1px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
              }}
            />
            {formik.touched.area && formik.errors.area && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.area}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Address' : 'العنوان'}
            </FormLabel>
            <Input
              name="address"
              onBlur={formik.handleBlur}
              value={formik.values.address}
              onChange={formik.handleChange}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `1px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
              }}
            />
            {formik.touched.address && formik.errors.address && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.address}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Postal Code' : 'الرمز البريدي'}
            </FormLabel>
            <Input
              name="postalCode"
              onBlur={formik.handleBlur}
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `1px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
              }}
            />
            {formik.touched.postalCode && formik.errors.postalCode && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.postalCode}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Phone' : 'الهاتف'}
            </FormLabel>
            <Input
              name="phone"
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              onChange={formik.handleChange}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `1px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
              }}
            />
            {formik.touched.phone && formik.errors.phone && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.phone}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Email' : 'البريد الالكتروني'}
            </FormLabel>
            <Input
              name="email"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `1px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
              }}
            />
            {formik.touched.email && formik.errors.email && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.email}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en'
                ? ' Order Notes (optional)'
                : 'ملاحظات الطلب (اختياري)'}
            </FormLabel>
            <Input
              name="orderNotes"
              onBlur={formik.handleBlur}
              value={formik.values.orderNotes}
              onChange={formik.handleChange}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `1px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
              }}
            />
            {formik.touched.orderNotes && formik.errors.orderNotes && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.orderNotes}
              </Typography>
            )}
          </Box>

          <Button
            type="submit"
            sx={{
              mt: 6,
              mb: 5,
              width: '100%',
              height: '60px',
              borderRadius: '15px',
              bgcolor: props.button?.backgroundColor
                ? `${props.button?.backgroundColor} !important`
                : 'black !important',
              color: props.button?.color
                ? `${props.button?.color} !important`
                : 'white',
              fontSize: { xs: '15px', md: '20px' },
              textTransform: 'none',
            }}
          >
            {language === 'en' ? 'Order Now' : 'اطلب الآن'}
          </Button>
        </form>
      </Container>

      <VerifiedCode open={openModal} handelerCode={handelerCode} verifiedCodeColor={props.verifiedCodeColor} />
    </Box>
  )
}

export default BillingDetails

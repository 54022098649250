import React from "react";
import { Box } from "@mui/material";
import data from "../data.json";
import { heroBackImgProps } from "./HeroTypes";
const Hero2 = (props:heroBackImgProps) => {
  return (


    //only Background image

    <Box
      sx={{
        height: {xs:"85vh",md:"100vh"},
        width: "100%",
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    ></Box>
  );
};

export default Hero2;

interface MapProps {
  url: string
  props?: any
}

function Map({ url, ...props }: MapProps) {
  return (
    <div>
      <iframe
        title="map"
        src={url}
        width="100%"
        height="225"
        style={{ border: '0px' }}
        loading="lazy"
        {...props}
      ></iframe>
    </div>
  )
}

export default Map

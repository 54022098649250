import { useEffect, useState } from 'react'
import { useGetAllCategoriesQuery } from '../../src/APIs/categoriesApi'
const useFetchCategories = () => {
  const { data, isSuccess, isError } = useGetAllCategoriesQuery(
    'limit=100',
    undefined
  )

  const [categories, setCategories] = useState([])
  const [, setError] = useState('')
  useEffect(() => {
    if (isSuccess && !isError) {
      setCategories(data?.data)
      setError('')
    } else {
      setError('Error While Fetching Products')
    }
  }, [isSuccess])
  return categories
}

export default useFetchCategories

import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'

import { Box, Button, Grid, Typography } from '@mui/material'
import { Card } from '../../Cards/Card'
import {
  useGetMostNewiestProductsQuery,
  useGetMostSellingProductsQuery,
} from '../../src/APIs/ProductApis'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CardColors } from '../../Cards/CardTypes'

type propsNewest = {
  title?: string
  color?: string
  borderRaduisBtn?: boolean
  type?: string
  typeScroll?: string
  BtnCollections?: {
    title?: string
    titleColor?: string
    backgroundColor?: string
    borderRadius?: boolean
  }
  card: {
    colors: CardColors
    wide?: boolean
    border?: boolean
    borderRadius?: boolean
    width?:
      | string
      | number
      | {
          xs?: string | number
          sm?: string | number
          md?: string | number
          lg?: string | number
        }
    height?:
      | string
      | number
      | {
          xs?: string | number
          sm?: string | number
          md?: string | number
          lg?: string | number
        }
    title?: boolean
    desc?: boolean
    button?: boolean
    favorite?: boolean
    hoverWithFav?: boolean
    hover?: boolean
  }
}
const NewestScroll = (props: propsNewest) => {
  const {
    data: MostSeller,
    isSuccess: MostSuccess,
    isError: MostError,
    error: MostErrMsg,
  } = useGetMostSellingProductsQuery(undefined)
  const {
    data: Newest,
    isSuccess,
    isLoading,
    error,
    isError,
  } = useGetMostNewiestProductsQuery(undefined)
  const getData =
    props.title === 'NewestScroll' ? Newest?.data : MostSeller?.data
  const navigate = useNavigate()
  const colors = {
    primary: 'white',
    secondary: 'black',
    backgroundPrimary: '#b0b4c5',
    backgroundSecondary: '#d2d9d5',
  }

  const [_, { language: lang }] = useTranslation()
  return (
    <Box
      mt={10}
      sx={{
        direction: lang === 'en' ? 'ltr' : 'rtl',
      }}
    >
      {isError && MostError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {isError
              ? (error as any)?.data && (error as any)?.data[`error_${lang}`]
              : (MostErrMsg as any)?.data &&
                (MostErrMsg as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}

      {isSuccess &&
        MostSuccess &&
        !isError &&
        !MostError &&
        getData?.length > 0 && (
          <Grid container sx={{}}>
            {/* Newest title and btn */}
            {props.typeScroll !== 'Newest' &&
            props.type !== 'MostSellerScroll' ? (
              <Grid
                item
                xs={12}
                md={3}
                p={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box textAlign={'center'}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',

                      fontSize: { xs: '1.5rem', sm: '2.5rem', lg: '3.2rem' },
                      color: props.color ? props.color : colors.secondary,
                    }}
                  >
                    {props.title === 'NewestScroll'
                      ? lang === 'en'
                        ? 'Newest'
                        : 'الاحدث'
                      : lang === 'en'
                      ? 'Most Seller'
                      : 'الاكثر مبيعا'}
                  </Typography>

                  <Button
                    variant="outlined"
                    sx={{
                      mt: 2,

                      color: props.BtnCollections?.titleColor
                        ? props.BtnCollections?.titleColor
                        : colors.secondary,
                      // borderRadius: props.borderRaduisBtn ? '40px' : '0px',
                      padding: { xs: '0.4rem 3rem', md: '0.6rem 5rem' },
                      border: `1px solid ${props.BtnCollections?.titleColor} !important`,
                      backgroundColor: 'transparent !important',
                    }}
                    onClick={() => {
                      navigate('/products')
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '10px', sm: '12px', md: '25px' },
                        // i need to make words not capital
                        textTransform: 'capitalize',
                        // color:props.color?props.color:colors.secondary,
                      }}
                    >
                      {props.BtnCollections?.title
                        ? props.BtnCollections?.title
                        : lang === 'en'
                        ? 'View All'
                        : 'عرض الكل'}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Box px={5}>
                <Typography
                  sx={{
                    fontSize: { xs: '30px', md: '35px', lg: '50px' },
                    fontWeight: 'bold',

                    color: props.color ? props.color : colors.secondary,
                  }}
                >
                  {lang === 'en' ? 'Most Seller' : 'الاكثر مبيعا'}
                </Typography>
              </Box>
            )}

            {/* Slider */}
            <Grid
              width={'100%'}
              item
              xs={12}
              md={
                props.typeScroll === 'Newest' &&
                props.type === 'MostSellerScroll'
                  ? 12
                  : 9
              }
              py={4}
              sx={{
                direction: lang === 'en' ? 'ltr' : 'rtl',
              }}
            >
              <Box px={2}>
                <Swiper
                  style={{ direction: 'ltr' }}
                  className="mySwiper"
                  slidesPerView={3}
                  spaceBetween={30}
                  freeMode={true}
                  modules={[FreeMode]}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 120,
                    },
                    719: {
                      slidesPerView: 2,
                      spaceBetween: 50,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
                >
                  {getData?.map((item: any) => (
                    <SwiperSlide key={item.title}>
                      <Box p={2}>
                        <Card
                          data={item}
                          colors={props.card.colors}
                          border={props.card.border}
                          borderRadius={props.card.borderRadius}
                          wide={props.card.wide}
                          width={props.card.width}
                          height={props.card.height}
                          title={props.card.title}
                          desc={props.card.desc}
                          button={props.card.button}
                          favorite={props.card.favorite}
                          hoverWithFav={props.card.hoverWithFav}
                          hover={props.card.hover}
 
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Grid>
          </Grid>
        )}
    </Box>
  )
}

export default NewestScroll

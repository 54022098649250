import { Box } from '@mui/material'
import React from 'react'

interface Props {
  video?: string
}

const VideoBack = ({ video }: Props) => {
  return (
    <Box>
      <video
        src={video}
        autoPlay
        muted
        loop
        style={{
          width: '100%',
          height: '100%',
          zIndex: -1,
          position: 'absolute',
          top: 0,
          objectFit: 'cover',
        }}
      />
    </Box>
  )
}

export default VideoBack

// eslint-disable-next-line import/no-unresolved
import { Box, CardMedia, Stack } from '@mui/material'
// eslint-disable-next-line import/no-unresolved
import { AboutUsTypes } from './AboutTypes'

import { useTranslation } from 'react-i18next'
import { Services } from './pulicComponents/Services'
interface AboutProps extends AboutUsTypes {
  servicesSection: boolean
}
export const AboutUs4 = ({
  colors,
  data,
  imgs,
  servicesSection,
}: AboutProps) => {
  const [, { language: lang }] = useTranslation()
  const descLength = data?.description_en.length
  return (
    <Stack sx={{ direction: lang === 'en' ? 'ltr' : 'rtl' }}>
      <Box
        sx={{
          height: 700,
          width: '100%',
          background: data?.image
            ? `url(${data?.image})`
            : `url(${imgs[0]}) center center`,
          //   background: `url(${imgs[0]}) center center`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          py: 3,
          mb: 15,
          
        }}
      >
        {servicesSection && (
          <Box sx={{ width: '90%', mx: 'auto' }}>
            <Services colors={colors} />
          </Box>
        )}
      </Box>
      <Box sx={{ mx: '5%' }}>
        <Box
          sx={{
            lineHeight: 2,
            fontSize: { md: 18, lg: 20, xl: 25 },
            color: colors.descColor,
            textAlign: lang == 'en' ? 'left' : 'right',
          }}
          dangerouslySetInnerHTML={{
            __html:
              lang === 'en'
                ? data?.description_en.slice(0, descLength / 2)
                : data?.description_ar.slice(0, descLength / 2),
          }}
        />
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={'center'}
          gap={2}
        >
          <Box
            sx={{
              lineHeight: 2,
              fontSize: { md: 18, lg: 20, xl: 25 },
              color: colors.descColor,
              textAlign: lang == 'en' ? 'left' : 'right',
            }}
            dangerouslySetInnerHTML={{
              __html:
                lang === 'en'
                  ? data?.description_en.slice(descLength / 2, descLength)
                  : data?.description_ar.slice(descLength / 2, descLength),
            }}
          />
          <CardMedia
            component={'img'}
            // src={data?.image}
            src={imgs[0]}
            sx={{
              display: 'block',
              width: { xs: '100%', md: '40%' },
              height: '250px',
              mb: 1,
            }}
          />
        </Stack>
      </Box>
    </Stack>
  )
}

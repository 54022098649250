import * as yup from 'yup'
import { ContactUsType } from './ContactUsType'
import { useTranslation } from 'react-i18next'

const Contact = () => {
  const [_, { language: lang }] = useTranslation()

  const contactData: ContactUsType = {
    name: '',
    email: '',
    phone: '',
    message: '',
    contactType: '',
  }
  const contactValidation = yup.object<ContactUsType>({
    name: yup
      .string()
      .required(lang === 'en' ? 'Name is required' : 'الاسم مطلوب'),
    email: yup
      .string()
      .email(lang === 'en' ? 'Email is invalid' : 'البريد الالكتروني غير صحيح')
      .required(
        lang === 'en' ? 'Email is required' : 'البريد الالكتروني مطلوب'
      ),
    phone: yup
      .string()
      .required(lang === 'en' ? 'Phone is required' : 'رقم الهاتف مطلوب'),
    message: yup
      .string()
      .required(lang === 'en' ? 'Message is required' : 'اكتب رسالتك مطلوبة'),
    contactType: yup
      .string()
      .required(lang === 'en' ? 'Select Contact Type' : 'اختار نوع التواصل')
      .ensure()
      .oneOf(['complaints', 'suggestions', 'customerService']),
  })

  return { contactData, contactValidation }
}
export default Contact

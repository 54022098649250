import React from 'react'
import { AboutProps } from './AboutType'
import { Box, Typography, Grid, Button } from '@mui/material'
import Banner from '../../Banners/Banner'
import { useGetAboutUsDataQuery } from '../../../src/APIs/aboutUsApi'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
const AboutUs = (props: AboutProps) => {
  const [_, { language: lang }] = useTranslation()
  const { data, isSuccess, isError, error, isLoading } =
    useGetAboutUsDataQuery(undefined)
  const navigate = useNavigate()
  const desc = data?.data[0][`description_${lang}`]
  const title = data?.data[0][`title_${lang}`]
  console.log('error', error)

  const colors = {
    primary: 'gray',
    secondary: '#e3e3e3',
    backgroundPrimary: '#292a2d',
    backgroundSecondary: '#97979763',
  }

  return (
    <Box>
      {isLoading && <span className="loader"></span>}
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isLoading && !isError && isSuccess && (
        <>
          <Box
            sx={{
              textAlign: 'center',
              mb: 5,
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '30px', md: '35px', lg: '45px' },
                fontWeight: 'bold',
                color: props.color?.title
              }}
            >
              {lang === 'ar' ? ' عننا' : 'About Us'}
            </Typography>
          </Box>
          <Grid
            container
            width={'100%'}
            sx={{
              backgroundColor: props.backgroundColor
                ? props.backgroundColor
                : colors.backgroundSecondary,
              direction: lang === 'ar' ? 'rtl' : 'ltr',
              py: 5,
            }}
          >
            {/* Description */}

            <Grid
              item
              xs={12}
              md={6}
              p={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                width={0.9}
                mx={'auto'}
               
                sx={
                  {
                    // textAlign: { xs: 'center', md: 'initial' },
                  }
                }
              >
                {/* Main Title */}
                <Typography
                  variant={'h3'}
                  sx={{
                    fontSize: { xs: '27px', sm: '30px', lg: '40px' },
                    fontWeight: 'bold',
                    // textAlign: 'center',
                    color: props.color?.title
                      ? props.color.title
                      : colors.primary,
                  }}
                >
                  {title}
                </Typography>

                {/* subTitle */}
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    fontSize: { xs: '16px', md: '17px', lg: '22px' },
                    color: props.color?.subTitle
                      ? props.color.subTitle
                      : colors.secondary,
                    wordBreak: 'break-word',
                  }}
                  // i need to make it end "..."
                  dangerouslySetInnerHTML={{
                    __html: desc ? `${desc.slice(0, 100)}` : '',
                  }}
                ></Typography>

                {props?.button && (
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate('/aboutUs')
                      }}
                      sx={{
                        mt: 2,
                        color: props.button.color,
                        backgroundColor: `${props.button.backgroundColor} !important`,
                        p: '10px 50px',
                        borderRadius: '50px',
                        textTransform: 'capitalize',
                      }}
                    >
                      {lang === 'ar' ? 'المزيد' : 'More'}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>

            {/* image or video */}

            <Grid item xs={12} md={5.5} p={2}>
              {props?.image && (
                <Box height={"100%"}>
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    src={props.image}
                    alt={`about${props.image}`}
                  />
                </Box>
              )}

              {props?.video && (
                <Box height={"70vh"}>
                  <video
                    style={{
                      width: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                    src={props?.video}
                    autoPlay
                    muted
                    loop
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}

export default AboutUs

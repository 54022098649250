import { Box } from '@mui/joy'
import React from 'react'
import Vision from './vision/Vision'
import { VisionType } from '../About/AboutTypes'

const VisionContainer = (props: VisionType) => {
  return (
    <Box>
      <Box my={10}>
        <Vision
          button={props.vision.button}
          video={props.vision.video}
          image={props.vision.image}
          background={props.vision.background}
          color={props.vision.color}
        />
      </Box>
    </Box>
  )
}

export default VisionContainer;

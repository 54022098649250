import { Box, InputBase, Stack, Typography, Container } from '@mui/material'
import React from 'react'
import { ContactUsInput } from './ContactUsType'
import { FormLabel } from '@mui/joy'
const ContactTextInput = (props: ContactUsInput) => {

  
  return (
    <Box>
      <Container>
        <InputBase
          type="text"
          value={props.value}
          name={props.name}
          placeholder={props.placeholder}
          sx={{
            bgcolor:props.colors?.backgroundInputColor?`${props.colors?.backgroundInputColor} !important`:"transparent !important",
            width: '100%',
            p: '15px 20px',
            color: props.colors?.color?props.colors?.color:"white",
            border:
              props.error && props.touched
                ? `2px solid red`
                : props.colors?. borderColor?props.colors?. borderColor:`transparent`,
            mt: 4,
            borderRadius: props.colors?.borderRadiusInput?"50px":"0px",
            '& input::placeholder': {
              fontSize: '20px',
              color:props.colors?.placeholderColor?props.colors?.placeholderColor:"gray",
            },
            
          }}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        />
        <Typography
          sx={{
            fontSize: '12px',
            color: 'red',
          }}
        >
          {props.error && props.touched ? props.error : undefined}
        </Typography>
      </Container>
    </Box>
  )
}

export default ContactTextInput

import { Box,Typography } from '@mui/material'
import React from 'react'
import { heroSolidProps } from './HeroTypes'
import { useTranslation } from 'react-i18next'
import { useGetAllSlidersQuery } from '../../src/APIs/SectionApis'
import { imageBaseUrl } from '../../src/App'
const Hero2 = (props:heroSolidProps) => {
  const { data, isSuccess, isLoading, isError, error } =
  useGetAllSlidersQuery(undefined)
  const [_, { language: lang }] = useTranslation()
const dataHero = data?.data.length - 1

  return (
    <Box>
  {isLoading && <span className="loader"></span>}
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isLoading && isSuccess && (

    <Box
      sx={{
        minHeight: { xs: '80vh', md: '100vh' },
        width: '100%',
        background: props.backgroundColor?props.backgroundColor: 'linear-gradient(90deg ,#d3d3d3 30%,white 30%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box width={{xs:0.9,sm:0.7,lg:0.6}} sx={{
        height: { xs:1, sm: 0.8 },
        transform: { xs: 'none',sm:'translateX(50px)' ,lg: 'translateX(140px)' },
      }} >
        <img   src={`${imageBaseUrl}${data?.data[dataHero].image}`} alt="hero" style={{ width: '100%', height: '100%' }} />
      </Box>
    </Box>
      )}
    </Box>
  )
}

export default Hero2

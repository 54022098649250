import { Box } from '@mui/material'
import React from 'react'
import NewestCollection from './NewestCollection'
import NewestScroll from './NewestScroll'
import MostSellerCollection from '../Most Seller/MostSellerCollection'
import { sectionsCardType } from '../HomeTypes'
import MostSellerScroll from '../Most Seller/MostSellerScroll'
import OffersScroll from '../Offers/OffersScroll'
import TrendingScroll from '../Trending/TrendingScroll'
import OffersCollection from '../Offers/OffersCollection'
import TrendingCollection from '../Trending/TrendingCollection'

// interface NewestProps {
//     type?:string,
//   typeCollections?:string,
//     backgroundColor?:string,
//   borderRadiusBtn?: boolean,
//   color?:string
//   ADS?:boolean

// }

const Newest = (props: sectionsCardType) => {
  return (
    <Box
      mb={10}
      sx={{
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : '#ffff',
      }}
    >
      {props.type === 'NewestCollections' &&
        props.typeCollections === 'Newest' && (
          <NewestCollection
            card={props.card}
            title={props.type}
            color={props.color}
            ADS={props.ADS}
            BtnCollections={props.BtnCollections}
          />
        )}
      {props.type === 'NewestCollections' &&
        props.typeCollections === 'MostSeller' && (
          <MostSellerCollection
            card={props.card}
            title={props.type}
            color={props.color}
            ADS={props.ADS}
            BtnCollections={props.BtnCollections}
          />
        )}
      {props.type === 'NewestCollections' &&
        props.typeCollections === 'Offers' && (
          <OffersCollection
            card={props.card}
            title={props.type}
            color={props.color}
            ADS={props.ADS}
            BtnCollections={props.BtnCollections}
          />
        )}
      {props.type === 'NewestCollections' &&
        props.typeCollections === 'Trending' && (
          <TrendingCollection
            card={props.card}
            title={props.type}
            color={props.color}
            ADS={props.ADS}
            BtnCollections={props.BtnCollections}
          />
        )}

      {/* scroll Newest */}
      {props.type === 'NewestScroll' && props.typeScroll === 'Newest' && (
        <NewestScroll
          card={props.card}
          color={props.color}
          title={props.type}
          BtnCollections={props.BtnCollections}
        />
      )}

      {props.type === 'NewestScroll' && props.typeScroll === 'MostSeller' && (
        <MostSellerScroll
          card={props.card}
          color={props.color}
          title={props.type}
        />
      )}

      {props.type === 'NewestScroll' && props.typeScroll === 'Offers' && (
        <OffersScroll
          backgroundArrowColor={props.backgroundArrowColor}
          card={props.card}
          color={props.color}
          title={props.type}
        />
      )}
      {props.type === 'NewestScroll' && props.typeScroll === 'Trending' && (
        <TrendingScroll
          card={props.card}
          color={props.color}
          title={props.type}
        />
      )}
    </Box>
  )
}

export default Newest

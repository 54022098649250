import { Box, CardMedia, Stack, Typography } from '@mui/material'
import { FooterProps, KeyOfSocial } from '../FooterTypes'
import googlePay from '../google_pay.png'
import mada from '../mada.png'
import paypal from '../paypal.png'
import tabby from '../tabby.png'
import tamara from '../tamara.png'
import visa from '../visa.png'
import { ICategory } from '../Footer'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useGetAllCategoriesQuery } from '../../src/APIs/categoriesApi'
import { FaFacebookF, FaLinkedinIn, FaPinterestP } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'
import { PiTwitterLogoFill } from 'react-icons/pi'
import { cloneElement } from 'react'
import { Grid } from '@mui/material'
import Map from './Map'
const Icons = () => {
  return (
    <Stack direction={'row'} gap={1}>
      <CardMedia
        component={'img'}
        sx={{ height: { lg: 30 } }}
        src={googlePay}
      />
      <CardMedia component={'img'} sx={{ height: { lg: 30 } }} src={paypal} />
      <CardMedia component={'img'} sx={{ height: { lg: 30 } }} src={tabby} />
      <CardMedia component={'img'} sx={{ height: { lg: 30 } }} src={visa} />
      <CardMedia component={'img'} sx={{ height: { lg: 30 } }} src={tamara} />
      <CardMedia component={'img'} sx={{ height: { lg: 30 } }} src={mada} />
    </Stack>
  )
}

interface DrajiteProps extends Omit<FooterProps, 'type'> {}

function CategorySection({
  categories,
  color,
}: {
  categories: ICategory[]
  color: DrajiteProps['color']
}) {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <>
      <Stack
        width={'100%'}
        direction={'column'}
        sx={{
          py: 4,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography
            sx={{
              color: color.primary,
              fontSize: 20,
              textAlign: { xs: 'center', md: 'start' },
            }}
          >
            {language === 'en' ? 'Categories' : 'الاقسام'}
          </Typography>
        </div>
        <Stack
          direction={'column'}
          gap={5}
          width={'100%'}
          sx={{
            pt: 2,
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {categories.map((category) => (
            <Box
              key={category._id}
              sx={{
                color: color.primary,
                textDecoration: 'none !important',
              }}
              component={Link}
              to={`/departments/${category._id}`}
            >
              {language === 'en' ? category.name_en : category.name_ar}
            </Box>
          ))}
        </Stack>
      </Stack>
    </>
  )
}

function SocialSection({ social }: { social: DrajiteProps['social'] }) {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <Box sx={{ width: 'fit-content' }}>
      <Typography
        sx={{
          color: social?.bgColor,
          fontSize: 20,
          pb: 1,
          textAlign: 'center',
        }}
      >
        {language === 'en' ? 'CONNECT WITH US' : 'تواصل معنا'}
      </Typography>
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        {social &&
          (Object.keys(social) as KeyOfSocial[]).map((key) => {
            const Icon = {
              facebook: FaFacebookF,
              instagram: FiInstagram,
              linkedin: FaLinkedinIn,
              twitter: PiTwitterLogoFill,
              pinterest: FaPinterestP,
            }[key]
            if (!Icon) return null
            return (
              <Box
                component={'a'}
                key={key}
                target="_blank"
                href={social[key]}
                sx={{
                  color: social?.bgColor,
                  fontSize: 20,
                  textAlign: { xs: 'center', md: 'start' },
                  backgroundColor: social?.color,
                  border: `2px solid ${social?.bgColor}`,
                  borderRadius: '6px',
                  width: '45px',
                  height: '45px',
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                {cloneElement(<Icon />, {})}
              </Box>
            )
          })}
      </Stack>
    </Box>
  )
}

const InformationSection = ({
  information,
  color,
}: {
  information: DrajiteProps['information']
  color: DrajiteProps['color']
}) => {
  const {
    i18n: { language },
  } = useTranslation()
  const dir = language === 'ar' ? 'rtl' : 'ltr'
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'start',
        direction: dir,
        pb: 1,
      }}
    >
      <Box
        component={'a'}
        href={`tel:${information?.phone}`}
        target="_blank"
        sx={{
          color: color.primary,
          textDecoration: 'none !important',
        }}
      >
        {information?.phone}
      </Box>
      <Box
        component={'a'}
        href={`mailto:${information?.email}`}
        target="_blank"
        sx={{
          color: color.primary,
          textDecoration: 'none !important',
        }}
      >
        {information?.email}
      </Box>
      <Box
        sx={{
          color: color.primary,
          textDecoration: 'none !important',
        }}
      >
        {information?.address}
      </Box>
    </Stack>
  )
}

function Drajite({
  bgColor,
  color,
  variant,
  social,
  information,
  map,
}: DrajiteProps) {
  const { pathname } = useLocation()
  const {
    i18n: { language },
  } = useTranslation()
  const { data, isLoading, isSuccess } = useGetAllCategoriesQuery(null)

  const categories = (data?.data || []) as ICategory[]

  const dir = language === 'ar' ? 'rtl' : 'ltr'
  return (
    <Box
      sx={{
        color: color.primary,
        backgroundColor: bgColor.primary,
        direction: dir,
        display:
          pathname === '/thankYou' ||
          pathname === '/sign-in' ||
          pathname === '/register'||
          pathname === '/forgetPassword'
            ? 'none'
            : 'block',
      }}
    >
      <Grid container spacing={2} sx={{ px: 5, py: 5 }}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {variant === 'form-map' && (
            <>
              <Box sx={{ width: 300 }}>
                <Map url={map?.url || ''} />
              </Box>
            </>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InformationSection information={information} color={color} />
          <Icons />
          <SocialSection social={social} />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {variant === 'map' && (
            <>
              <Box sx={{ width: 300 }}>
                <Map url={map?.url || ''} />
              </Box>
            </>
          )}
          {(variant === 'form' || variant === 'form-map') && (
            <>
              <Stack
                sx={{
                  width: '100%',
                  gap: 2,
                  py: 2,
                }}
              >
                <Box
                  component={'input'}
                  type="text"
                  placeholder={
                    language === 'en' ? 'Email' : 'البريد الالكتروني'
                  }
                  sx={{
                    color: color.primary,
                    '::placeholder': { color: color.primary },
                    fontSize: {
                      xs: 10,
                      md: 20,
                    },
                    width: '100%',
                    backgroundColor: 'transparent',
                    padding: '5px 10px',
                    border: 'none',
                    outline: 'none !important',
                    borderBottom: `1px solid ${color.primary}`,
                  }}
                />
                <Box
                  component={'textarea'}
                  placeholder={language === 'en' ? 'Write Here' : 'اكتب هنا'}
                  rows={5}
                  sx={{
                    color: color.primary,
                    '::placeholder': { color: color.primary },
                    fontSize: {
                      xs: 10,
                      md: 20,
                    },
                    backgroundColor: 'transparent',
                    padding: '5px 10px',
                    outline: 'none !important',
                    border: `1px solid ${color.primary}`,
                  }}
                />
              </Stack>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Drajite

import { Box, CardMedia } from '@mui/material'
import { BannerProps } from './BannerType'
import { useGetBannerSectionQuery } from '../../src/APIs/bannerApi'
import { imageBaseUrl } from '../../src/App'
// import img from '../../src/img.jpg'
// Banner with full width
const Banner = (props: BannerProps) => {
  const { data } = useGetBannerSectionQuery(undefined)

  const banner = data?.data.filter((b) => b.alignment === 'horizontal')[0]
  const bannerVertical = data?.data.filter((b) => b.alignment === 'vertical')[0]

  return (
    <Box
      sx={{
        display: { xs: 'block', sm: 'block' },
        mt: { xs: 4, md: 2 },
      }}
    >
      {props.bannerHorizontal && (
        <Box
          // width={props.width ? '50%' : '100%'}
          // mx={props.width ? 'auto' : '0'}
          // height={{ md: '25vh', sm: '20vh', xs: '15vh', lg: '30vh' }}
          // my={5}
          sx={{
            background: `url("${imageBaseUrl + banner?.image}") center center`,
            backgroundRepeat: 'no-repeat',
            width: '100%',
            my:"auto",
            height: '200px',
            backgroundSize: '100% ',
          }}
        ></Box>
      )}
      {props.bannerVertical && (
        <Box width="100%" height={'100%'}>
          <CardMedia
            component="img"
            height="100%"
            width="100%"
            image={imageBaseUrl + bannerVertical?.image}
            // image={img}
          />
        </Box>
      )}
    </Box>
  )
}

export default Banner

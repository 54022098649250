import { Box, Typography, Stack, Button } from '@mui/material'
import React, { useRef } from 'react'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide  } from 'swiper/react'
// import data from '.././data.json'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/navigation'
import { EffectCoverflow } from 'swiper/modules'
import { Navigation } from 'swiper/modules'
import { Card } from '../../Cards/Card'
import { MostSellerCollectionProps } from '../Most Seller/MostSellerCollection'
import {
  useGetMostNewiestProductsQuery,
  useGetMostSellingProductsQuery,
} from '../../src/APIs/ProductApis'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
const OffersScroll = (props: MostSellerCollectionProps) => {
  console.log('props', props);
  
  const {
    data: MostSeller,
    isSuccess: MostSuccess,
    isError: MostError,
    error: MostErrMsg,
  } = useGetMostSellingProductsQuery(undefined)
  const {
    data: Newest,
    isSuccess,
    isLoading,
    error,
    isError,
  } = useGetMostNewiestProductsQuery(undefined)
  const [_, { language: lang }] = useTranslation()
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const getData =
    props.title === 'NewestScroll' ? Newest?.data : MostSeller?.data
  console.log('getData', getData)

  return (
    <Box>
      {isError && MostError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {isError
              ? (error as any)?.data && (error as any)?.data[`error_${lang}`]
              : (MostErrMsg as any)?.data &&
                (MostErrMsg as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {isSuccess &&
        MostSuccess &&
        !isError &&
        !MostError &&
        getData?.length > 0 && (
          <>
            <Box textAlign={'center'}>
              <Typography
                sx={{
                  fontSize: { xs: '1.8rem', sm: '2.5rem', lg: '4rem' },
                  fontWeight: 'bold',
                  color: props.color ? props.color : 'gray',
                }}
              >
                {props.title === 'NewestScroll'
                  ? lang === 'en'
                    ? 'Newest'
                    : 'الاحدث'
                  : lang === 'en'
                  ? 'Most Seller'
                  : 'الاكثر مبيعا'}
              </Typography>
            </Box>

            <Box mx={'auto'} width={{ xs: 1, md: 0.9, xl: 0.7 }} p={2} mt={2}>
              <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                // centeredSlidesBounds= {true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
      
                navigation={{
                  prevEl: navigationPrevRef?.current,
                  nextEl: navigationNextRef?.current,
                }}
                loop={true}
                slidesPerView={3}
                onBeforeInit={(swiper) => {
                  if (
                    swiper.params.navigation &&
                    typeof swiper.params.navigation !== "boolean"
                  ) {
                    swiper.params.navigation.prevEl = navigationPrevRef?.current;
                    swiper.params.navigation.nextEl = navigationNextRef?.current;
                  }
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  719: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                }}
                coverflowEffect={{
                  rotate: 10,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                modules={[EffectCoverflow, Navigation,Autoplay]}
                className="mySwiper"
              >
                <Stack
                  direction={'row'}
                  position={'absolute'}
                  sx={{
                    top: '40%',
                    left: 0,
                    zIndex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Button
                    ref={navigationNextRef}
                
                    sx={{
                      background: props?.backgroundArrowColor
                        ? `${props.backgroundArrowColor} !important`
                        : 'gray !important',
                        borderRadius:"50%",
                        height:"40px",
                        minWidth:"0px",
                        width:"40px",
                      
                      
                    }}
                  >
                    <ArrowBackIosIcon
                      sx={{
                        fontSize: '20px',
                        color: props.color ? props.color : 'gray',
                       ml:"40%"
                      }}
                    />
                  </Button>
                  <Button
                    ref={navigationPrevRef}
                    sx={{
                      background: props?.backgroundArrowColor
                        ? `${props.backgroundArrowColor} !important`
                        : 'gray !important',
                        borderRadius:"50%",
                      
                        height:"40px",
                        minWidth:"0px",
                        width:"40px",

                    }}
                  >
                    <ArrowForwardIosIcon
                      sx={{
                        fontSize: '20px',
                        color: props.color ? props.color : 'gray',
                        // mr:"40%"
                      }}
                    />
                  </Button>
                </Stack>

                {getData?.map((item: any, index: number) => (
                  <SwiperSlide key={index}>
                    <Card
                      data={item}
                      colors={props.card.colors}
                      border={props.card.border}
                      borderRadius={props.card.borderRadius}
                      wide={props.card.wide}
                      width={props.card.width}
                      height={props.card.height}
                      title={props.card.title}
                      desc={props.card.desc}
                      button={props.card.button}
                      favorite={props.card.favorite}
                      hoverWithFav={props.card.hoverWithFav}
                      hover={props.card.hover}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </>
        )}
    </Box>
  )
}

export default OffersScroll

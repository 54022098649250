import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { TCartColors } from './CartTypes'
import {
  useAddToCartMutation,
  useDeleteFromCartMutation,
  useGetAllCartsQuery,
} from '../src/APIs/cartApi'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { imageBaseUrl } from '../src/App'
import { Card } from '../Cards/Card'

const cartPrice = (total: number, quantity: number, price: number) => {
  const pricePlusTax = total / quantity
  const tax = pricePlusTax - price
  return tax
}

export const Cart05 = ({ colors }: TCartColors) => {
  const [, { language: lng }] = useTranslation()
  const { data, isSuccess, isError, isLoading } = useGetAllCartsQuery(undefined)
  const [removeItem] = useDeleteFromCartMutation()
  const [addToCart] = useAddToCartMutation()

  const nav = useNavigate()

  const updateQty = (method: string, id: string, qty: number) => {
    if (method === '+') {
      // setQty((prev) => prev + 1),
      addToCart({
        quantity: qty + 1,
        properties: [],
        id: id,
      })
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        })
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    } else {
      // setQty((prev) => prev - 1)
      addToCart({
        quantity: qty - 1,
        properties: [],
        id: id,
      })
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        })
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    }
  }

  return (
    <Grid container sx={{ p: { xs: 1, md: 3 }, minHeight: '60vh', mt: 4 }}>
      {isError && !isLoading && (
        <Typography
          color={'error'}
          sx={{ m: 5, fontSize: '2rem', textAlign: 'center', width: '100%' }}
        >
          {lng === 'en' ? 'No products found' : 'لا يوجد منتجات'}
        </Typography>
      )}
      {isSuccess && !isError && !isLoading ? (
        <>
          <Grid item xs={12} md={8} sx={{ p: 3 }}>
            {/* PRODUCTS */}
            <Stack direction={'column'} sx={{ gap: 3 }}>
              {/* CASH */}
              {data.data.cashItems.items.length > 0 && (
                <Typography
                  variant="h4"
                  sx={{
                    color: colors.title,
                  }}
                >
                  {lng === 'en' ? 'Cash on delivery' : 'الدفع عند الإستلام'}
                </Typography>
              )}
              {data?.data?.cashItems?.items?.length > 0 &&
                data?.data?.cashItems.items.map((item: any) => (
                  <Stack
                    key={item._id}
                    direction={{ xs: 'column', md: 'row' }}
                    sx={{
                      justifyContent: 'space-between',
                      width: '100%',
                      bgcolor: colors.productsBg,
                      p: 1,
                      border: '1px solid black',
                    }}
                  >
                    <Stack
                      direction={'row'}
                      sx={{
                        gap: 5,
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          background: `url("${
                            imageBaseUrl + item.product.images[0]
                          }") center center`,
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat',
                          width: '150px',
                          height: '250px',
                        }}
                      />
                      <Stack
                        direction={'column'}
                        sx={{
                          justifyContent: 'center',
                          height: '100%',
                          gap: 5,
                        }}
                      >
                        <Typography
                          sx={{
                            color: colors.title,
                            fontSize: '1.5rem',
                          }}
                        >
                          {item.product[`title_${lng === 'en' ? 'en' : 'ar'}`]}
                        </Typography>
                        <Box
                          className="desc"
                          sx={{
                            fontSize: '15px !important',
                            fontWeight: 'normal',
                            color: colors.desc,
                            wordBreak: 'break-word',
                          }}
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.product[
                                `description_${lng === 'en' ? 'en' : 'ar'}`
                              ]?.split(' ').length > 7
                                ? `${item?.product[
                                    `description_${lng === 'en' ? 'en' : 'ar'}`
                                  ]
                                    ?.split(' ')
                                    .slice(0, 7)
                                    .join(' ')} ...`
                                : item?.product[
                                    `description_${lng === 'en' ? 'en' : 'ar'}`
                                  ],
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      direction={'row'}
                      sx={{
                        border: '1px solid black',
                        borderRadius: 1,
                        alignItems: 'center',
                        alignSelf: 'center',
                        my: 1,
                        height: 'fit-content',
                      }}
                    >
                      <Button
                        size="small"
                        onClick={() =>
                          updateQty('-', item.product._id, item.quantity)
                        }
                        disabled={item.product.quantity === 1}
                        sx={{
                          color: 'black',
                          minWidth: 40,
                        }}
                      >
                        -
                      </Button>
                      <Typography>{item.quantity}</Typography>
                      <Button
                        size="small"
                        onClick={() =>
                          updateQty('+', item.product._id, item.quantity)
                        }
                        sx={{
                          color: 'black !important',
                          minWidth: 45,
                        }}
                      >
                        +
                      </Button>
                    </Stack>
                    <Stack
                      direction={'column'}
                      sx={{ justifyContent: 'space-evenly' }}
                    >
                      <DeleteOutlinedIcon
                        sx={{ cursor: 'pointer', color: colors.remove }}
                        onClick={() =>
                          removeItem(item.product.id)
                            .unwrap()
                            .then((res) => {
                              toast.success(
                                res[`success_${lng === 'en' ? 'en' : 'ar'}`]
                              )
                            })
                            .catch((e) =>
                              toast.error(
                                e.data[`error_${lng === 'en' ? 'en' : 'ar'}`]
                              )
                            )
                        }
                      />
                      <Box>
                        <Typography>
                          {item.product.priceAfterDiscount > 0 && (
                            <Box
                              component={'span'}
                              sx={{
                                color: colors.priceBefore,
                                textDecoration: `line-through 1px solid ${colors.priceBefore}`,
                                fontSize:
                                  item.product.priceAfterDiscount > 0
                                    ? '0.7rem'
                                    : '1.5rem',
                              }}
                            >
                              {`${
                                cartPrice(
                                  item.total,
                                  item.quantity,
                                  item.product.finalPrice
                                ) + item.product.priceBeforeDiscount
                              } ${lng === 'en' ? 'SAR' : 'ر.س'}`}
                            </Box>
                          )}
                          <Box
                            sx={{
                              color: colors.priceAfter,
                              fontSize: '1.5rem',
                              fontWeight: 'bold',
                            }}
                          >
                            <Box component={'span'} sx={{ mx: 0.5 }}>
                              {lng === 'en' ? 'SAR' : 'ر.س'}
                            </Box>
                            {item.product.priceAfterDiscount > 0
                              ? cartPrice(
                                  item.total,
                                  item.quantity,
                                  item.product.finalPrice
                                ) + item.product.priceAfterDiscount
                              : cartPrice(
                                  item.total,
                                  item.quantity,
                                  item.product.finalPrice
                                ) + item.product.priceBeforeDiscount}
                          </Box>
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                ))}
              {/* ONLINE */}
              {data.data.onlineItems.items.length > 0 && (
                <Typography
                  variant="h4"
                  sx={{
                    color: colors.title,
                  }}
                >
                  {lng === 'en' ? 'Online on delivery' : 'الدفع اونلاين'}
                </Typography>
              )}
              {data?.data?.onlineItems?.items?.length > 0 &&
                data?.data?.onlineItems.items.map((item: any) => (
                  <Stack
                    key={item._id}
                    direction={{ xs: 'column', md: 'row' }}
                    sx={{
                      justifyContent: 'space-between',
                      width: '100%',
                      bgcolor: colors.productsBg,
                      p: 1,
                      border: '1px solid black',
                    }}
                  >
                    <Stack
                      direction={'row'}
                      sx={{
                        gap: 5,
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          background: `url("${
                            imageBaseUrl + item.product.images[0]
                          }") center center`,
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat',
                          width: '150px',
                          height: '250px',
                        }}
                      />
                      <Stack
                        direction={'column'}
                        sx={{
                          justifyContent: 'center',
                          height: '100%',
                          gap: 5,
                        }}
                      >
                        <Typography
                          sx={{
                            color: colors.title,
                            fontSize: '1.5rem',
                          }}
                        >
                          {item.product[`title_${lng === 'en' ? 'en' : 'ar'}`]}
                        </Typography>
                        <Box
                          className="desc"
                          sx={{
                            fontSize: '15px !important',
                            fontWeight: 'normal',
                            color: colors.desc,
                            wordBreak: 'break-word',
                          }}
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.product[
                                `description_${lng === 'en' ? 'en' : 'ar'}`
                              ]?.split(' ').length > 7
                                ? `${item?.product[
                                    `description_${lng === 'en' ? 'en' : 'ar'}`
                                  ]
                                    ?.split(' ')
                                    .slice(0, 7)
                                    .join(' ')} ...`
                                : item?.product[
                                    `description_${lng === 'en' ? 'en' : 'ar'}`
                                  ],
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      direction={'row'}
                      sx={{
                        border: '1px solid black',
                        borderRadius: 1,
                        alignItems: 'center',
                        alignSelf: 'center',
                        my: 1,
                        height: 'fit-content',
                      }}
                    >
                      <Button
                        size="small"
                        onClick={() =>
                          updateQty('-', item.product._id, item.quantity)
                        }
                        disabled={item.product.quantity === 1}
                        sx={{
                          color: 'black',
                          minWidth: 40,
                        }}
                      >
                        -
                      </Button>
                      <Typography>{item.quantity}</Typography>
                      <Button
                        size="small"
                        onClick={() =>
                          updateQty('+', item.product._id, item.quantity)
                        }
                        sx={{
                          color: 'black !important',
                          minWidth: 45,
                        }}
                      >
                        +
                      </Button>
                    </Stack>
                    <Stack
                      direction={'column'}
                      sx={{ justifyContent: 'space-evenly' }}
                    >
                      <DeleteOutlinedIcon
                        sx={{ cursor: 'pointer', color: colors.remove }}
                        onClick={() =>
                          removeItem(item.product.id)
                            .unwrap()
                            .then((res) => {
                              toast.success(
                                res[`success_${lng === 'en' ? 'en' : 'ar'}`]
                              )
                            })
                            .catch((e) =>
                              toast.error(
                                e.data[`error_${lng === 'en' ? 'en' : 'ar'}`]
                              )
                            )
                        }
                      />
                      <Box>
                        <Typography>
                          {item.product.priceAfterDiscount > 0 && (
                            <Box
                              component={'span'}
                              sx={{
                                color: colors.priceBefore,
                                textDecoration: `line-through 1px solid ${colors.priceBefore}`,
                                fontSize:
                                  item.product.priceAfterDiscount > 0
                                    ? '0.7rem'
                                    : '1.5rem',
                              }}
                            >
                              {`${
                                cartPrice(
                                  item.total,
                                  item.quantity,
                                  item.product.finalPrice
                                ) + item.product.priceBeforeDiscount
                              } ${lng === 'en' ? 'SAR' : 'ر.س'}`}
                            </Box>
                          )}
                          <Box
                            sx={{
                              color: colors.priceAfter,
                              fontSize: '1.5rem',
                              fontWeight: 'bold',
                            }}
                          >
                            <Box component={'span'} sx={{ mx: 0.5 }}>
                              {lng === 'en' ? 'SAR' : 'ر.س'}
                            </Box>
                            {item.product.priceAfterDiscount > 0
                              ? cartPrice(
                                  item.total,
                                  item.quantity,
                                  item.product.finalPrice
                                ) + item.product.priceAfterDiscount
                              : cartPrice(
                                  item.total,
                                  item.quantity,
                                  item.product.finalPrice
                                ) + item.product.priceBeforeDiscount}
                          </Box>
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                border: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                py: 3,
                px: 5,
                gap: 3,
                bgcolor: colors.orderSummaryBg,
                color: colors.orderSummaryBody,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bolder',
                  fontSize: '1.1rem',
                  color: colors.orderSummary,
                  bgcolor: colors.orderSummaryTitleBg,
                  p: 1,
                }}
              >
                {lng === 'en' ? 'Order summary' : 'ملخص الطلب'}
              </Typography>
              <Divider />
              {data.data.cashItems.totalPrice > 0 && (
                <Typography
                  sx={{
                    justifyContent: 'space-between',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Box component={'span'}>
                    {lng === 'en' ? 'Cash amount: ' : 'اجمالي الكاش: '}
                  </Box>
                  <Box component={'span'}>
                    {' '}
                    {data.data.cashItems.totalPrice}{' '}
                    {lng === 'en' ? 'SAR' : 'ر.س'}
                  </Box>
                </Typography>
              )}
              {data.data.onlineItems.totalPrice > 0 && (
                <Typography
                  sx={{
                    justifyContent: 'space-between',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Box component={'span'}>
                    {lng === 'en' ? 'Online amount: ' : 'اجمالي الاونلاين: '}
                  </Box>
                  <Box component={'span'}>
                    {' '}
                    {data.data.onlineItems.totalPrice}{' '}
                    {lng === 'en' ? 'SAR' : 'ر.س'}
                  </Box>
                </Typography>
              )}
              <Typography
                sx={{
                  justifyContent: 'space-between',
                  width: '100%',
                  display: 'flex',
                  fontWeight: 'bold',
                }}
              >
                <Box
                  component={'span'}
                  sx={{
                    direction: 'ltr',
                    display: 'flex',
                  }}
                >
                  <Typography>
                    {lng === 'en' ? 'Total: ' : 'الإجمالي: '}
                  </Typography>
                  <Typography
                    component={'span'}
                    sx={{
                      fontSize: '0.75rem',
                      color: colors.quantity,
                    }}
                  >
                    ({data.data.totalQuantity} {lng === 'en' ? 'Items' : 'منتج'}
                    )
                  </Typography>
                </Box>
                <Box component={'span'}>
                  {data.data.totalPrice} {lng === 'en' ? 'SAR' : 'ر.س'}
                </Box>
              </Typography>
            </Box>
            <Button
              variant="contained"
              fullWidth
              sx={{
                my: 3,
                borderRadius: 5,
                py: 1,
                color: colors.button,
                bgcolor: `${colors.buttonBg} !important`,
              }}
              onClick={() => {
                nav('/checkout')
              }}
            >
              {lng === 'en' ? 'Checkout' : 'الدفع'}
            </Button>
          </Grid>
        </>
      ) : (
        isLoading && !isSuccess && <span className="loader"></span>
      )}
    </Grid>
  )
}

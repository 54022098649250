import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuIcon from '@mui/icons-material/Menu'
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined'
import { Badge, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import * as React from 'react'
// import { ReactElement } from 'react'
import CallIcon from '@mui/icons-material/Call'
import CategoryIcon from '@mui/icons-material/Category'
import HomeIcon from '@mui/icons-material/Home'
import InfoIcon from '@mui/icons-material/Info'
import MapsUgcIcon from '@mui/icons-material/MapsUgc'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavDrawer } from './NavDrawer'
import { NavItems, NavTypes } from './NavTypes'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import Logo from './Logo'
import logo from '../src/assets/logo.png'
import { NavData } from './navdata'
import ProfileButton from './publicComponents/ProfileButton'
import { NavLink } from 'react-router-dom'
import NavMenu from './NavMenu'
import { useGetAllCartsQuery } from '../src/APIs/cartApi'
import { useGetAllSavedProductsQuery } from '../src/APIs/SavedProductApi'
import {
  useGetAllCategoriesQuery,
  useGetAllSubCategoriesByCategoryIdQuery,
} from '../src/APIs/categoriesApi'
import { useState } from 'react'

interface NavAccordionItemProps extends NavTypes {
  item: NavItems
}

interface NavAccordionSubCategoryProps extends NavTypes {
  _id: string
  open: boolean
  setHasSubCategories: any
}

const NavAccordionSubCategory: React.FC<NavAccordionSubCategoryProps> = (
  props
) => {
  const { data, isSuccess } = useGetAllSubCategoriesByCategoryIdQuery(props._id)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const {
    i18n: { language },
  } = useTranslation()
  const subCategories = data?.data || []

  React.useEffect(() => {
    if (isSuccess) {
      if (subCategories.length > 0) {
        props.setHasSubCategories(true)
      } else {
        props.setHasSubCategories(false)
      }
    }
  }, [isSuccess])
  return (
    <Box className={`initiallyCollapsed ${props.open ? 'active' : ''}`}>
      {subCategories.map((subCategory: any) => (
        <Box
          sx={{
            width: '90%',
            ...(language === 'en' ? { ml: 'auto' } : { mr: 'auto' }),
            // mb: 1,
            py: '5px',

            color:
              pathname === `/departments`
                ? props.colors.activeLinkColor
                : props.colors.linkTextColor,
            bgColor: `${props.colors.linkTextBgColor} !important`,
            cursor: 'pointer',
            fontSize: {
              xs: '1rem',
              md: '1rem',
              lg: '1.2rem',
            },
            textTransform: 'capitalize',
            textAlign: 'start',
            textDecoration: 'none !important',
            borderRight:
              language === 'en'
                ? 'none'
                : `2px solid ${props.colors.borderColor}`,
            borderLeft:
              language === 'ar'
                ? 'none'
                : `2px solid ${props.colors.borderColor}`,
            borderRadius: props.buttonStyle
              ? props.buttonStyle === 'radius'
                ? 3
                : 6
              : 0,
            // py: '0.3rem !important',
            ...(language === 'en' ? { pl: '1rem' } : { pr: '1rem' }),
          }}
          onClick={() => navigate(`/departments/${props._id}?subCategory=${subCategory._id}`)}
        >
          {language === 'en' ? subCategory.name_en : subCategory.name_ar}
        </Box>
      ))}
    </Box>
  )
}
const NavAccordionCategory: React.FC<NavAccordionItemProps> = (props) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const {
    i18n: { language },
  } = useTranslation()
  const { category } = props as any
  const [open, setOpen] = useState(false)
  const [hasSubCategories, setHasSubCategories] = useState(false)
  return (
    <>
      <Box
        sx={{
          width: props.direction === 'vertical' ? 1 : 170,
          // mb: 1,

          border:
            props.blockStyle || props.buttonStyle
              ? `1px solid ${props.colors.borderColor}`
              : 'none',
          borderBottom: !props.buttonStyle
            ? `2px solid ${
                pathname === `/departments`
                  ? props.colors.activeLinkColor
                  : null
              }`
            : `1px solid ${props.colors.borderColor}`,
          color:
            pathname === `/departments`
              ? props.colors.activeLinkColor
              : props.colors.linkTextColor,
          bgColor: `${props.colors.linkTextBgColor} !important`,
          cursor: 'pointer',
          fontSize: {
            xs: '1rem',
            md: '1rem',
            lg: '1.2rem',
          },
          textTransform: 'capitalize',
          textAlign: 'center',
          textDecoration: 'none !important',
          borderRadius: props.buttonStyle
            ? props.buttonStyle === 'radius'
              ? 3
              : 6
            : 0,
          p: props.blockStyle
            ? '.7rem 1rem'
            : props.buttonStyle
            ? '8px 10px'
            : 0,
          px: '0px !important',
        }}
        onClick={() => {
          if (hasSubCategories) {
            setOpen(!open)
            return
          }

          navigate(`/departments/${category._id}`)

        }}
      >
        {language === 'en' ? category.name_en : category.name_ar}
        <NavAccordionSubCategory {...props} _id={category._id} open={open} setHasSubCategories={setHasSubCategories} />
      </Box>
    </>
  )
}

const NavAccordionItem: React.FC<NavAccordionItemProps> = (props) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const {
    i18n: { language },
  } = useTranslation()

  const { data } = useGetAllCategoriesQuery(null)
  const categories = data?.data || []

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        rowGap: '0px !important',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {props.item.nestedLinks ? (
        categories.map((category: any) => (
          <NavAccordionCategory
            key={category._id}
            category={category}
            {...props}
            item={props.item}
          />
        ))
      ) : (
        <>
          <Box
            sx={{
              width: props.direction === 'vertical' ? 1 : 170,
              // mb: 1,

              border:
                props.blockStyle || props.buttonStyle
                  ? `1px solid ${props.colors.borderColor}`
                  : 'none',
              borderBottom: !props.buttonStyle
                ? `2px solid ${
                    pathname === `/departments`
                      ? props.colors.activeLinkColor
                      : null
                  }`
                : `1px solid ${props.colors.borderColor}`,
              color:
                pathname === `/departments`
                  ? props.colors.activeLinkColor
                  : props.colors.linkTextColor,
              bgColor: `${props.colors.linkTextBgColor} !important`,
              cursor: 'pointer',
              fontSize: {
                xs: '1rem',
                md: '1rem',
                lg: '1.2rem',
              },
              textTransform: 'capitalize',
              textAlign: 'center',
              textDecoration: 'none !important',
              borderRadius: props.buttonStyle
                ? props.buttonStyle === 'radius'
                  ? 3
                  : 6
                : 0,
              p: props.blockStyle
                ? '.7rem 1rem'
                : props.buttonStyle
                ? '8px 10px'
                : 0,
              px: '0px !important',
            }}
            onClick={() => navigate(`${props.item.path}`)}
          >
            {language === 'en' ? props.item.link_en : props.item.link_ar}
          </Box>
        </>
      )}
    </Box>
  )
}

export const NavAccordion: React.FC<NavTypes> = (props) => {
  const navLinks = NavData()
  return (
    <Box py={3} width={1}>
      {navLinks.map((item: NavItems) => (
        <NavAccordionItem {...props} key={item.path} item={item} />
      ))}
    </Box>
  )
}

export const Navbar = (props: NavTypes) => {
  const [, { language: lng, changeLanguage }] = useTranslation()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const theme = useTheme()
  const phoneScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const navigate = useNavigate()
  const navLinks = NavData()
  React.useEffect(() => {
    if (props.direction === 'vertical') {
      if (window.innerWidth > 1200) {
        document.body.style.width = 'calc(100% - 252px)'
      } else {
        document.body.style.width = '100%'
      }
      document.body.style.direction = lng === 'en' ? 'rtl' : 'ltr'
    } else {
      document.body.style.width = '100%'
    }
  }, [lng, props.direction, window.innerWidth])
  const muiIcons = [
    <HomeIcon
      key="home"
      sx={{
        color: 'A3A3A3',
        ml: lng === 'en' ? '-4px' : '8px',
        mr: lng === 'en' ? '8px' : '-4px',
      }}
    />,
    <CategoryIcon
      key={'category'}
      sx={{
        color: 'A3A3A3',
        ml: lng === 'en' ? '-4px' : '8px',
        mr: lng === 'en' ? '8px' : '-4px',
      }}
    />,
    <InfoIcon
      key={'info'}
      sx={{
        color: 'A3A3A3',
        ml: lng === 'en' ? '-4px' : '8px',
        mr: lng === 'en' ? '8px' : '-4px',
      }}
    />,
    <MapsUgcIcon
      key={'message'}
      sx={{
        color: 'A3A3A3',
        ml: lng === 'en' ? '-4px' : '8px',
        mr: lng === 'en' ? '8px' : '-4px',
      }}
    />,
    <CallIcon
      key={'call'}
      sx={{
        color: 'A3A3A3',
        ml: lng === 'en' ? '-4px' : '8px',
        mr: lng === 'en' ? '8px' : '-4px',
      }}
    />,
  ]
  const toggleLanguage = () => {
    if (lng === 'en') {
      changeLanguage('ar')
    } else {
      changeLanguage('en')
    }
  }
  const appBarZindex = theme.zIndex.drawer + 1
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [activeButton, setActiveButton] = React.useState<null | string>(null)
  const { data: dataCart, error: errorCart } = useGetAllCartsQuery(undefined)
  const { data: favPros, error: errorFav } =
    useGetAllSavedProductsQuery(undefined)
  const handleClick = (
    event: React.MouseEvent<any, MouseEvent>,
    buttonId: string
  ) => {
    setAnchorEl(event.currentTarget)
    setActiveButton(buttonId)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setActiveButton(null)
  }
  const { pathname } = useLocation()
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <Box>
      <Box
        sx={{
          display:
            pathname === '/sign-in' ||
            pathname === '/register' ||
            pathname === '/thankYou' ||
            pathname === '/forgetPassword'
              ? 'none'
              : 'flex',
          direction: lng === 'en' ? 'ltr' : 'rtl',
          flexDirection: props.direction === 'horizontal' ? 'row' : 'column',
          position: props.direction === 'horizontal' ? 'relative' : 'fixed',
          zIndex: props.direction === 'vertical' ? appBarZindex : 0,
          width: {
            lg: props.direction === 'vertical' ? 253 : '100%',
            xs: '100%',
          },
          height: {
            lg: props.direction === 'vertical' ? '100vh' : 'inherit',
            xs: 'inherit',
          },
          top: 0,
          left: lng === 'en' ? 0 : 'auto',
          right: lng === 'ar' ? 0 : 'auto',
          overflowY: 'auto',
          bgcolor: props.navBg ? props.colors.backgroundColor : 'white',
        }}
      >
        <CssBaseline />
        <AppBar
          component="nav"
          sx={{
            bgcolor: props.navBg ? props.colors.backgroundColor : 'white',
            position: 'relative',
            boxShadow: 'none',
            height: 'inherit',
            px: phoneScreen ? 2 : 0,
          }}
        >
          <Toolbar sx={{ height: 'inherit', p: '0px !important' }}>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { lg: 'none' },
                color: props.colors.menuItemColor,
              }}
            >
              <MenuIcon />
            </IconButton>
            <Grid
              container
              sx={{
                height: 'inherit',
                py: phoneScreen ? 0 : 5,
                px: phoneScreen ? 2 : 0,
                justifyContent: { xs: 'flex-end', lg: 'space-between' },
                alignItems: 'center',
                flexWrap: props.direction === 'vertical' ? 'wrap' : 'nowrap',
              }}
            >
              <Grid item xs={props.direction === 'vertical' && 12}>
                {!phoneScreen && (
                  <Box
                    sx={{
                      height: '80px',
                      width: '165px',
                      mx: 'auto',
                    }}
                  >
                    <Logo imagePath={logo} extraObjectFit={'contain'} />
                  </Box>
                )}
              </Grid>
              {!phoneScreen && (
                <Grid
                  item
                  xs={props.direction === 'vertical' && 12}
                  sx={{
                    display: { xs: 'none', sm: 'block' },
                    mx: props.direction === 'vertical' ? 'auto' : 0,
                  }}
                >
                  <Stack
                    direction={
                      props.direction === 'horizontal' ? 'row' : 'column'
                    }
                    alignItems={
                      props.direction === 'horizontal' ? 'center' : 'flex-start'
                    }
                    justifyContent={'center'}
                    flexWrap={'wrap'}
                    columnGap={props.blockStyle ? 0 : 8}
                    // rowGap={props.direction === 'vertical' ? 2 : 0}
                    py={1}
                  >
                    {/* TODO */}
                    <NavAccordion {...props} />
                    {/* TODO */}
                  </Stack>
                </Grid>
              )}
              {/* icon button */}
              <Grid
                item
                sx={{
                  flexDirection: {
                    xs: props.direction === 'vertical' ? 'row-reverse' : 'row',
                  },
                }}
                lg={props.direction === 'vertical' && 12}
              >
                <Stack
                  direction={{
                    lg: props.direction === 'vertical' ? 'column' : 'row',
                    xs: 'row',
                  }}
                  alignItems={'center'}
                  gap={2}
                >
                  <Badge
                    badgeContent={
                      dataCart && !errorCart ? dataCart.data.totalQuantity : 0
                    }
                    sx={{
                      '.MuiBadge-badge': {
                        bgcolor: props.colors.buttonBgColor,
                      },
                    }}
                  >
                    <AddShoppingCartOutlinedIcon
                      onClick={() => navigate('/cart')}
                      sx={{
                        color: `${props.colors.buttonBgColor}`,
                        cursor: 'pointer',
                        fontSize: {
                          xs: '1.5rem',
                          sm: '1.8rem',
                          md: '2.2rem',
                          lg: '2.3rem',
                        },
                      }}
                    />
                  </Badge>
                  <Badge
                    badgeContent={
                      favPros && !errorFav ? favPros.data.favourite.length : 0
                    }
                    sx={{
                      '.MuiBadge-badge': {
                        bgcolor: props.colors.buttonBgColor,
                      },
                    }}
                  >
                    <FavoriteBorderOutlinedIcon
                      onClick={() => navigate('/savedProducts')}
                      sx={{
                        color: `${props.colors.buttonBgColor}`,
                        cursor: 'pointer',
                        fontSize: {
                          xs: '1.5rem',
                          sm: '1.8rem',
                          md: '2.2rem',
                          lg: '2.3rem',
                        },
                      }}
                    />
                  </Badge>
                  <ProfileButton
                    iconColor={props.colors.iconColor || 'black'}
                    bgColor={props.colors.buttonBgColor || 'transparent'}
                    textColor={props.colors.buttonBgColor || 'black'}
                  />
                  <Button
                    onClick={toggleLanguage}
                    sx={{
                      minWidth: 0,
                      width: {
                        md: 50,
                        xs: 40,
                      },
                      height: {
                        md: 40,
                        xs: 30,
                      },
                      color: props.colors.buttonTextColor,
                      bgcolor: props.colors.buttonBgColor,
                      // fontSize: { xs: '.7rem', md: '.9rem' },
                      '&:hover': {
                        bgcolor: `${props.colors.buttonBgColor} !important`,
                      },
                    }}
                  >
                    {lng === 'en' ? 'Ar' : 'En'}
                  </Button>
                </Stack>
              </Grid>
              {/* icon button */}
            </Grid>
          </Toolbar>
        </AppBar>
        <NavDrawer
          {...props}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          muiIcons={muiIcons}
        />
      </Box>
    </Box>
  )
}

import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import data from '.././data.json'

import 'swiper/css'
import 'swiper/css/effect-coverflow'
import { EffectCoverflow } from 'swiper/modules'
import { Autoplay } from 'swiper/modules'

import { Card } from '../../Cards/Card'
import { useGetMostNewiestProductsQuery, useGetMostSellingProductsQuery } from '../../src/APIs/ProductApis'
import { useTranslation } from 'react-i18next'
import { MostSellerCollectionProps } from './MostSellerCollection'

const MostSellerScroll = (props: MostSellerCollectionProps) => {
  const { data:MostSeller, isSuccess:MostSuccess,isError:MostError,error:MostErrMsg } = useGetMostSellingProductsQuery(undefined)
  const { data:Newest, isSuccess,isLoading,error,isError } = useGetMostNewiestProductsQuery(undefined)
  const getData = props.title==="NewestScroll"?Newest?.data:MostSeller?.data
  const [_, { language: lang }] = useTranslation()
  const colors = {
    primary: 'white',
    secondary: 'black',
    backgroundPrimary: '#b0b4c5',
    backgroundSecondary: '#d2d9d5',
  }


  return (
    <Box py={5}>
       {isError&&MostError && (
        <Box
        sx={{
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          minHeight:"80vh"
        }}
        >

        <Typography
          fontSize={'1.5rem'}
          my={10}
          textAlign={'center'}
          color="error"
        >
         {isError?(error as any)?.data&& (error as any)?.data[`error_${lang}`]:(MostErrMsg as any)?.data&& (MostErrMsg as any)?.data[`error_${lang}`]}
        </Typography>
        </Box>
      )}
        {(isSuccess&&MostSuccess ) && (!isError&&!MostError) &&getData?.length > 0&&(
          <>
             {/* Title */}
      <Box textAlign={'center'}>
        <Typography
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.5rem', lg: '4rem' },
            fontWeight: 'bold',
            color: props.color ? props.color : colors.secondary,
          }}
        >
          {props.title === 'NewestScroll'
            ? lang === 'en'
              ? 'Newest'
              : 'الاحدث'
            : lang === 'en'
            ? 'Most Seller'
            : 'الاكثر مبيعا'}
        </Typography>
      </Box>

      {/* slider */}
      <Box mx={'auto'} width={{ xs: 1, md: 0.9, xl: 0.7 }} mt={5} py={2}>
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          
          centeredSlides={true}
          slidesPerView={3.5}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}

          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            719: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          coverflowEffect={{
            rotate: 5,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          modules={[EffectCoverflow,Autoplay]}
          className="mySwiper"
        >
          {getData?.map((item: any, index: any) => (
            <SwiperSlide key={index}>
              <Card
                data={item}
                colors={props.card.colors}
                border={props.card.border}
                borderRadius={props.card.borderRadius}
                wide={props.card.wide}
                width={props.card.width}
                height={props.card.height}
                title={props.card.title}
                desc={props.card.desc}
                button={props.card.button}
                favorite={props.card.favorite}
                hoverWithFav={props.card.hoverWithFav}
                hover={props.card.hover}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
          </>
        )}
   
    </Box>
  )
}

export default MostSellerScroll

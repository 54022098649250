import React from 'react'
import { Typography, Box, Button } from '@mui/material'
import VideoBack from './BackVideo/VideoBack'

import { useTranslation } from 'react-i18next'
import { useGetAllSlidersQuery } from '../../src/APIs/SectionApis'
import { heroVideoProps } from './HeroTypes'
import { useNavigate } from 'react-router-dom'

const Hero6 = (props: heroVideoProps) => {
  const navigate = useNavigate()
  const [_, { language: lang }] = useTranslation()
  const { data, isError, error, isLoading } = useGetAllSlidersQuery(undefined)
  const dataHero = data?.data.length - 1
  const colors = {
    primary: 'white',
    secondary: '#e3e3e3',
    backgroundPrimary: 'gray',
    backgroundSecondary: '#202429',
  }

  return (
    <Box minHeight={'100vh'}>
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isError && !isLoading && (
        <Box
          sx={{
            height: { xs: '85vh', md: '100vh' },
            position: 'relative',
          }}
        >
          <VideoBack video={props.backgroundVideo} />

          {/* layout */}
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.4)',
            }}
          />
          <Box
            width={{ xs: 0.7, sm: 0.5, md: 0.4 }}
            textAlign={'center'}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            {/* Title */}

            <Typography
              variant="h1"
              sx={{
                mb: 3,
                color: props.color?.titleColor
                  ? props.color.titleColor
                  : colors.primary,
                fontWeight: 'bold',
                fontSize: {
                  xs: '2rem',
                  sm: '3rem',
                  md: '4rem',
                  lg: '5rem',
                  xl: '6rem',
                },
              }}
            >
              {data?.data[dataHero][`title_${lang}`]}
            </Typography>

            {/* Description */}
            <Typography
              variant="h6"
              sx={{
                color: props.color?.subTitleColor
                  ? props.color?.subTitleColor
                  : colors.secondary,
                fontSize: {
                  xs: '1rem',
                  sm: '1.2rem',
                  md: '1.3rem',
                },
                wordBreak: 'break-word',
              }}
            >
              {data?.data[dataHero][`description_${lang}`]}
            </Typography>

            {/* Button */}

            <Button
              onClick={() => navigate('/aboutUs')}
              variant="contained"
              sx={{
                mt: 3,
                outline: 'none !important',
                border: 'none !important',
                color: props.Btn?.colorBtn
                  ? props.Btn?.colorBtn
                  : colors.primary,
                backgroundColor: props.Btn?.backgroundColorBtn
                  ? `${props.Btn?.backgroundColorBtn} !important`
                  : `${colors.backgroundSecondary} !important`,
                p: '12px 40px',
                borderRadius: props.Btn?.borderRaduisBtn ? '25px' : '5px',
                textTransform: 'capitalize'
              }}
            >
              {props.Btn?.titleBtn ? props.Btn?.titleBtn : 'lorem'}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Hero6

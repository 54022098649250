import React,{useState} from 'react'
import { Box, Typography, ButtonBase, TextField,InputAdornment,InputBase } from '@mui/material'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { verifyProps } from '../ForgetType'
import { useResetPasswordMutation} from '../../src/APIs/forgetPassApi'
import { useNavigate } from 'react-router-dom'
import HttpsIcon from '@mui/icons-material/Https'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
const ResetPassword = (props: verifyProps) => {
  const [resetPassword] = useResetPasswordMutation(undefined)
  const [passwordType, setPasswordType] = useState(true)
  const navigate = useNavigate()
  const [_, { language: lang }] = useTranslation()



  const formik = useFormik({
    initialValues: {
     password:'',
     confirmPassword:''
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .required(lang === 'en' ? 'Password is required' : 'كلمة المرور مطلوبة')
        .min(6, lang === 'en' ? 'Password must be at least 6 characters' : 'يجب أن تكون كلمة المرور على الأقل 6 أحرف'),
      confirmPassword: yup
        .string()
        .required(lang === 'en' ? 'Confirm Password is required' : 'يجب تأكيد كلمة المرور')
        .oneOf([yup.ref('password')], lang === 'en' ? 'Passwords must match' : 'يجب أن تتطابق كلمتي المرور'),
    }),
    onSubmit: (values) => {
      const data = {
        username: props.data?.email,
        newPassword: values.password,
      }
      resetPassword(data).unwrap().then((res) => {
        toast.success(res[`success_${lang}`])
        navigate('/sign-in')
      }
      ).catch((err) => {
        toast.error(err?.data[`error_${lang}`])
       
      })

      
      
    
    },
  })

  return (
    <Box
      sx={{
        minHeight: '100vh',
        direction: lang === 'en' ? 'ltr' : 'rtl',
       
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '45%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          width: { xs: '100%', sm: '50%', lg: '35%', xl: '25%' },
          p: 2,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
        
                    {/* Password */}
                    <Box>
                      <InputBase
                        name="password"
                        value={formik.values.password}
                        
                        placeholder={lang === 'en' ? 'Password' : 'كلمة المرور'}
                        type={passwordType ?'password' : 'text'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}

                        
                          startAdornment={

                            <InputAdornment position="start">
                              <HttpsIcon
                               sx={{
                                color:props.style.borderInputColor
                                ?props.style.backgroundColorBtn
                                :`black`,
                                mx:2
                            }} 
                              />
                            </InputAdornment>
                          }
                          
                          endAdornment={

                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPasswordType(!passwordType)}
                                // onMouseDown={handleMouseDownPassword}

                                edge="end"
                              >
                                {passwordType ? (
                                  <VisibilityOff sx={{
                                    color:props.style.borderInputColor
                                    ?props.style.backgroundColorBtn
                                    :`black`,
                                    mx:lang==="en"?2:1
                                }} />
                                ) : (
                                  <Visibility sx={{
                                    color:props.style.borderInputColor
                                    ?props.style.backgroundColorBtn
                                    :`black`
                                }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                       
                        sx={{
                          p: '15px 0px',
                          width: '100%',
                          borderRadius: props.style.borderRadius ? '50px' : '0',
                          mt: '20px',
                          border:`2px solid ${
                            props.style.borderInputColor
                                    ? props.style.borderInputColor
                                    : `2px solid black`
                                }`,
                          '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                            opacity: 0,
                          },
                          '.css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root':
                            {
                              px: lang === 'en' ? undefined : '0',
                            },
                          ' .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input':
                            {
                              p: lang === 'en' ? undefined : '16.5px 14px',
                            },
                          '& input::placeholder': {
                            color: props.style.placeholderColor
                              ? props.style.placeholderColor
                              : 'gray',
                          },
                        }}
                      />

                      {formik.errors.password && formik.touched.password && (
                        <Typography
                          fontWeight={'bold'}
                          fontSize={13}
                          color="red"
                        >
                          {formik.errors.password}
                        </Typography>
                      )}
                    </Box>

                    {/* confirm pass */}
                    <Box>
                      <InputBase
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        
                        placeholder={lang === 'en' ? 'Confirm Password' : 'تأكيد كلمة المرور'}
                        type={passwordType ? 'password' : 'text'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    
                          startAdornment={

                            <InputAdornment position="start">
                              <HttpsIcon sx={{
                        color:props.style.borderInputColor
                        ?props.style.backgroundColorBtn
                        :`black`,
                        mx:2
                    }} />
                            </InputAdornment>
                          }
                       
                          endAdornment={

                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPasswordType(!passwordType)}
                                // onMouseDown={handleMouseDownPassword}

                                edge="end"
                              >
                                {passwordType ? (
                                  <VisibilityOff sx={{
                                    color:props.style.borderInputColor
                                    ?props.style.backgroundColorBtn
                                    :`black`,
                                    mx:lang==="en"?2:1
                                }}  />
                                ) : (
                                  <Visibility sx={{
                                    color:props.style.borderInputColor
                                    ?props.style.backgroundColorBtn
                                    :`black`
                                }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                     
                        sx={{
                          p: '15px 0px',
                          width: '100%',
                          borderRadius: props.style.borderRadius ? '50px' : '0',
                          mt: '20px',
                          border:`2px solid ${
                            props.style.borderInputColor
                                    ? props.style.borderInputColor
                                    : `2px solid black`
                                }`,
                          '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                            opacity: 0,
                          },
                          '.css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root':
                            {
                              px: lang === 'en' ? undefined : '0',
                            },
                          ' .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input':
                            {
                              p: lang === 'en' ? undefined : '16.5px 14px',
                            },
                          '& input::placeholder': {
                            color: props.style.placeholderColor
                              ? props.style.placeholderColor
                              : 'gray',
                          },
                        }}
                      />

                      {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                        <Typography
                          fontWeight={'bold'}
                          fontSize={13}
                          color="red"
                        >
                          {formik.errors.confirmPassword}
                        </Typography>
                      )}
                    </Box>

          <Box mt={2}>
            <ButtonBase
              type="submit"
              sx={{
                color:props.style.colorBtn ? props.style.colorBtn : 'white',
                bgcolor: props.style.backgroundColorBtn
                  ? props.style.backgroundColorBtn
                  : 'gray',
                mt: '20px',
                fontSize: {
                  xl: '35px',
                  lg: '20px',
                  xs: '18px',
                },
                p: {xs:"10px 30px",sm:'6px 30px'},
                width: '100%',
              }}
            >
              {lang === 'en' ? 'Login' : 'تسجيل الدخول'}
            </ButtonBase>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

export default ResetPassword
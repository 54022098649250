import { Grid } from '@mui/material'
import React from 'react'

function AllProductsGrid({ cards }: any) {
  return (
    <Grid
      container
      spacing={{
        xs: 2,
        md: 3,
        lg: 4,
        xl: 5,
      }}
      pt={5}
      width={'100%'}
    >
      {cards?.map((card: any, index: number) => (
        <Grid
          item
          xs={6}
          md={4}
          // lg={3}
          // xl={2}
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
            width: '100%',
            height: '100%',
          }}
        >
          {card}
        </Grid>
      ))}
    </Grid>
  )
}

export default AllProductsGrid

import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { heroBackImgProps } from './HeroTypes'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGetAllSlidersQuery } from '../../src/APIs/SectionApis'

// floating text with button
const Hero6 = (props: heroBackImgProps) => {
  const [_, { language: lang }] = useTranslation()
  const { data, isError ,error,isLoading} = useGetAllSlidersQuery(undefined)
  const dataHero = data?.data.length - 1 //last item in array
  const navigate = useNavigate()
  const colors = {
    primary: 'white',
    secondary: '#e3e3e3',
    backgroundPrimary: 'gray',
    backgroundSecondary: '#202429',
  }

  return (
    <Box minHeight={"100vh"}>
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isError && !isLoading && (

      <Box
        sx={{
          height: { xs: '85vh', md: '100vh' },
          bgcolor:props.backgroundColor?props.backgroundColor: "none",
        }}
      >
        <Box
          width={{ xs: 0.7, sm: 0.5, md: 0.4 }}
          textAlign={'center'}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          {/* Title */}

          <Typography
            variant="h1"
            sx={{
              mb: 3,
              color: props.color?.titleColor
                ? props.color?.titleColor
                : colors.primary,
              fontWeight: 'bold',
              fontSize: {
                xs: '1.8rem',
                sm: '2.3rem',
                md: '2.5rem',
                lg: '3.2rem',
                xl: '4rem',
              },
            }}
          >
            {data?.data[dataHero][`title_${lang}`]}
          </Typography>

          {/* Description */}
          <Typography
            variant="h6"
            sx={{
              color: props.color?.subTitleColor
              ? `${props.color.subTitleColor}`
              : `${colors.primary}`,
              fontSize: {
                xs: '1rem',
                sm: '1.2rem',
                md: '1.3rem',
              },
              wordBreak: 'break-word',
            }}
          
          >
            {data?.data[dataHero][`description_${lang}`]}
          </Typography>

          {/* Button */}

          <Button
            onClick={() => navigate('/aboutUs')}
            variant="contained"
            sx={{
              mt: 3,
              outline: 'none !important',
              border: 'none !important',
              color: props.Btn?.colorBtn ? props.Btn?.colorBtn : colors.primary,
              backgroundColor: props.Btn?.backgroundColorBtn
                ? `${props.Btn?.backgroundColorBtn}!important`
                : `${colors.backgroundPrimary} !important`,
              p: '12px 40px',
              borderRadius: props.Btn?.borderRaduisBtn ? '25px' : '5px',
              textTransform: 'capitalize'
            }}
          >
            {props.Btn?.titleBtn ? props.Btn?.titleBtn : 'lorem'}
          </Button>
        </Box>
      </Box>
      )}
    </Box>
  )
}

export default Hero6

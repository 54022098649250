import { useGetMostNewiestProductsQuery, useGetMostSellingProductsQuery } from "../../src/APIs/ProductApis"

export const getData=(title:string|undefined)=>{
  
  
    let Data=[]
    if (title == 'Most Seller') {
        const { data } = useGetMostSellingProductsQuery(undefined)
      
        
        Data=data?.data
      }else if (title == 'Newest'){
        const { data } = useGetMostNewiestProductsQuery(undefined)
        Data=data?.data
      }else{
        Data=[]
      }

      return Data?.slice(0,3);
} 
//

// sarri
// export const baseUrl = 'https://saritest.store:8080/api/v1'
// export const imageBaseUrl = 'https://saritest.store:8080/uploads'

// current base Url

export const baseUrl = 'https://bookstore.work:8080/api/v1'
export const imageBaseUrl = 'https://bookstore.work:8080/uploads'

import React from 'react'
import { Box, Typography, Grid, Container, Stack } from '@mui/material'
import { CardColors } from '../../Cards/CardTypes'
export type MostSellerCollectionProps = {
  title?: string
  color?: string
  backgroundArrowColor?: string
  BtnCollections?: {
    title?: string
    titleColor?: string
    backgroundColor?: string
    borderRadius?: boolean
  }
  ADS?: boolean
  card: {
    colors: CardColors
    wide?: boolean
    border?: boolean
    borderRadius?: boolean
    width?:
      | string
      | number
      | {
          xs?: string | number
          sm?: string | number
          md?: string | number
          lg?: string | number
        }
    height?:
      | string
      | number
      | {
          xs?: string | number
          sm?: string | number
          md?: string | number
          lg?: string | number
        }
    title?: boolean
    desc?: boolean
    button?: boolean
    favorite?: boolean
    hoverWithFav?: boolean
    hover?: boolean
  }
}
import { Card } from '../../Cards/Card'
import {
  useGetMostNewiestProductsQuery,
  useGetMostSellingProductsQuery,
} from '../../src/APIs/ProductApis'
import { useTranslation } from 'react-i18next'
import Banner from '../Banners/Banner'

const MostSellerCollection = (props: MostSellerCollectionProps) => {
  const {
    data: MostSeller,
    isLoading: loadingMost,
    isSuccess: MostSuccess,
    isError: MostError,
    error: MostErrMsg,
  } = useGetMostSellingProductsQuery(undefined)
  const {
    data: Newest,
    isSuccess,
    isLoading,
    error,
    isError,
  } = useGetMostNewiestProductsQuery(undefined)
  const getData =
    props.title === 'NewestCollections' ? Newest?.data : MostSeller?.data
  const mostSeller = getData?.slice(0, 6)
  const [_, { language: lang }] = useTranslation()
  const colors = {
    primary: 'white',
    secondary: 'black',
    backgroundPrimary: '#b0b4c5',
    backgroundSecondary: '#d2d9d5',
  }
  return (
    <Box py={4} mt={6}>
      {isError && MostError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isLoading &&
        !loadingMost &&
        isSuccess &&
        MostSuccess &&
        mostSeller?.length > 0 && (
          <Container sx={{
            
          }}>

          <Box
            sx={{
              // width: { xs: '100%', sm: '99%' },
              // mx: 'auto',
              direction: lang === 'en' ? 'ltr' : 'rtl',
            }}
          >
            {/* title */}
            <Box mb={5}>
              <Typography
                sx={{
                  fontSize: { xs: '1.8rem', sm: '2.5rem', lg: '3.5rem' },
                  fontWeight: 'bold',
                  color: props.color ? props.color : colors.secondary,
                  mx:{xs:5,sm:0},
                  textAlign: {xs:"initial",sm:'center'},
                }}
              >
                {props.title === 'NewestCollections'
                  ? lang === 'en'
                    ? 'Newest'
                    : 'الاحدث'
                  : lang === 'en'
                  ? 'Most Seller'
                  : 'الاكثر مبيعا'}
              </Typography>
            </Box>
            <Stack
              display={'flex'}
              justifyContent={'center'}
              direction={'row-reverse'}
              gap={{ xs: 0, sm: 3 }}
            >
              {props.ADS && (
                <Box
                  sx={{
                    height: '100%',
                    width: { xs: '0%', sm: 0.3 },
                  }}
                >
                  <Banner bannerVertical />
                </Box>
              )}

              <Box
                // height={{xs:"900px",sm:"800px"}}
                // width={{ xs: 1, lg: 0.8 }}
                sx={{ display: 'grid', p: { xs: 2, sm: 0 } }}
                gridTemplateColumns={{
                  xs: 'repeat(2, 1fr)',
                  sm: 'repeat(4,1fr)',
                }}
                gridTemplateRows={{
                  xs: 'repeat(4,260px)',
                  sm: 'repeat(2, 450px)',
                }}
                gap={2}
              >
                {mostSeller?.map((item: any, index: any) => (
                  <Box
                    gridColumn={{
                      xs: index === 0 || index === 3 ? 'span 2' : 'span 1', // For xs screen, index 2 => span 1, else span 2
                      sm: index === 2 || index === 3 ? 'span 2' : 'span 1', // For sm screen, index 2 => span 2, else span 1
                    }}
                    key={index}
                  >
                    <Card
                      data={item}
                      colors={props.card.colors}
                      border={props.card.border}
                      borderRadius={props.card.borderRadius}
                      wide={props.card.wide}
                      width={props.card.width}
                      height={props.card.height}
                      title={props.card.title}
                      desc={props.card.desc}
                      button={props.card.button}
                      favorite={props.card.favorite}
                      hoverWithFav={props.card.hoverWithFav}
                      hover={props.card.hover}
                    />
                  </Box>
                ))}
              </Box>
            </Stack>
          </Box>
          </Container>
        )}
    </Box>
  )
}

export default MostSellerCollection

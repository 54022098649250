import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { FooterProps, KeyOfSocial } from '../FooterTypes'
import { useTranslation } from 'react-i18next'
import { ICategory, links } from '../Footer'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useGetAllCategoriesQuery } from '../../src/APIs/categoriesApi'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import PinterestIcon from '@mui/icons-material/Pinterest'
import Map from './Map'

interface TomoorProps extends Omit<FooterProps, 'type' | 'information'> {}

function Logo({ url }: { url: string }) {
  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          lg: '300px',
        },

        display: 'grid',
        placeItems: 'center',
        py:3
      }}
    >
      <Box
        component={'img'}
        src={url}
        alt="logo"
        sx={{ width: 150, height: 150 }}
      />
    </Box>
  )
}

function CategorySection({
  categories,
  color,
}: {
  categories: ICategory[]
  color: TomoorProps['color']
}) {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <Stack
      direction={{
        xs: 'row',
        md: 'column',
      }}
      gap={2}
      sx={{
        justifyContent: { xs: 'center', md: 'start' },
        flexWrap: 'wrap',
      }}
    >
      {categories.map((category) => (
        <Box
          key={category._id}
          sx={{
            color: color.primary,
            textDecoration: 'none !important',
          }}
          component={Link}
          to={`/departments/${category._id}`}
        >
          {language === 'en' ? category.name_en : category.name_ar}
        </Box>
      ))}
    </Stack>
  )
}

function ContentSection({
  color,
  map,
  variant,
}: {
  color: TomoorProps['color']
  map: TomoorProps['map']
  variant: TomoorProps['variant']
}) {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <Stack
      direction={'column'}
      sx={{
        justifyContent: {
          xs: 'center',
          md: 'start',
        },
      }}
      gap={2}
    >
      <Stack
        direction={{ xs: 'row', md: 'column' }}
        gap={3}
        justifyContent={'center'}
      >
        {links.map((link) => (
          <Box
            key={link.path}
            sx={{
              color: color.primary,
              textDecoration: 'none !important',
            }}
            component={Link}
            to={link.path}
          >
            {language === 'en' ? link.en : link.ar}
          </Box>
        ))}
      </Stack>
      {(variant === 'map' || variant === 'form-map') && map && (
        <Box sx={{ pt: 5 }}>
          <Map url={map.url} />
        </Box>
      )}
    </Stack>
  )
}

function IconLink({
  social,
  icon,
  link,
}: {
  social: TomoorProps['social']
  icon: any
  link: string
}) {
  return (
    <Box
      component={Link}
      target="_blank"
      to={link}
      sx={{
        textDecoration: 'none !important',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Box
        component={icon}
        width="fit-content"
        sx={{ color: social?.color, height: 66, width: 66 }}
      />
    </Box>
  )
}

function SocialSection({ social }: { social: TomoorProps['social'] }) {
  if (!social) return null

  return (
    <Stack direction="column" gap={2}>
      {social?.phone && (
        <Box
          component={Link}
          target="_blank"
          sx={{
            textDecoration: 'none !important',
            width: 'fit-content',
          }}
          to={`tel:${social.phone}`}
        >
          <Typography sx={{ color: social?.color, fontSize: 20 }}>
            {social.phone}
          </Typography>
        </Box>
      )}
      <Stack
        direction={{ xs: 'row', md: 'column' }}
        gap={2}
        justifyContent={{
          xs: 'center',
          md: 'start',
        }}
      >
        {(Object.keys(social) as KeyOfSocial[]).map((key) => {
          console.log({ key, social })

          const Icon = {
            facebook: FacebookIcon,
            instagram: InstagramIcon,
            linkedin: LinkedInIcon,
            twitter: TwitterIcon,
            pinterest: PinterestIcon,
          }[key]
          if (!Icon) return null
          return (
            <IconLink
              social={social}
              link={social[key] || ''}
              icon={Icon}
              key={key}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}

function StayInformed({
  color,
  bgColor,
  variant,
}: {
  color: TomoorProps['color']
  bgColor: TomoorProps['bgColor']
  variant: TomoorProps['variant']
}) {
  const {
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()
  return (
    <Stack
      direction={'column'}
      justifyItems={'center'}
      gap={2}
      alignItems={{
        xs: 'center',
        md: 'start',
      }}
    >
      <Typography
        sx={{
          color: color.primary,
          textTransform: 'capitalize',
          fontWeight: 'bold',
          fontSize: {
            xs: 20,
            md: 40,
          },
        }}
      >
        {language === 'en' ? 'Stay informed' : 'ابقى على اطلاع'}
      </Typography>
      {(variant === 'form-map' || variant === 'form') && (
        <Stack
          sx={{
            width: '100%',
            gap: 2,
          }}
        >
          <Box
            component={'input'}
            type="text"
            placeholder={language === 'en' ? 'Email' : 'البريد الالكتروني'}
            sx={{
              color: color.primary,
              '::placeholder': { color: color.secondary },
              fontSize: {
                xs: 10,
                md: 20,
              },
              backgroundColor: 'transparent',
              padding: '5px 10px',
              border: 'none',
              outline: 'none !important',
              borderBottom: `1px solid ${color.primary}`,
            }}
          />
          <Box
            component={'textarea'}
            placeholder={language === 'en' ? 'Write Here' : 'اكتب هنا'}
            rows={5}
            sx={{
              color: color.primary,
              '::placeholder': { color: color.secondary },
              fontSize: {
                xs: 10,
                md: 20,
              },
              backgroundColor: 'transparent',
              padding: '5px 10px',
              outline: 'none !important',
              border: `1px solid ${color.primary}`,
            }}
          />
        </Stack>
      )}

      <Button
        variant="contained"
        onClick={() => navigate('/register')}
        sx={{
          color: color.primary,
          bgcolor: bgColor.secondary,
          padding: '10px 20px',
          '&:hover': {
            bgcolor: bgColor.secondary,
          },
        }}
      >
        {language === 'en' ? 'Sign Up' : 'اشترك'}
      </Button>
    </Stack>
  )
}

function Tomoor({ bgColor, color, variant, social, map, logo }: TomoorProps) {
  const {
    i18n: { language },
  } = useTranslation()
  const { data, isLoading, isSuccess, isError } = useGetAllCategoriesQuery(null)
  const { pathname } = useLocation()
  const categories = (data?.data || []) as ICategory[]

  const dir = language === 'ar' ? 'rtl' : 'ltr'
  return (
    <Stack
      direction={{
        xs: 'column',
        lg: 'row',
      }}
      sx={{
        minHeight: 300,
        fontWeight: 'bold',
        display: pathname==="/thankYou"||pathname==="/sign-in"||pathname==="/register"||pathname==="/forgetPassword"?"none":"flex",
      }}
      dir={dir}
    >
      <Logo url={logo} />
      <Box bgcolor={bgColor.primary} width={'100%'}>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          gap={{
            xs: 2,
            lg: 10,
          }}
          pt={{
            xs: 2,
            md: 10,
          }}
          px={2}
          sx={{
            flexGrow: 1,
            justifyContent: 'space-evenly',
            height: '100%',
            pb: 10,
            maxWidth: 1200,
          }}
        >
          <ContentSection color={color} map={map} variant={variant} />
          {isLoading && <div>loading...</div>}
          {isSuccess && (
            <CategorySection color={color} categories={categories} />
          )}
          <SocialSection social={social} />
          <StayInformed color={color} bgColor={bgColor} variant={variant} />
        </Stack>
      </Box>
    </Stack>
  )
}

export default Tomoor

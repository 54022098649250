import { render } from 'react-dom'
import { App } from './App'
import './index.css'
import { store } from './store/Store'
import { Provider } from 'react-redux'
import './i18n'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const root = document.getElementById('root')

render(
  <Provider store={store}>
    <ToastContainer />
    <App />
  </Provider>,
  root
)

import React from 'react'
import { Box } from '@mui/material'
import VideoBack from './BackVideo/VideoBack';
import { heroVideoProps } from './HeroTypes';

const Hero2 = (props:heroVideoProps) => {
  return (
    <Box
    sx={{
      position: 'relative',
      width: '100%',
      minHeight: { xs: '80vh', md: '100vh' },
    }}
  >
    {/* Video */}
    <VideoBack video={props.backgroundVideo}/>
  </Box>
  )
}

export default Hero2
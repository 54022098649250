import React from 'react'
import { Box, Typography, Container, Button, Stack } from '@mui/material'
import data from '../data.json'
import { Card } from '../../Cards/Card'
import { MostSellerCollectionProps } from '../Most Seller/MostSellerCollection'
import { useTranslation } from 'react-i18next'
import {
  useGetMostNewiestProductsQuery,
  useGetMostSellingProductsQuery,
} from '../../src/APIs/ProductApis'
import { useNavigate } from 'react-router-dom'
import Banner from '../Banners/Banner'
const TrendingCollection = (props: MostSellerCollectionProps) => {
  const [_, { language: lang }] = useTranslation()
  const navigate = useNavigate()

  const {
    data: MostSeller,
    isSuccess: MostSuccess,
    isError: MostError,
    error: MostErrMsg,
    isLoading: loadingMost,
  } = useGetMostSellingProductsQuery(undefined)
  const {
    data: Newest,
    isSuccess,
    isLoading,
    error,
    isError,
  } = useGetMostNewiestProductsQuery(undefined)
  const getData =
    props.title === 'NewestCollections' ? Newest?.data : MostSeller?.data
  const trending = getData?.slice(0, 5)
  const colors = {
    primary: 'white',
    secondary: 'black',
    backgroundPrimary: '#b0b4c5',
    backgroundSecondary: '#d2d9d5',
  }

  return (
    <Box py={3} my={5}>
      {isError && MostError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isLoading && !loadingMost && isSuccess && MostSuccess && (
        <Stack>
          <Box
            sx={{
              textAlign: 'center',
              mb:2
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '1.8rem', sm: '2.5rem', lg: '4rem' },
                fontWeight: 'bold',
                color: props.color ? props.color : colors.secondary,
              }}
            >
              {props.title === 'NewestCollections'
                ? lang === 'en'
                  ? 'Newest'
                  : 'الاحدث'
                : lang === 'en'
                ? 'Most Seller'
                : 'الاكثر مبيعا'}
            </Typography>
          </Box>

          <Stack
           
           display={'flex'}
            justifyContent={'center'}
            direction={lang==="en"?'row-reverse':"row"}
            gap={{ xs: 0, sm: 3 }}
            p={1}
          
            // gap={{ xs: 0, sm: -5 }}
          >
             {props.ADS && (
              <Box
                sx={{
                  height: '100%',
                  width: { xs: '0%', sm: 0.2 },
                  

               
                }}
              >
                <Banner bannerVertical />
              </Box>
            )}
            <Container>

            <Box
          
             
              position={'relative'}
             justifyContent={{xs:'center',sm:'flex-start'}}
              // mx={'auto'}
       
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: 'repeat(2, 150px)',
                  sm: 'repeat(4, 1fr)',
                },
                gap: 2,
                gridTemplateRows: 'repeat(2, 360px)',
              }}
            >
              {trending?.map((item: any, index: number) => (
                <Box
                  gridColumn={
                    index === 0 ? { xs: 'span 1', sm: 'span 2' } : 'span 1'
                  } // Use template literals here
                  gridRow={
                    index === 0 ? { xs: 'span 1', sm: 'span 2' } : 'span 1'
                  } // Use template literals here
                >
                  <Card
                    data={item}
                    colors={props.card.colors}
                    border={props.card.border}
                    borderRadius={props.card.borderRadius}
                    wide={props.card.wide}
                    width={props.card.width}
                    height={props.card.height}
                    title={props.card.title}
                    desc={props.card.desc}
                    button={props.card.button}
                    favorite={props.card.favorite}
                    hoverWithFav={props.card.hoverWithFav}
                    hover={props.card.hover}
                  />
                </Box>
              ))}
            </Box>
            </Container>
          </Stack>

          <Box textAlign={'center'} my={2}>
            <Button
              variant="outlined"
              sx={{
                mt: 2,
                color: props.BtnCollections?.titleColor
                  ? props.BtnCollections?.titleColor
                  : colors.secondary,
                padding: { xs: '0.4rem 3rem', md: '0.7rem 5rem' },
                border: `1px solid ${
                  props.BtnCollections?.titleColor
                    ? props.BtnCollections?.titleColor
                    : colors.secondary
                } !important`,
                backgroundColor: props.BtnCollections?.backgroundColor
                  ? `${props.BtnCollections?.backgroundColor}!important`
                  : 'transparent !important',
                borderRadius: props.BtnCollections?.borderRadius
                  ? '50px'
                  : '0px',
              }}
              onClick={() => {
                navigate('/products')
              }}
            >
              {props.BtnCollections?.title
                ? props.BtnCollections?.title
                : 'lorem'}
            </Button>
          </Box>
        </Stack>
      )}
    </Box>
  )
}

export default TrendingCollection

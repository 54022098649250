import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { ReactElement } from 'react'
import { NavTypes } from './NavTypes'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTranslation } from 'react-i18next'
import Logo from './Logo'
import { NavData } from './navdata'
// import { LogInButton } from './publicComponents/LogInButton'
import { NavLink, useLocation } from 'react-router-dom'
import NavMenu from './NavMenu'
import logo from "../src/assets/logo.png";
import { NavAccordion } from './Navbar'

interface NavDrawerProp extends NavTypes {
  mobileOpen: boolean
  handleDrawerToggle: () => void
  muiIcons: ReactElement[]
}
export const NavDrawer = (props: NavDrawerProp) => {
  const [, { language: lng }] = useTranslation()
  const navLinks = NavData()
  const { pathname } = useLocation()
  // const navigate = useNavigate()
  return (
    <nav>
      <Drawer
        variant="temporary"
        anchor={lng === 'en' ? 'left' : 'right'}
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 240,
            pt: '60px',
            pb: '10px',
            bgcolor: props.drawerBg ? props.colors.backgroundColor : 'white',
          },
        }}
      >
        <Stack
          sx={{
            textAlign: 'center',
            height: '100%',
            direction: lng === 'en' ? 'ltr' : 'rtl',
          }}
          direction={'column'}
          alignItems={'center'}
        >
          <Box
            sx={{
              borderBottom: `1px solid ${props.colors.borderColor}`,
              flex: 1,
              mb: 2,
              width: '100%',
            }}
          >
            <ArrowBackIcon
              sx={{
                display: 'block',
                cursor: 'pointer',
                textAlign: 'left',
                ml: lng === 'en' ? 2 : 0,
                mr: lng === 'ar' ? 2 : 0,
              }}
              onClick={props.handleDrawerToggle}
            />
            <Box sx={{ width: '100%', mx: 'auto' }}>
              <Logo
                imagePath={logo}
                extraObjectFit={undefined}
              />
            </Box>

            <NavAccordion {...props} />
          </Box>
        </Stack>
      </Drawer>
    </nav>
  )
}

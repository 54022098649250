import React from 'react'
import { Box, Typography } from '@mui/material'
import data from '../data.json'
import { useTranslation } from 'react-i18next'
import { useGetAllSlidersQuery } from '../../src/APIs/SectionApis'
import { heroBackImgProps } from './HeroTypes'

// floating text

const Hero3 = (props: heroBackImgProps) => {
  const [_, { language: lang }] = useTranslation()
  const { data, isError ,error,isLoading} = useGetAllSlidersQuery(undefined)
  const dataHero = data?.data.length - 1 //last item in array
  const colors = {
    primary: 'white',
    secondary: '#e3e3e3',
    backgroundPrimary: '#292a2d',
    backgroundSecondary: '#d2d9d5',
  }

  return (
    <Box minHeight={"100vh"} sx={{
      direction:lang==="en"?'ltr':'rtl'
    }}>
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isError && !isLoading && (

      <Box
        sx={{
          height: { xs: '85vh', md: '100vh' },
          bgcolor: props.backgroundColor?props.backgroundColor:"none",
        }}
      >
        <Box
          width={{ xs: 0.7, sm: 0.5, md: 0.4 }}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
           
          }}
        >
          {/* Title */}
          <Typography
            variant="h1"
            sx={{
              mb: 2,
              color: props.color?.titleColor
                ? props.color?.titleColor
                : colors.primary,
              fontWeight: 'bold',
              fontSize: {
                xs: '1.8rem',
                sm: '2.3rem',
                md: '2.5rem',
                lg: '3.2rem',
                xl: '4rem',
              },
            }}
          >
            {data?.data[dataHero][`title_${lang}`]}
          </Typography>

          {/* Description */}
          <Typography
            variant="h6"
            sx={{
              color: props.color?.subTitleColor
                ? props.color?.subTitleColor
                : colors.secondary,
              fontSize: {
                xs: '1rem',
                sm: '1.2rem',
                md: '1.3rem',
              },
              wordBreak: 'break-word',
            }}
          >
            {data?.data[dataHero][`description_${lang}`]}
          </Typography>
        </Box>
      </Box>
      )}
    </Box>
  )
}

export default Hero3

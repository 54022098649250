import React from 'react'
import { Box, Grid, Typography, Stack, Container, Button } from '@mui/material'

import { Card } from '../../Cards/Card'
import {
  useGetMostNewiestProductsQuery,
  useGetMostSellingProductsQuery,
} from '../../src/APIs/ProductApis'
import { useTranslation } from 'react-i18next'
import { MostSellerCollectionProps } from '../Most Seller/MostSellerCollection'
import { useNavigate } from 'react-router-dom'

const NewestCollection = (props: MostSellerCollectionProps) => {
  const navigate = useNavigate()

  // slice data to get 4 most newest to show
  const {
    data: MostSeller,
    isLoading: loadingMost,
    isSuccess: MostSuccess,
    isError: MostError,
    error: MostErrMsg,
  } = useGetMostSellingProductsQuery(undefined)
  const {
    data: Newest,
    isSuccess,
    isLoading,
    error,
    isError,
  } = useGetMostNewiestProductsQuery(undefined)
  const getData =
    props.title === 'NewestCollections' ? Newest?.data : MostSeller?.data
  const mostNewest = getData?.slice(0, 4)
  const [_, { language: lang }] = useTranslation()
  console.log(getData)

  const colors = {
    primary: 'white',
    secondary: 'black',
    backgroundPrimary: '#b0b4c5',
    backgroundSecondary: '#d2d9d5',
  }

  return (
    <Box my={10} py={5}>
      {isLoading && loadingMost && <span className="loader"></span>}
      {isError && MostError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isLoading && !loadingMost && isSuccess && MostSuccess && (
        <>
        <Container sx={{
         
        }}>

          <Box
            // width={{ xs: 0.9, xl: 0.7 }}
            mt={2}
            // position={'relative'}
            // mx={'auto'}
           
            
            sx={{
              display: 'grid',
              direction: lang==="en"? "ltr":"rtl",
              gap: 3,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
              },

              gridTemplateRows: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 300px)',
                lg: 'repeat(2, 350px)',
              },
            }}
          >
            <Box display={{xs:"block",sm:"none"}}>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: { xs: '25px', sm: '30px', lg: '40px' },
                  mb: 1,
                  color: props.color ? props.color : colors.secondary,
                }}
              >
                {props.title === 'NewestCollections'
                  ? lang === 'en'
                    ? 'Newest'
                    : 'الاحدث'
                  : lang === 'en'
                  ? 'Most Seller'
                  : 'الاكثر مبيعا'}
              </Typography>
            </Box>
            {mostNewest?.map((item: any, index: any) => (
              <Box
                key={index}
                gridRow={
                  index === 1
                    ? { xs: 'span 1', sm: 'span 2' }
                    : index === 0
                    ? { xs: 'span 1', sm: 'span 2' }
                    : 'span 1'
                }
                sx={{
                  height: index === 0 ? { xs: '100%', sm: '88%' } : '100%',
                }}
              >
                {index === 0 && (
                  <Box display={{xs:"none",sm:"block"}}>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: { xs: '25px', sm: '30px', lg: '40px' },
                        mb: 3,
                        color: props.color ? props.color : colors.secondary,
                      }}
                    >
                      {props.title === 'NewestCollections'
                        ? lang === 'en'
                          ? 'Newest'
                          : 'الاحدث'
                        : lang === 'en'
                        ? 'Most Seller'
                        : 'الاكثر مبيعا'}
                    </Typography>
                  </Box>
                )}

                <Card
                  data={item}
                  colors={props.card.colors}
                  border={props.card.border}
                  borderRadius={props.card.borderRadius}
                  wide={props.card.wide}
                  width={props.card.width}
                  height={props.card.height}
                  title={props.card.title}
                  desc={props.card.desc}
                  button={props.card.button}
                  favorite={props.card.favorite}
                  hoverWithFav={props.card.hoverWithFav}
                  hover={props.card.hover}
                />
              </Box>
            ))}
          </Box>
        </Container>

          <Box textAlign={'center'} my={2} pb={3}>
            <Button
              variant="outlined"
              sx={{
                mt: 2,
                color: props.BtnCollections?.titleColor
                  ? props.BtnCollections?.titleColor
                  : colors.secondary,
                padding: { xs: '0.4rem 3rem', md: '0.7rem 5rem' },
                border: `1px solid ${
                  props.BtnCollections?.titleColor
                    ? props.BtnCollections?.titleColor
                    : colors.secondary
                } !important`,
                backgroundColor: props.BtnCollections?.backgroundColor
                  ? `${props.BtnCollections?.backgroundColor}!important`
                  : 'transparent !important',
                borderRadius: props.BtnCollections?.borderRadius
                  ? '50px'
                  : '0px',
              }}
              onClick={() => {
                navigate('/products')
              }}
            >
              {props.BtnCollections?.title
                ? props.BtnCollections?.title
                : 'lorem'}
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default NewestCollection

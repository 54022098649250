import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FooterProps, KeyOfSocial } from '../FooterTypes'
import { useTranslation } from 'react-i18next'
import { ICategory, links } from '../Footer'
import { Link, useLocation } from 'react-router-dom'
import { useGetAllCategoriesQuery } from '../../src/APIs/categoriesApi'
import Map from './Map'
import JarirIcons from '../icons/JarirIcons'

interface JarirProps extends Omit<FooterProps, 'type' | 'social'> {}

function Logo() {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <JarirIcons />
    </Box>
  )
}

function CategorySection({
  categories,
  color,
}: {
  categories: ICategory[]
  color: JarirProps['color']
}) {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <>
      <Stack width={'100%'} direction={'column'}>
        <div>
          <Typography
            sx={{
              color: color.primary,
              fontSize: 20,
              textAlign: { xs: 'center', md: 'start' },
            }}
          >
            {language === 'en' ? 'Categories' : 'الاقسام'}
          </Typography>
          <Divider
            orientation="horizontal"
            style={{ backgroundColor: color.primary }}
          />
        </div>
        <Stack
          direction={{
            xs: 'row',
            md: 'column',
          }}
          gap={2}
          width={'100%'}
          sx={{
            justifyContent: { xs: 'center', md: 'start' },
            flexWrap: 'wrap',
          }}
        >
          {categories.map((category) => (
            <Box
              key={category._id}
              sx={{
                color: color.primary,
                textDecoration: 'none !important',
              }}
              component={Link}
              to={`/departments/${category._id}`}
            >
              {language === 'en' ? category.name_en : category.name_ar}
            </Box>
          ))}
        </Stack>
      </Stack>
    </>
  )
}

function ContentSection({
  color,
  map,
  variant,
}: {
  color: JarirProps['color']
  map: JarirProps['map']
  variant: JarirProps['variant']
}) {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <Stack
      direction={'column'}
      sx={{
        justifyContent: { xs: 'center', md: 'start' },
      }}
      width={'100%'}
      height="100%"
      gap={2}
    >
      <div>
        <Typography
          sx={{
            color: color.primary,
            textAlign: { xs: 'center', md: 'start' },
            fontSize: 20,
          }}
        >
          {language === 'en' ? 'Quick Links' : 'روابط سريعة'}
        </Typography>
        <Divider
          orientation="horizontal"
          style={{ backgroundColor: color.primary }}
        />
      </div>
      <Stack
        direction={{ xs: 'row', md: 'column' }}
        gap={3}
        justifyContent={'center'}
      >
        {links.map((link) => (
          <Box
            key={link.path}
            sx={{
              color: color.primary,
              textDecoration: 'none !important',
            }}
            component={Link}
            to={link.path}
          >
            {language === 'en' ? link.en : link.ar}
          </Box>
        ))}
      </Stack>
      {(variant === 'map' || variant === 'form-map') && map && (
        <Box sx={{ pt: 5 }}>
          <Map url={map.url} />
        </Box>
      )}
    </Stack>
  )
}

function SocialSection({
  information,
  color,
  variant,
}: {
  information: JarirProps['information']
  color: JarirProps['color']
  variant: JarirProps['variant']
}) {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <Stack
      direction={'column'}
      gap={2}
      sx={{
        justifyContent: {
          xs: 'center',
          md: 'start',
        },
        alignItems: { xs: 'center', md: 'start' },
        textAlign: { xs: 'center', md: 'start' },
      }}
    >
      <Box width="100%">
        <Typography
          sx={{
            color: color.primary,
            textAlign: { xs: 'center', md: 'start' },
            fontSize: 20,
          }}
        >
          {language === 'en' ? 'Contact Us' : 'تواصل معنا'}
        </Typography>
        <Divider
          orientation="horizontal"
          style={{ backgroundColor: color.primary }}
        />
      </Box>
      <Box
        component={'a'}
        href={`tel:${information?.phone}`}
        target="_blank"
        sx={{
          color: color.primary,
          textDecoration: 'none !important',
        }}
      >
        {information?.phone}
      </Box>
      <Box
        component={'a'}
        href={`mailto:${information?.email}`}
        target="_blank"
        sx={{
          color: color.primary,
          textDecoration: 'none !important',
        }}
      >
        {information?.email}
      </Box>
      <Box
        sx={{
          color: color.primary,
          textDecoration: 'none !important',
        }}
      >
        {information?.address}
      </Box>
      {variant === 'form-map' && (
        <Stack
          sx={{
            width: '100%',
            gap: 2,
            pt: 2,
          }}
        >
          <Box
            component={'input'}
            type="text"
            placeholder={language === 'en' ? 'Email' : 'البريد الالكتروني'}
            sx={{
              color: color.primary,
              '::placeholder': { color: color.secondary },
              fontSize: {
                xs: 10,
                md: 20,
              },
              width: { xs: '100%', md: '50%' },
              backgroundColor: 'transparent',
              padding: '5px 10px',
              border: 'none',
              outline: 'none !important',
              borderBottom: `1px solid ${color.primary}`,
            }}
          />
          <Box
            component={'textarea'}
            placeholder={language === 'en' ? 'Write Here' : 'اكتب هنا'}
            rows={5}
            sx={{
              color: color.primary,
              '::placeholder': { color: color.secondary },
              fontSize: {
                xs: 10,
                md: 20,
              },
              backgroundColor: 'transparent',
              padding: '5px 10px',
              outline: 'none !important',
              border: `1px solid ${color.primary}`,
            }}
          />
        </Stack>
      )}
    </Stack>
  )
}

function Jarir({
  bgColor,
  color,
  variant,
  logo,
  map,
  information,
  footerText,
}: JarirProps) {
  const {
    i18n: { language },
  } = useTranslation()
  const { data, isLoading, isSuccess, isError } = useGetAllCategoriesQuery(null)
  const { pathname } = useLocation()
  const categories = (data?.data || []) as ICategory[]

  const dir = language === 'ar' ? 'rtl' : 'ltr'
  return (
    <Stack
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        fontWeight: 'bold',
        display: pathname==="/thankYou"||pathname==="/sign-in"||pathname==="/register"||pathname==="/forgetPassword"?"none":"flex",
      }}
      dir={dir}
    >
      <Logo />
      <Box bgcolor={bgColor.primary} width={'100%'}>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          justifyContent={'center'}
          gap={2}
          pt={3}
          px={2}
          sx={{
            height: '100%',
          }}
        >
          <Stack
            direction={'column'}
            sx={{
              width: {
                xs: '100%',
                md: '40%',
              },
              height: '100%',
            }}
          >
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              gap={2}
              sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'space-between',
                alignItems: { xs: 'center', md: 'flex-start' },
                alignContent: 'flex-start',
              }}
            >
              <ContentSection color={color} map={map} variant={variant} />
              <Divider
                orientation="vertical"
                style={{
                  backgroundColor: color.primary,
                }}
                flexItem
              />
              {isLoading && <div>loading...</div>}
              {isSuccess && (
                <CategorySection color={color} categories={categories} />
              )}
            </Stack>
            {variant === 'form' && (
              <Stack
                sx={{
                  width: '100%',
                  gap: 2,
                  pt: 2,
                }}
              >
                <Box
                  component={'input'}
                  type="text"
                  placeholder={
                    language === 'en' ? 'Email' : 'البريد الالكتروني'
                  }
                  sx={{
                    color: color.primary,
                    '::placeholder': { color: color.secondary },
                    fontSize: {
                      xs: 10,
                      md: 20,
                    },
                    backgroundColor: 'transparent',
                    padding: '5px 10px',
                    border: 'none',
                    outline: 'none !important',
                    borderBottom: `1px solid ${color.primary}`,
                    width: {
                      xs: '100%',
                      md: '50%',
                    },
                  }}
                />
                <Box
                  component={'textarea'}
                  placeholder={language === 'en' ? 'Write Here' : 'اكتب هنا'}
                  rows={5}
                  sx={{
                    color: color.primary,
                    '::placeholder': { color: color.secondary },
                    fontSize: {
                      xs: 10,
                      md: 20,
                    },
                    width: { xs: '100%', md: '90%' },
                    backgroundColor: 'transparent',
                    padding: '5px 10px',
                    outline: 'none !important',
                    border: `1px solid ${color.primary}`,
                  }}
                />
              </Stack>
            )}
          </Stack>
          <Divider
            orientation="vertical"
            style={{
              backgroundColor: color.primary,
            }}
            flexItem
          />
          <SocialSection
            variant={variant}
            color={color}
            information={information}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          backgroundColor: bgColor.secondary,
          width: '100%',
          minHeight: 60,
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Typography
          sx={{
            color: color.primary,
            fontSize: {
              xs: 10,
              md: 15,
              lg: 20,
            },
          }}
        >
          {footerText}
        </Typography>
      </Box>
    </Stack>
  )
}

export default Jarir

import { Box } from '@mui/material'
import { useGetAboutUsDataQuery } from '../src/APIs/aboutUsApi'
// eslint-disable-next-line import/no-unresolved
import { Types } from './AboutTypes'
import { AboutUs2 } from './AboutUs2'
import { AboutUs1 } from './AboutUs1'
import { AboutUs3 } from './AboutUs3'
import { AboutUs4 } from './AboutUs4'
export const About = (type: Types) => {
  const { data: aboutSectionData, isLoading, isSuccess } = useGetAboutUsDataQuery(undefined)
  return (
    <Box sx={{ mt: 8, pt: 4 }}>
      {type.category === 2 && (
        <AboutUs2
          isLoading={isLoading}
          data={aboutSectionData?.data[0]}
          imgs={type.images}
          colors={type.colors}
          servicesSection={type.servicesSection || false}
        />
      )}
      {type.category === 1 && (
        <AboutUs1
          isLoading={isLoading}
          data={aboutSectionData?.data[0]}
          imgs={type.images}
          colors={type.colors}
        />
      )}
      {type.category === 3 && (
        <AboutUs3
          isLoading={isLoading}
          data={aboutSectionData?.data[0]}
          imgs={type.images}
          colors={type.colors}
        />
      )}
      {type.category === 4 && (
        <AboutUs4
          isLoading={isLoading}
          data={aboutSectionData?.data[0]}
          imgs={type.images}
          colors={type.colors}
          servicesSection={type.servicesSection || false}
        />
      )}
    </Box>
  )
}

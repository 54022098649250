import React from 'react'
import { Typography, Box, Button, Grid, CardMedia } from '@mui/material'
import { useGetAllSlidersQuery } from '../../src/APIs/SectionApis'
import { useTranslation } from 'react-i18next'
import { colorHero, heroBtn } from '../HeroTypes'
import { imageBaseUrl } from '../../src/App'
import { SwiperSlide, Swiper } from 'swiper/react'
import { Container } from '@mui/joy'
import { useNavigate } from 'react-router-dom'

type HeroMultiProps = {
  Btn?: heroBtn
  color?: colorHero
  data: any
}
type HeroProps = {
  background?: string
  image?: string
  Btn?: heroBtn
  color?: colorHero
  heroSlideType: 'single' | 'multi'
}
/**
 description_ar 
description_en 
image 
title_ar 
title_en  
 */
const HeroMulti: React.FC<HeroMultiProps> = ({ Btn, color, data }) => {
  const {
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <Box component={Swiper}>
        {data.map((slide: any) => (
          <SwiperSlide key={slide._id}>
            <Box
              sx={{
                backgroundImage: `url(${imageBaseUrl}${slide.image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                }}
              >
                <Container
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography
                    sx={{
                      color: color?.titleColor || 'white',
                      textAlign: 'end',
                      fontWeight: 'bold',
                      fontSize: {
                        xs: '1.5rem',
                        sm: '2rem',
                        md: '2.5rem',
                        lg: '3rem',
                        xl: '4rem',
                      },
                    }}
                  >
                    {language === 'en' ? slide.title_en : slide.title_ar}
                  </Typography>
                  <Typography
                    sx={{
                      color: color?.subTitleColor || 'white',
                      fontWeight: 'bold',
                      textAlign: 'end',
                      fontSize: {
                        xs: '1.5rem',
                        sm: '2rem',
                        md: '2.5rem',
                        lg: '3rem',
                        xl: '4rem',
                      },
                    }}
                  >
                    {language === 'en'
                      ? slide.description_en
                      : slide.description_ar}
                  </Typography>
                  <Button
                    onClick={() => navigate('/departments')}
                    variant="contained"
                    sx={{
                      backgroundColor: Btn?.backgroundColorBtn,
                      color: Btn?.colorBtn,
                      '&:hover': { backgroundColor: Btn?.backgroundColorBtn },
                      mt: 2,
                      borderRadius: '50px !important',
                      py: '1rem',
                      px: '2rem',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '16px',
                          sm: '18px',
                          md: '20px',
                        },
                        textTransform: 'capitalize',
                      }}
                    >
                      {Btn?.titleBtn}
                    </Typography>
                  </Button>
                </Container>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Box>
    </>
  )
}

const Hero4 = (props: HeroProps) => {
  const [_, { language: lang }] = useTranslation()
  const { data, isError, isLoading, error } = useGetAllSlidersQuery(undefined)
  const dataHero = data?.data.length - 1 //last item in array
  console.log('props in hero4', data?.data[dataHero])

  const colors = {
    primary: 'white',
    secondary: '#e3e3e3',
    backgroundPrimary: '#1f1f1f',
    backgroundSecondary: '#d2d9d5',
  }

  const handleClick = () => {
    console.log('clicked')
  }

  return (
    <Box minHeight={'100vh'}>
      {isLoading && <span className="loader"></span>}
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isLoading && !isError && (
        <>
          {props.heroSlideType === 'single' ? (
            <Box
              sx={{
                height: '100vh',
                width: '100%',
                backgroundImage: `url(${props.background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
              }}
            >
              <Grid
                container
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <Grid
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {/* Title and SubTitle */}
                  <Box
                    width={{ xs: 0.7, sm: 0.5, md: 0.7 }}
                    sx={{
                      direction: lang === 'en' ? 'ltr' : 'rtl',
                      // position: 'absolute',
                      // top: { xs: '65%', sm: '75%', md: '60%' },
                      // left: { xs: '50%', sm: '30%' },
                      // transform: 'translate(-50%,-50%)',
                    }}
                  >
                    {/* Title */}
                    <Typography
                      variant="h1"
                      sx={{
                        mb: 2,
                        color: props.color?.titleColor
                          ? `${props.color.titleColor}`
                          : `${colors.primary}`,
                        fontWeight: 'bold',
                        fontSize: {
                          xs: '1.8rem',
                          sm: '2.3rem',
                          md: '2.5rem',
                          lg: '3.2rem',
                          xl: '4rem',
                        },
                      }}
                    >
                      {data?.data[dataHero][`title_${lang}`]}
                    </Typography>

                    {/* Description */}
                    <Typography
                      variant="h6"
                      sx={{
                        color: props.color?.subTitleColor
                          ? `${props.color.subTitleColor}`
                          : `${colors.primary}`,
                        fontSize: {
                          xs: '1rem',
                          sm: '1.2rem',
                          md: '1.3rem',
                        },
                        wordBreak: 'break-word',
                      }}
                    >
                      {data?.data[dataHero][`description_${lang}`]}
                    </Typography>

                    {/* Button */}

                    <Button
                      variant="contained"
                      sx={{
                        mt: 2,
                        color: props.Btn?.colorBtn
                          ? `${props.Btn?.colorBtn} !important`
                          : `${colors.primary} !important`,
                        backgroundColor: props.Btn?.backgroundColorBtn
                          ? `${props.Btn?.backgroundColorBtn} !important`
                          : `${colors.backgroundPrimary} !important`,
                        p: '12px 40px',
                        borderRadius: props.Btn?.borderRaduisBtn
                          ? '50px'
                          : '5px',
                        outline: 'none',
                        display: { xs: 'none', sm: 'block' },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '16px',
                            sm: '18px',
                            md: '20px',
                          },
                          textTransform: 'capitalize',
                        }}
                      >
                        {props.Btn?.titleBtn
                          ? `${props.Btn?.titleBtn}`
                          : 'Lorem'}
                      </Typography>
                    </Button>
                  </Box>
                </Grid>

                <Grid
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', sm: 'end', lg: 'center' },
                    alignItems: { xs: 'start', md: 'center' },
                    mt: { xs: 5, md: 0 },
                    px: { xs: 0, sm: 5, md: 0 },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: '250px', sm: '350px', md: '500px' },
                      height: { xs: '250px', sm: '350px', md: '500px' },
                    }}
                  >
                    <CardMedia
                      component={'img'}
                      src={`${imageBaseUrl}${data?.data[dataHero].image}`}
                      sx={{
                        display: 'block',
                        width: { xs: '100%', md: '100%' },
                        height: '100%',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <HeroMulti
              data={data?.data || []}
              Btn={props.Btn}
              color={props.color}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default Hero4

import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import { TCartColorsData } from './SingleProductTypes'
import { useTranslation } from 'react-i18next'
// import { attrColors, attrs } from './SingleProductTypes'
type TAttrs = {
  colors: {
    attrKeyColor: string
    attrValueColor: string
    attrValueBgColor?: string
    attrValueBorderColor?: string
  }
  attr: {
    _id: string
    key_en?: string
    key_ar?: string
    values: { value_en: string; value_ar: string }[]
  }
  setCartData: React.Dispatch<React.SetStateAction<TCartColorsData>>
  cartData: TCartColorsData
}

interface IUpdateQualities {
  attr: { key_en?: string; key_ar?: string; _id: string }
  values: { value_en: string; value_ar: string }
}

export const Attrs = ({ colors, attr, setCartData, cartData }: TAttrs) => {
  const [, { language: lng }] = useTranslation()
  const handleUpdateQualities = ({ attr, values }: IUpdateQualities) => {
    setCartData((prev) => {
      const newQualities = prev.qualities.filter(
        (v) => v.key_en !== attr.key_en && v.key_ar !== attr.key_ar
      )
      return {
        ...prev,
        qualities: [
          ...newQualities,
          {
            key_en: attr.key_en,
            key_ar: attr.key_ar,
            value_en: values.value_en,
            value_ar: values.value_ar,
          },
        ],
      }
    })
  }
  return (
    <Box key={attr._id} dir="ltr">
      <Typography sx={{ color: colors.attrKeyColor, fontWeight: 'bold',
      textAlign: lng === 'en' ? 'left' : 'right'
    }}>
        {attr[`key_${lng === 'en' ? 'en' : 'ar'}`]}
      </Typography>
      <ButtonGroup
        variant="outlined"
        // disabled={
        //   attr.key_en ===
        //   cartData.qualities.find((v) => v.key_en === attr.key_en)?.key_en
        // }
      >
        {attr?.values?.map((val: { value_en: string; value_ar: string }) => (
          <Button
            variant={
              val.value_en ===
              cartData.qualities.findLast((v) => v.key_en === attr.key_en)
                ?.value_en
                ? 'contained'
                : 'outlined'
            }
            sx={{
              color: colors.attrValueColor,
              bgcolor: `${colors.attrValueBgColor} !important`,
              borderColor: `${colors.attrValueBorderColor} !important`,
            }}
            key={val.value_en}
            onClick={() =>
              handleUpdateQualities({
                attr,
                values: {
                  value_en: val.value_en,
                  value_ar: val.value_ar,
                },
              })
            }
          >
            {val[`value_${lng === 'en' ? 'en' : 'ar'}`]}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  )
}

import { Box, Grid, Typography } from '@mui/material'
import { useGetAllSlidersQuery } from '../../src/APIs/SectionApis'
import { useTranslation } from 'react-i18next'
import { heroSolidProps } from './HeroTypes'
import { imageBaseUrl } from '../../src/App'
const Hero1 = (props: heroSolidProps) => {
  const [_, { language: lang }] = useTranslation()
  const { data, isSuccess, isLoading, isError, error } =
    useGetAllSlidersQuery(undefined)
  const dataHero = data?.data.length - 1 //last item in array
  const colors = {
    primary: 'gray',
    secondary: '#e3e3e3',
    backgroundPrimary: '#292a2d',
    backgroundSecondary: '#d2d9d5',
  }
  console.log('path', data?.data[dataHero].image)
  return (
    <Box minHeight={'100vh'}>
      {isLoading && <span className="loader"></span>}
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isLoading && isSuccess && (
        <Box
          sx={{
            height: { xs: '100vh', md: '100vh' },
            width: '100%',
            background: props.backgroundColor ? props.backgroundColor : 'gray',
            position: 'relative',
            // mt: 8,
          }}
        >
          <Grid
            container
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  width: { xs: 0.7, md: 1 },
                  height: { xs: 0.7, md: 0.6, lg: 0.8 },
                  ml: { xs: 0, sm: 15, xl: 30 },
                  display: 'flex',
                  // transform: { xs: 'none', md: 'translateY(0px)' },
                }}
              >
                <img
                  src={`${imageBaseUrl}${data?.data[dataHero].image}`}
                  alt="hero"
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              display={'flex'}
              alignItems={{ xs: 'start', md: 'center' }}
              justifyContent={'center'}
              sx={{
                direction: lang === 'ar' ? 'rtl' : 'ltr',
              }}
            >
              <Box
                width={{ xs: 0.7 }}
                sx={{
                  mx: 'auto',
                }}
              >
                {/* Title */}
                <Typography
                  // variant="h1"
                  sx={{
                    mb: 2,
                    color: props.color?.titleColor
                      ? props.color?.titleColor
                      : colors.primary,
                    fontWeight: 'bold',
                    fontSize: {
                      xs: '1.8rem',
                      sm: '2.3rem',
                      md: '2.5rem',
                      lg: '3.2rem',
                      xl: '4rem',
                    },
                    wordBreak: 'break-word',
                  }}
                >
                  {data?.data[dataHero][`title_${lang}`]}
                </Typography>

                {/* sub title */}
                <Typography
                  variant="h6"
                  sx={{
                    color: props.color?.subTitleColor
                      ? props.color?.subTitleColor
                      : colors.primary,
                    fontSize: {
                      xs: '1.1rem',
                      sm: '1.3rem',
                      md: '1.5rem',
                    },
                    wordBreak: 'break-word',
                  }}
                >
                  {data?.data[dataHero][`description_${lang}`]}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  )
}

export default Hero1

import React from 'react'
import { Box, Typography } from '@mui/material'
import Heros from '../HeroSection/Heros'
import { HomeProps } from './HomeTypes'
import MostSeller from './Most Seller/MostSeller'
import Newest from './Newest/Newest'
import About from './About/About'
import FixedCard from './ProductCardFixed/FixedCard'

import VisionContainer from './Vision/VisionContainer'
import ContactUs from '../ContactUs/ContactUsHome/ContactUs'

const Home = (props: HomeProps) => {
  return (
    <Box>
      <Heros
        backgroundVideo={props.heroBackgroundVideo}
        hero={props.hero.type}
        backgroundImage={props.heroBackgroundImage}
        image={props.heroImage}
        Btn={props.hero.heroBtn}
        color={props.hero.color}
        backgroundColor={props.hero.heroBackgroundColor}
      />
      <Newest
        type={props.newest.type}
        typeScroll={props.newest.typeScroll}
        typeCollections={props.newest.typeCollections}
        backgroundColor={props.newest.backgroundColor}
        borderRadiusBtn={props.newest.borderRadiusBtn}
        color={props.newest.color}
        ADS={props.newest.ADS}
        card={props.newest.card}
        BtnCollections={props.newest.BtnCollections}
        backgroundArrowColor={props.newest.backgroundArrowColor}
      />
      {props.about && <About about={props.about} />}
      {props.vision && <VisionContainer vision={props.vision} />}
      {props.fixedCardData && (
        <FixedCard
          title_en={props?.fixedCardData?.title_en}
          title_ar={props?.fixedCardData?.title_ar}
          card={props?.fixedCardData?.card}
        />
      )}
      {props.sellerType && (
        <MostSeller
          type={props.sellerType.type}
          typeScroll={props.sellerType.typeScroll}
          typeCollections={props.sellerType.typeCollections}
          backgroundColor={props.sellerType.backgroundColor}
          borderRadiusBtn={props.sellerType.borderRadiusBtn}
          color={props.sellerType.color}
          ADS={props.sellerType.ADS}
          card={props.sellerType.card}
          BtnCollections={props.sellerType.BtnCollections}
        />
      )}
      {props.contactUs && <ContactUs colors={props.contactUs} />}
    </Box>
  )
}

export default Home

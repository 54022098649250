import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import { ContactUsType } from './ContactData/ContactUsType'
import { Container, FormLabel, Typography } from '@mui/joy'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import ContactTextInput from './ContactData/ContactInput'
import Contact from './ContactData/ContactData'
import { useContactMutation } from '../../src/APIs/contactsApis'
import Banner from '../../HomePage/Banners/Banner'
import { toast } from 'react-toastify'
import { propsContactUs03 } from '../ContactUs03/ContactData/ContactUsType'
const ContactUs = (props: propsContactUs03) => {
  const [_, { language: lang, changeLanguage }] = useTranslation()
  const [contact] = useContactMutation()
  const { contactData, contactValidation } = Contact()
  const formik = useFormik<ContactUsType>({
    initialValues: contactData,
    validationSchema: contactValidation,
    onSubmit: (values: any) => {
      formik.resetForm()
      contact(values)
        .unwrap()
        .then((res: any) => {
          toast.success(res[`success_${lang}`])
        })
        .catch((err: any) => {
          toast.error(err[`error_${lang}`])
        })
    },
  })

  return (
    <Box
      sx={{
        direction: lang === 'en' ? 'rtl' : 'ltr',
        minHeight: '100vh',
        // overflowY: 'hidden',
      }}
    >
      <Box
        sx={{
          width: props.ADS ? '100%' : { xs: '100%', md: '90%' },
          mx: 'auto',
          // mb: props.ADS ? 10 : 10,
          minHeight: '100vh',
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: props.ADS ? undefined : 'center',
            alignItems: props.ADS ? undefined : 'start',
            mt: props.ADS ? '0px' : { xs: 0, md: '20px' },
            position: props.ADS ? undefined : 'relative',
            // bgcolor:"blue"
            // height: {
            //   xs: 'auto',
            //   md: props.ADS ? '83vh' : '95vh',
            //   xl: props.ADS ? '93vh' : '100vh',
            // },

            minHeight: '100vh',
          }}
        >
          <Grid
            item
            // p={2}
            xs={12}
            md={6}
            height={{ xs: 400, md: '100vh' }}
            width={'50%'}
            sx={{
              backgroundImage: props.backgroundImag
                ? `url(${props.backgroundImag})`
                : 'url(https://vitofryfilter.com/vendor/semantic/examples/assets/images/wireframe/image-square.png)',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              // position: 'relative',
              borderRadius: props.ADS
                ? undefined
                : lang === 'en'
                ? { xs: undefined, md: '0 150px 00px 0' }
                : { xs: undefined, md: '150px 0 0 0' },
            }}
          >
            {props.ADS && (
              <Box width={'100%'}>
                <Banner bannerHorizontal />
              </Box>
            )}
          </Grid>

          <Grid
            item
            height={'100%'}
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: props.ADS ? 'start' : 'center',
            }}
          >
            <Container
              sx={{
                height: '100%',
                backgroundColor: props.ADS ? undefined : '#bf9fea',
                borderRadius: props.ADS
                  ? undefined
                  : lang === 'en'
                  ? { xs: undefined, md: '0 0 0 150px' }
                  : { xs: undefined, md: '0px 0 150px 0px' },
                py: 2,
              }}
            >
              <Box mt={0}>
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: { xs: '25px', md: '35px' },
                    color: props.color?.textColor
                      ? props.color.textColor
                      : 'black',
                  }}
                >
                  {lang === 'en' ? 'Contact Us' : 'تواصل معنا'}
                </Typography>
              </Box>

              <Box
                sx={{
                  direction: lang === 'en' ? 'ltr' : 'rtl',
                  mt: props.ADS ? 5 : 0,
                }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <Box mt={props.ADS ? 4 : 0}>
                    <ContactTextInput
                      index={0}
                      value={formik.values.name}
                      name={'name'}
                      error={formik.errors.name}
                      touched={formik.touched.name}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      placeholder={lang === 'en' ? 'Name' : 'الاسم'}
                      raduis
                      background={props.ADS ? false : true}
                      color={props.color}
                    />
                  </Box>

                  <Box mt={props.ADS ? 4 : 0}>
                    <ContactTextInput
                      index={0}
                      value={formik.values.email}
                      name={'email'}
                      error={formik.errors.email}
                      touched={formik.touched.email}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      placeholder={
                        lang === 'en' ? 'Email' : 'البريد الالكتروني'
                      }
                      raduis
                      background={props.ADS ? false : true}
                      color={props.color}
                    />
                  </Box>
                  <Box mt={props.ADS ? 4 : 0}>
                    <ContactTextInput
                      index={0}
                      value={formik.values.phone}
                      name={'phone'}
                      error={formik.errors.phone}
                      touched={formik.touched.phone}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      placeholder={lang === 'en' ? 'Phone' : 'الجوال'}
                      raduis
                      background={props.ADS ? false : true}
                      color={props.color}
                    />
                  </Box>

                  <Container>
                    <Box mt={4}>
                      <select
                        value={formik.values.contactType}
                        name="contactType"
                        style={{
                          backgroundColor: 'transparent',
                          width: '100%',
                          borderRadius: '50px',
                          padding: '15px',
                          border:
                            formik.errors.contactType &&
                            formik.touched.contactType
                              ? `2px solid red `
                              : `2px solid ${
                                  props.color?.borderColor
                                    ? props.color?.borderColor
                                    : 'black'
                                }`,
                          fontSize: '20px',
                          color: formik.values.contactType
                            ? props.color?.textColor
                            : '#333',
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="select one" hidden selected>
                          {lang === 'en'
                            ? 'Select Contact Type'
                            : 'أختار نوع التواصل'}
                        </option>
                        <option
                          value="complaints"
                          style={{
                            color: props.color?.textColor
                              ? props.color.textColor
                              : 'black',
                            fontSize: '15px',
                            fontWeight: 'bold',
                          }}
                        >
                          {lang === 'en' ? 'Complaints' : 'الشكاوي'}
                        </option>
                        <option
                          value="suggestions"
                          style={{
                            color: props.color?.textColor
                              ? props.color.textColor
                              : 'black',
                            fontSize: '15px',
                            fontWeight: 'bold',
                          }}
                        >
                          {lang === 'en' ? 'Suggestions' : 'الاقتراحات'}
                        </option>
                        <option
                          value="customerService"
                          style={{
                            color: props.color?.textColor
                              ? props.color.textColor
                              : 'black',
                            fontSize: '15px',
                            fontWeight: 'bold',
                          }}
                        >
                          {lang === 'en' ? 'Customer Service' : 'خدمة العملاء'}
                        </option>
                      </select>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'red',
                        }}
                      >
                        {formik.errors.contactType && formik.touched.contactType
                          ? formik.errors.contactType
                          : undefined}
                      </Typography>
                    </Box>

                    <Box my={4}>
                      <textarea
                        value={formik.values.message}
                        name="message"
                        placeholder={lang === 'en' ? 'Message' : 'اكتب رسالتك'}
                        style={{
                          width: '100%',
                          padding: '12px 16px',
                          height: '180px',
                          backgroundColor: 'transparent ',

                          fontSize: '16px',

                          color: formik.values.message
                            ? props?.color?.textColor
                            : '#BCD6D9',

                          border:
                            formik.errors.message && formik.touched.message
                              ? '2px solid red'
                              : `2px solid ${
                                  props.color?.borderColor
                                    ? props.color?.borderColor
                                    : 'black'
                                }`,
                          outline: 0,
                          borderRadius: '20px',
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'red',
                        }}
                      >
                        {formik.errors.message && formik.touched.message
                          ? formik.errors.message
                          : undefined}
                      </Typography>
                    </Box>
                    {/* Button */}
                    <Box
                      sx={{
                        textAlign: props.ADS
                          ? { xs: 'center', md: 'start' }
                          : 'center',
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: props.button?.backgroundColor
                            ? `${props.button?.backgroundColor} !important`
                            : '#000 !important',
                          color: props.button?.color
                            ? `${props.button?.color} !important`
                            : '#fff !important',
                          fontWeight: 'bold',
                          fontSize: { xs: '20px', md: '26px' },
                          transition: 'all 0.3s',
                          px: {
                            md: '60px',
                            xs: '55px',
                          },
                          py: '6px',
                          '&:active': {
                            transform: 'scale(0.9)',
                          },
                        }}
                        type="submit"
                      >
                        {lang === 'en' ? 'Send' : 'ارسال'}
                      </Button>
                    </Box>
                  </Container>
                </form>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ContactUs

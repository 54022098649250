import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import data from '../data.json'
import { useTranslation } from 'react-i18next'
import { useGetAllSlidersQuery } from '../../src/APIs/SectionApis'
import { heroSolidProps } from './HeroTypes'
import { useNavigate } from 'react-router-dom'

const Hero6 = (props: heroSolidProps) => {
  const navigate = useNavigate()
  const [_, { language: lang }] = useTranslation()
  const { data, isError, isLoading, error } = useGetAllSlidersQuery(undefined)
  const dataHero = data?.data.length - 1 //last item in array
  const colors = {
    primary: 'gray',
    secondary: '#e3e3e3',
    backgroundPrimary: '#292a2d',
    backgroundSecondary: '#d2d9d5',
  }

  return (
    <Box minHeight={"100vh"}>
      {isLoading && <span className="loader"></span>}
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data&& (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isLoading && !isError && (
        <Box
          sx={{
            height: { xs: '80vh', md: '100vh' },
            width: '100%',
            background: props.backgroundColor
              ? props.backgroundColor
              : 'linear-gradient(90deg ,#d3d3d3 35%,white 30%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            width={{ xs: 1, sm: 0.7, md: 0.4 }}
            textAlign={{ xs: 'center', sm:lang==="en"? 'left':"right" }}
            ml={{ xs: 0, sm: 10, md: 20 }}
            px={2}
          >
            <Typography
              variant="h1"
              sx={{
                mb: 2,
                textAlign: 'center',
                color: props.color?.titleColor
                  ? props.color?.titleColor
                  : colors.primary,
                fontWeight: 'bold',
                fontSize: {
                  xs: '1.8rem',
                  sm: '2.3rem',
                  md: '2.5rem',
                  lg: '3.2rem',
                  xl: '4rem',
                },
              }}
            >
              {data?.data[dataHero][`title_${lang}`]}
            </Typography>

            {/* sub title */}
            <Typography
              variant="h6"
              sx={{
                color: props.color?.subTitleColor
                  ? props.color?.subTitleColor
                  : colors.primary,
                fontSize: {
                  xs: '1rem',
                  sm: '1.2rem',
                  md: '1.3rem',
                },
                wordBreak: 'break-word',
              }}
            >
              {data?.data[dataHero][`description_${lang}`]}
            </Typography>
            <Box
              sx={{
                textAlign: 'center',
                mt: 2,
              }}
            >
              <Button
                onClick={() => navigate('/aboutUs')}
                variant="contained"
                sx={{
                  mt: 2,
                  color: props.Btn?.colorBtn
                    ? props.Btn?.colorBtn
                    : colors.secondary,
                  backgroundColor: props.Btn?.backgroundColorBtn
                    ? `${props.Btn?.backgroundColorBtn}!important`
                    : `${colors.backgroundPrimary} !important`,
                  p: '12px 50px',
                  borderRadius: props.Btn?.borderRaduisBtn ? '25px' : '5px',
                  textTransform: 'capitalize',
                }}
              >
                {props.Btn?.titleBtn ? props.Btn.titleBtn : 'lorem'}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Hero6

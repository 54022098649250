import { Box, Button } from "@mui/material";
import React from "react";
import data from "../data.json";
import { useNavigate } from "react-router-dom";
import { heroBackImgProps } from "./HeroTypes";



//floating image with button
const Hero5 = (props:heroBackImgProps) => {
const navigate=useNavigate()
  const colors = {
    primary: "white",
    secondary: "#e3e3e3",
    backgroundPrimary: "#1f1f1f",
    backgroundSecondary: "#d2d9d5",
  };

  return (
    <Box
      sx={{
        height: { xs: "85vh", md: "100vh" },
        width: "100%",
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <Box
      
        sx={{
            position: "absolute",
            bottom: {xs:"20%",md:"-5%"},
            left:"50%",
            transform: "translate(-50%,-50%)",
        }}
      >
        <Button
        onClick={()=>navigate("/aboutUs")}
          variant="contained"
          sx={{
        
            color:props.Btn?.colorBtn?props.Btn?.colorBtn: colors.primary,
            backgroundColor:props.Btn?.backgroundColorBtn?`${props.Btn?.backgroundColorBtn}!important`: `${colors.backgroundPrimary} !important`,
            p: "12px 45px",
            borderRadius:props.Btn?.borderRaduisBtn? "25px":"5px",
            fontSize: {xs:"1rem",md:"1.2rem"},
            textTransform: 'capitalize'
          }}
        >
            {props.Btn?.titleBtn?props.Btn?.titleBtn:"lorem"}
        </Button>
      </Box>
    </Box>
  );
};

export default Hero5;

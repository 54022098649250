import { Box, Typography } from '@mui/material'
import React from 'react'
import AboutUs from './About/AboutUs'
import { AboutUsProps } from './AboutTypes'
import { useGetAboutUsDataQuery } from '../../src/APIs/aboutUsApi'

const About = (props:AboutUsProps) => {


  
  
  return (
    <Box>
      <Box my={10}>
        <AboutUs
          
          button={props.about.button}
          video={props.about.video}
          image={props.about.image}
          backgroundColor={props.about.backgroundColor}
          color={props.about.color}
        />
      </Box>
    </Box>
  )
}

export default About

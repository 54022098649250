import React from 'react'
import { Box, Typography, Container, Button } from '@mui/material'
// import data from '../data.json'
import { Card } from '../../Cards/Card'
import { MostSellerCollectionProps } from '../Most Seller/MostSellerCollection'
import {
  useGetMostNewiestProductsQuery,
  useGetMostSellingProductsQuery,
} from '../../src/APIs/ProductApis'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const OffersCollection = (props: MostSellerCollectionProps) => {
  console.log('props', props.backgroundArrowColor);
  
  const navigate = useNavigate()
  const [_, { language: lang }] = useTranslation()
  const {
    data: MostSeller,
    isSuccess: MostSuccess,
    isError: MostError,
    error: MostErrMsg,
    isLoading: loadingMost,
  } = useGetMostSellingProductsQuery(undefined)
  const {
    data: Newest,
    isSuccess,
    isLoading,
    error,
    isError,
  } = useGetMostNewiestProductsQuery(undefined)
  const getData =
    props.title === 'NewestCollections' ? Newest?.data : MostSeller?.data
  const offer = getData?.slice(0, 4)
  const colors = {
    primary: 'white',
    secondary: 'black',
    backgroundPrimary: '#b0b4c5',
    backgroundSecondary: '#d2d9d5',
  }

  return (
    <Box my={5} py={3}>
      {isError && MostError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isLoading && !loadingMost && isSuccess && MostSuccess && (
        <>
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '1.8rem', sm: '2.5rem', lg: '4rem' },
                fontWeight: 'bold',
                color:props.color?props.color:colors.secondary,
              }}
            >
              {props.title === 'NewestCollections'
                ? lang === 'en'
                  ? 'Newest'
                  : 'الاحدث'
                : lang === 'en'
                ? 'Most Seller'
                : 'الاكثر مبيعا'}
            </Typography>
          </Box>

          {/* collection */}
              <Container>

          <Box
            
            mt={2}
            position={'relative'}
          
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(4, 1fr)',
               
              },
              gap: 2,
              gridTemplateRows:{xs:'repeat(3, 1fr)',sm:'repeat(2, 285px)',md:'repeat(2, 360px)'} ,
            }}
          >
            {offer?.map((item: any, index: number) => (
              <Box 
              key={index}
                gridColumn={
                  index === 0
                    ? { xs: 'span 1', sm: 'span 2' }
                    : index === 1
                    ? { xs: 'span 1', sm: 'span 2' }
                    : 'span 1'
                } // Use template literals here
                gridRow={
                  index === 0 ? { xs: 'span 1', sm: 'span 2' } : 'span 1'
                } // Use template literals here
              >
                <Card
                  data={item}
                  colors={props.card.colors}
                  border={props.card.border}
                  borderRadius={props.card.borderRadius}
                  wide={props.card.wide}
                  width={props.card.width}
                  height={props.card.height}
                  title={props.card.title}
                  desc={props.card.desc}
                  button={props.card.button}
                  favorite={props.card.favorite}
                  hoverWithFav={props.card.hoverWithFav}
                  hover={props.card.hover}
                />
              </Box>
            ))}
          </Box>
              </Container>

          <Box textAlign={'center'} my={2}>
            <Button
              variant="outlined"
              sx={{
                mt: 2,
                color: props.BtnCollections?.titleColor
                  ? props.BtnCollections?.titleColor
                  : colors.secondary,
                padding: { xs: '0.4rem 3rem', md: '0.7rem 5rem' },
                border: `1px solid ${
                  props.BtnCollections?.titleColor
                    ? props.BtnCollections?.titleColor
                    : colors.secondary
                } !important`,
                backgroundColor: props.BtnCollections?.backgroundColor
                  ? `${props.BtnCollections?.backgroundColor}!important`
                  : 'transparent !important',
                borderRadius: props.BtnCollections?.borderRadius
                  ? '50px'
                  : '0px',
              }}
              onClick={() => {
                navigate('/products')
              }}
            >
              {props.BtnCollections?.title
                ? props.BtnCollections?.title
                : 'lorem'}
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default OffersCollection

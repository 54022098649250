import { Grid, Breadcrumbs, Link, Typography } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'

function CustomBreadcrumb({
  data,
  lng,
}: {
  data: {
    title_en: string
    title_ar: string
  }
  lng: string
}) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        justifyContent: 'end',
        pb: 2,
        
      }}
    >
      <Breadcrumbs dir={lng === 'en' ? 'ltr' : 'rtl'} aria-label="breadcrumb">
        <Link sx={{color: '#4f3124'}} component={NavLink} underline="hover" color="inherit" to="/">
          {lng === 'en' ? 'Home' : 'الرئيسية'}
        </Link>

        <Typography sx={{color: '#896B60'}} color="text.primary">
          {lng === 'en' ? data.title_en : data.title_ar}
        </Typography>
      </Breadcrumbs>
    </Grid>
  )
}

export default CustomBreadcrumb

import React, { useState } from 'react';
import { Box, Typography, ButtonBase } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { verifyProps } from '../ForgetType';
import { useVerifyCodeMutation } from '../../src/APIs/forgetPassApi';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';

const VerifyPassword = (props: verifyProps) => {
  const [verifyCode] = useVerifyCodeMutation(undefined);
  const navigate = useNavigate();
  const [_, { language: lang }] = useTranslation();

  const formik = useFormik({
    initialValues: {
      otp: '', // Initialize OTP value
    },
    validationSchema: yup.object({
      otp: yup
        .string()
        .required(lang === 'en' ? 'Code is required*' : '*الكود مطلوب')
        .matches(/^\d{6}$/, lang === 'en' ? 'Code must be 6 digits' : 'الكود يجب أن يكون 6 أرقام'),
    }),
    onSubmit: (values) => {
      const data = {
        resetCode: values.otp, // Use Formik's 'otp' field value
      };
     
      
      verifyCode(data)
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lang}`]);
          props.setValue('3');
          props.setData(res.data);
          formik.resetForm();
        })
        .catch((err) => {
          toast.error(err?.data[`error_${lang}`]);
          console.log(err?.data[`error_${lang}`]);
        });
    },
  });

  return (
    <Box
      sx={{
        minHeight: '90vh',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '45%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          width: { xs: '100%', sm: '70%', lg: '40%', xl: '30%' },
          p: 2,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" gap={2}>
            <OtpInput
              containerStyle={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                gap: '10px',
              }}
              inputStyle={{
                width: '100%',
                height: '80px',
                borderRadius: '10px',
                border: `2px solid ${
                  props.style.borderInputColor
                    ? props.style.borderInputColor
                    : `2px solid black`
                }`,
                fontSize: "25px",
              }}
              value={formik.values.otp} // Use Formik field value
              onChange={(otp) => formik.setFieldValue('otp', otp)} // Update Formik field value
              numInputs={6}
              renderInput={(props) => <input {...props} />}
            />
          </Box>

          {formik.errors.otp && formik.touched.otp && (
            <Typography fontWeight={'bold'} fontSize={13} color="red" sx={{ mt: 2 }}>
              {formik.errors.otp}
            </Typography>
          )}

          <Box mt={2}>
            <ButtonBase
              type="submit"
              sx={{
                color: props.style.colorBtn ? props.style.colorBtn : 'white',
                bgcolor: props.style.backgroundColorBtn
                  ? props.style.backgroundColorBtn
                  : 'gray',
                mt: '20px',
                fontSize: {
                  xl: '35px',
                  lg: '20px',
                  xs: '18px',
                },
                p: {xs:"10px 30px",sm:'6px 30px'},
                width: '100%',
                borderRadius: '10px',
              }}
            >
              {lang === 'en' ? 'Check' : 'تحقق'}
            </ButtonBase>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default VerifyPassword;

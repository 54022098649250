import { Box, Stack, Typography } from '@mui/joy'
import { FooterProps, KeyOfSocial } from '../FooterTypes'
import { ICategory, links } from '../Footer'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useGetAllCategoriesQuery } from '../../src/APIs/categoriesApi'
import { CardMedia, Grid } from '@mui/material'
import Map from './Map'
import googlePay from '../google_pay.png'
import mada from '../mada.png'
import paypal from '../paypal.png'
import tabby from '../tabby.png'
import tamara from '../tamara.png'
import visa from '../visa.png'

interface NoonProps extends Omit<FooterProps, 'type' | 'information'> {}
function CategorySection({
  categories,
  color,
}: {
  categories: ICategory[]
  color: NoonProps['color']
}) {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <>
      <Stack
        width={'100%'}
        direction={'column'}
        sx={{
          py: 4,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography
            sx={{
              color: color.primary,
              fontSize: 20,
              textAlign: { xs: 'center', md: 'start' },
            }}
          >
            {language === 'en' ? 'Categories' : 'الاقسام'}
          </Typography>
        </div>
        <Stack
          direction={'row'}
          gap={5}
          width={'100%'}
          sx={{
            pt: 2,
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {categories.map((category) => (
            <Box
              key={category._id}
              sx={{
                color: color.primary,
                textDecoration: 'none !important',
              }}
              component={Link}
              to={`/departments/${category._id}`}
            >
              {language === 'en' ? category.name_en : category.name_ar}
            </Box>
          ))}
        </Stack>
      </Stack>
    </>
  )
}

function ContentSection({ color }: { color: NoonProps['color'] }) {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <Stack
      direction={{ xs: 'row', md: 'column' }}
      gap={3}
      width={'100%'}
      justifyContent={'space-evenly'}
      alignItems={'start'}
      flexWrap={'wrap'}
      py={5}
    >
      {links.map((link) => (
        <Box
          key={link.path}
          sx={{
            color: color.primary,
            textDecoration: 'none !important',
            fontSize: 18,
            fontWeight: 'bold',
          }}
          component={Link}
          to={link.path}
        >
          {language === 'en' ? link.en : link.ar}
        </Box>
      ))}
    </Stack>
  )
}

import { FaFacebookF, FaLinkedinIn, FaPinterestP } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'
import { PiTwitterLogoFill } from 'react-icons/pi'
import { cloneElement } from 'react'

function SocialSection({ social }: { social: NoonProps['social'] }) {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <Box sx={{ width: 'fit-content' }}>
      <Typography
        sx={{
          color: social?.color,
          fontSize: 20,
          textAlign: 'center',
        }}
      >
        {language === 'en' ? 'CONNECT WITH US' : 'تواصل معنا'}
      </Typography>
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          py: 2,
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        {social &&
          (Object.keys(social) as KeyOfSocial[]).map((key) => {
            const Icon = {
              facebook: FaFacebookF,
              instagram: FiInstagram,
              linkedin: FaLinkedinIn,
              twitter: PiTwitterLogoFill,
              pinterest: FaPinterestP,
            }[key]
            if (!Icon) return null
            return (
              <Box
                component={'a'}
                key={key}
                target="_blank"
                href={social[key]}
                sx={{
                  color: social?.bgColor,
                  fontSize: 20,
                  textAlign: { xs: 'center', md: 'start' },
                  backgroundColor: social?.color,
                  borderRadius: '50%',
                  width: '45px',
                  height: '45px',
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                {cloneElement(<Icon />, {})}
              </Box>
            )
          })}
      </Stack>
    </Box>
  )
}

function Noon({ bgColor, color, variant, map, social,footerText }: NoonProps) {
  const {
    i18n: { language },
  } = useTranslation()
  const { data, isLoading, isSuccess } = useGetAllCategoriesQuery(null)
const {pathname}=useLocation()
  const categories = (data?.data || []) as ICategory[]

  const dir = language === 'ar' ? 'rtl' : 'ltr'
  return (
    <div dir={dir} style={{
      display: pathname==="/thankYou"||pathname==="/sign-in"||pathname==="/register"||pathname==="/forgetPassword"?"none":"flex",
    }}>
      <Box
        sx={{
          backgroundColor: bgColor.primary,
          color: color.primary,
          pb: 5,
        }}
      >
        {isLoading && <div>loading...</div>}
        {isSuccess && <CategorySection categories={categories} color={color} />}
        <Grid
          container
          sx={{
            px: 5,
            alignItems: 'end',
            justifyContent: variant === 'form-map' ? 'center' : 'space-evenly', 
          }}
        >
          <Grid item xs={12} md={4} lg={3}>
            <ContentSection color={color} />
          </Grid>
          {(variant === 'form' || variant === 'form-map') && (
            <Grid item xs={12} md={4} lg={3}>
              <Box>
                <Stack
                  sx={{
                    width: '100%',
                    gap: 2,
                    py: 2,
                  }}
                >
                  <Box
                    component={'input'}
                    type="text"
                    placeholder={
                      language === 'en' ? 'Email' : 'البريد الالكتروني'
                    }
                    sx={{
                      color: color.primary,
                      '::placeholder': { color: color.primary },
                      fontSize: {
                        xs: 10,
                        md: 20,
                      },
                      width: { xs: '100%', md: '50%' },
                      backgroundColor: 'transparent',
                      padding: '5px 10px',
                      border: 'none',
                      outline: 'none !important',
                      borderBottom: `1px solid ${color.primary}`,
                    }}
                  />
                  <Box
                    component={'textarea'}
                    placeholder={language === 'en' ? 'Write Here' : 'اكتب هنا'}
                    rows={5}
                    sx={{
                      color: color.primary,
                      '::placeholder': { color: color.primary },
                      fontSize: {
                        xs: 10,
                        md: 20,
                      },
                      backgroundColor: 'transparent',
                      padding: '5px 10px',
                      outline: 'none !important',
                      border: `1px solid ${color.primary}`,
                    }}
                  />
                </Stack>
              </Box>
            </Grid>
          )}
          {(variant === 'map' || variant === 'form-map') && (
            <Grid item xs={12} md={4} lg={3}>
              <Box
                sx={{
                  width: '300px',
                  px: 2,
                  mx: 'auto',
                }}
              >
                <Map url={map?.url || ''} />
              </Box>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            sx={{
              display: 'flex',
              justifyContent: {
                xs: 'center',
                md: 'flex-end',
              },
              alignItems: 'center',
            }}
          >
            <SocialSection social={social} />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
          py: 2,
          backgroundColor: bgColor.secondary,
        }}
      >
        <Typography sx={{ color: color.primary }}>{footerText}</Typography>
        <Box>
          <Stack direction={'row'} gap={1}>
            <CardMedia
              component={'img'}
              sx={{ height: { lg: 30 } }}
              src={googlePay}
            />
            <CardMedia
              component={'img'}
              sx={{ height: { lg: 30 } }}
              src={paypal}
            />
            <CardMedia
              component={'img'}
              sx={{ height: { lg: 30 } }}
              src={tabby}
            />
            <CardMedia
              component={'img'}
              sx={{ height: { lg: 30 } }}
              src={visa}
            />
            <CardMedia
              component={'img'}
              sx={{ height: { lg: 30 } }}
              src={tamara}
            />
            <CardMedia
              component={'img'}
              sx={{ height: { lg: 30 } }}
              src={mada}
            />
          </Stack>
        </Box>
      </Box>
    </div>
  )
}

export default Noon

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ParentDepartmentPage } from '../AllProducts/ParentDepartmentPage'
import { Profile } from '../Profile/Profile'
import AuthenticationUser from '../AuthenticationUser/AuthenticationUser'
import { Cart05 } from '../Cart/Cart05'
import ContactUs from '../ContactUs/ContactUs1/ContactUs'
import { Navbar } from '../Nav/Navbar'
import { Privacy } from '../Privacy/Privacy'
import { About } from '../AboutUs/About'
import Home from '../HomePage/Home'
import img2 from './assets/auth.jpg'
import img3 from './assets/img1.png'
import img4 from './assets/img2.png'
import logo from './assets/logo.png'
import backgroundLogin from './assets/login-back.jpeg'
import video from './assets/video.mp4'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Footer from '../Footer/Footer'
import ForgetPass from '../Forget Password/ForgetPass'
import PaymentMoyasar from '../PaymentMoyasar/PaymentMoyasar'
import ThanksPage from '../ThanksPage/ThanksPage'
import { useLazyGetMeQuery } from './APIs/UserApis'
import { useCreateGuestUserMutation } from './APIs/gestUserApi'
import { setCurrentUser } from './APIs/userSlice'
import { useTranslation } from 'react-i18next'
import CheckOutJariri from '../PaymentPage/jariri/checkOut/CheckOutJariri'
import { JaririSingleProduct } from '../SingleProduct/JaririSingleProduct'

export const imageBaseUrl = `https://bookstore.work:8080/uploads/`

export const App = () => {
  const dispatch = useDispatch()
  // const { currentUser }: any = useSelector((state) => state)
  const [createGuestUser] = useCreateGuestUserMutation()
  // console.log(currentUser)
  const [, { language: lang, changeLanguage }] = useTranslation()
  useEffect(() => {
    const sessionState = localStorage.getItem('token')

    if (!sessionState) {
      createGuestUser(undefined).then((response) => {
        if ('data' in response) {
          const { data } = response
          console.log(data)
          if (data?.token) {
            localStorage.setItem('token', data.token)
          }
        } else {
          const { error } = response
          console.error(error)
          // Handle the error case here
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('token')])
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const [getMe] = useLazyGetMeQuery()

  useEffect(() => {
    getMe(undefined).then(({ data }) => {
      if (data?.data.role !== 'guest') {
        dispatch(setCurrentUser(data?.data))
      }
    })
  }, [dispatch, getMe])
  useEffect(() => {
    if (localStorage.i18nextLng === 'en') {
      changeLanguage('ar')
    }
  }, [])
  return (
    <BrowserRouter>
      <Navbar
        direction="vertical"
        colors={{
          borderColor: '#896B60',
          logoColor: 'white',
          linkTextColor: '#F5F2EA',
          activeLinkColor: '#896B60',
          buttonTextColor: '#F5F2EA',
          buttonBgColor: '#CC8241',
          menuItemColor: '#CC8241',
          cartIconColor: '#CC8241',
          backgroundColor: '#4f3124',
          iconColor: '#F5F2EA',
        }}
        drawerBg
        blockStyle
        navBg
        menu
      />

      <Routes>
        <Route
          path="/"
          element={
            <Home
              hero={{
                type: 'HeroBack4',
                heroBackgroundColor:
                  'linear-gradient(90deg ,#D0C7C2 35%,#D0C7C2 30%)',
                color: { subTitleColor: '#D0C7C2', titleColor: '#F5F2EA' },
                HeroSlideType: 'multi',
                heroBtn: {
                  backgroundColorBtn: '#CC8241',
                  borderRaduisBtn: true,
                  colorBtn: '#4f3124',
                  titleBtn: lang === 'en' ? 'Shop Now' : 'تسوق الان',
                },
              }}
              newest={{
                type: 'NewestCollections',
                typeCollections: 'Offers',
                backgroundColor: '#D0C7C2',
                color: '#4f3124',
                card: {
                  colors: {
                    backgroundColor: '#4f3124',
                    buttonTextColor: '#F5F2EA',
                    titleColor: '#F5F2EA',
                    descColor: '#F5F2EA',
                    buttonBackgroundColor: '#CC8241',
                    borderColor: '#F5F2EA',
                    hoverColor: ' #4f3124',
                  },

                  hover: true,
                  button: true,
                  favorite: true,
                  height: '100%',
                  width: '100%',
                },

                BtnCollections: {
                  title:
                    lang === 'en' ? 'Newest Collections' : 'أحدث المجموعات',
                  titleColor: '#4f3124',
                  backgroundColor: '#CC8241',
                  // borderRadius: true,
                },
              }}
              vision={{
                video: video,
                background: '#fff',
                color: {
                  title: '#4f3124',
                  subTitle: '#896B60',
                },
              }}
              sellerType={{
                type: 'MostSellerScroll',
                typeScroll: 'MostSeller',
                backgroundColor: '#D0C7C2',
                color: '#4f3124',
                card: {
                  borderRadius: false,
                  colors: {
                    backgroundColor: '#fff',
                    titleColor: '#4f3124',
                    descColor: '#896B60',
                    buttonTextColor: '#4f3124',
                    buttonBackgroundColor: '#CC8241',
                    borderColor: '#4f3124',
                  },

                  height: {
                    xs: '300px',
                    md: '360px',
                  },
                  width: '100%',
                  title: true,
                  favorite: true,
                  desc: true,
                  button: true,
                },
              }}
              contactUs={{
                backgroundColor: '#fff',
                backgroundBtn: '#CC8241',
                colorBtn: '#4f3124',
                color: '#4f3124',
                borderRadiusInput: true,
                // borderRadiusBtn: true,
                placeholderColor: '#4f3124',
                borderInputColor: '#4f3124',
                borderColor: '1px solid #4f3124',
                backgroundInputColor: '#fff',
              }}
            />
          }
        />
        <Route
          path="/departments"
          element={
            <ParentDepartmentPage
              grid
              type="allProducts"
              card={{
                colors: {
                  titleColor: '#4f3124',
                  descColor: '#896B60',
                  buttonBackgroundColor: '#CC8241',
                  buttonTextColor: '#4f3124',
                },
                height: { xs: 200, sm: 225, md: 250, lg: 300 },
                width: { xs: 150, sm: 175, md: 200, lg: 250 },
                title: true,
                desc: true,
                favorite: true,
                // button: true,
                // borderRadius: true,
              }}
            />
          }
        />

        <Route
          path="/products/:id"
          element={
            <JaririSingleProduct
              colors={{
                attrKeyColor: '#4f3124',
                attrValueColor: '#896B60',
                buttonBgColor: '#CC8241',
                buttonColor: '#4f3124',
                priceBeforeColor: '#4f3124',
                titleColor: '#4f3124',
                attrValueBgColor: '#CC8241',
                descColor: '#896B60',
                priceAfterColor: '#896B60',
                attrValueBorderColor: '#896B60',
              }}
            />
          }
        />
        <Route
          path="/departments/:categoryId"
          element={
            <ParentDepartmentPage
              type="category"
              card={{
                colors: {
                  titleColor: '#4f3124',
                  descColor: '#896B60',
                  buttonBackgroundColor: '#CC8241',
                  buttonTextColor: '#4f3124',
                },
                height: 300,
                width: 300,
                // borderRadius: true,
                title: true,
                desc: true,
                favorite: true,
                // button: true,
              }}
            />
          }
        />
        <Route
          path="/checkOut"
          element={
            <CheckOutJariri
              color={{
                title: '#4f3124 ',
                label: '#896B60 ',
              }}
              button={{
                color: '#4f3124',
                backgroundColor: '#CC8241',
              }}
              verifiedCodeColor={{
                title: '#4f3124',
                borderInputColor: '#896B60',
                Btn: {
                  titleColor: '#4f3124 ',
                  backgroundColor: '#CC8241 ',
                },
              }}
            />
          }
        />
        <Route path="/payment-order" element={<PaymentMoyasar />} />
        <Route
          path="/aboutUs"
          element={
            <About
              category={3}
              images={[img2, img3, img4]}
              colors={{
                titleColor: '#4f3124',
                descColor: '#4f3124',
                bgColor1: '#fff',
                bgColor2: '#D0C7C2',
              }}
            />
          }
        />

        <Route
          path="/products"
          element={
            <ParentDepartmentPage
              grid
              type="allProducts"
              card={{
                colors: {
                  titleColor: '#4f3124',
                  descColor: '#896B60',
                  buttonBackgroundColor: '#CC8241',
                  buttonTextColor: '#4f3124',
                },
                height: { xs: 200, sm: 225, md: 250, lg: 300 },
                width: { xs: 150, sm: 175, md: 200, lg: 250 },
                title: true,
                desc: true,
                favorite: true,
                // button: true,
                // borderRadius: true,
              }}
            />
          }
        />

        <Route
          path="/cart"
          element={
            <Cart05
              colors={{
                header: '#4f3124',
                title: '#4f3124',
                remove: '#4f3124',
                productsBg: '#F5F2EA',
                priceBefore: '#4f3124',
                priceAfter: '#4f3124',
                button: '#4f3124',
                buttonBg: '#CC8241',
                orderSummary: '#4f3124',
                orderSummaryBg: '#F5F2EA',
                orderSummaryBody: '#4f3124',
                quantity: '#4f3124',
                desc: '#4f3124',
              }}
            />
          }
        />
        <Route
          path="/savedProducts"
          element={
            <ParentDepartmentPage
              type="favorite"
              card={{
                colors: {
                  titleColor: '#4f3124',
                  descColor: '#896B60',
                  buttonBackgroundColor: '#CC8241',
                  buttonTextColor: '#4f3124',
                },
                height: 300,
                width: 300,
                title: true,
                desc: true,
                // borderRadius: true,
                favorite: true,
              }}
              search
            />
          }
        />
        <Route path="/privacyPolicy" element={<Privacy noImage />} />
        <Route
          path="/contactUs"
          element={
            <ContactUs
              color={{
                borderColor: '#896B60',
                textColor: '#4f3124',
              }}
              ADS={true}
              backgroundImag={img2}
              button={{
                color: '#4f3124',
                backgroundColor: '#CC8241',
              }}
            />
          }
        />

        <Route
          path="/profile"
          element={
            <Profile
              colors={{
                primaryBgColor: 'white',
                secondaryBgColor: '#D0C7C2',
                titleColor: '#4f3124 ',
                buttonColor: '#4f3124',
                buttonBgColor: '#CC8241',
                labelColor: '#4f3124 ',
                borderColor: '#4f3124',
              }}
              shapeProfile="horizontal"
            />
          }
        />

        <Route
          path="/sign-in"
          element={
            <AuthenticationUser
              type="login"
              backgroundImag={backgroundLogin}
              logo={logo}
              backgroundBtn="#CC8241"
              color="#4f3124"
              borderInputColor="#896B60"
              placeholderColor="#4f3124"
              verifiedCodeColor={{
                title: '#4f3124',
                borderInputColor: '#896B60',
                Btn: {
                  titleColor: '#4f3124 ',
                  backgroundColor: '#CC8241 ',
                },
              }}
            />
          }
        />
        <Route
          path="/register"
          element={
            <AuthenticationUser
              type="signUp"
              backgroundImag={backgroundLogin}
              logo={logo}
              backgroundBtn="#CC8241"
              color="#4f3124"
              borderInputColor="#896B60"
              placeholderColor="#4f3124"
            />
          }
        />
        <Route
          path="/forgetPassword"
          element={
            <ForgetPass
              borderInputColor="#4f3124"
              backgroundColorBtn="#CC8241"
              colorBtn="#4f3124"
              placeholderColor="#4f3124"
            />
          }
        />
        <Route
          path="/thankYou"
          element={
            <ThanksPage
              color={{
                titleColor: '#4f3124',
                subTitleColor: '#896B60',
                Btn: {
                  textColor: '#4f3124',
                  bgColor: '#CC8241',
                },
              }}
            />
          }
        />
      </Routes>

      <Footer
        logo={logo}
        type="amazon"
        variant="map"
        bgColor={{ primary: '#bbb0aa', secondary: '#D0C7C2' }}
        color={{ primary: '#4f3124', secondary: '#4f3124' }}
        footerText={
          lang === 'en'
            ? `© ${new Date().getFullYear()} All Rights Reserved.`
            : `© ${new Date().getFullYear()} جميع الحقوق محفوظة.`
        }
        information={{
          address:
            'Jarir Marketing Company, King Khaled Street, Al Murabba District, Riyadh, KSA',
          email: 'jarir@gmail.com',
          phone: '+966500458116',
        }}
        social={{
          color: '#D0C7C2',
          bgColor: '#4f3124',
          facebook: 'https://www.facebook.com',
          instagram: 'https://www.instagram.com',
          linkedin: 'https://www.linkedin.com',
        }}
        map={{
          url: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1854.8072173905196!2d39.1349908!3d21.6009683!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3db4ed164d9a9%3A0xb6a4ba7216165791!2sSarri%20technology!5e0!3m2!1sen!2seg!4v1695652130557!5m2!1sen!2seg',
        }}
      />
    </BrowserRouter>
  )
}

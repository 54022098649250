import { Box, InputAdornment, TextField, Typography,ButtonBase,InputBase} from '@mui/material'
import { useFormik } from 'formik'
import MailIcon from '@mui/icons-material/Mail'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { verifyProps } from './ForgetType'
import * as yup from 'yup'
import { useForgetPassMutation } from '../src/APIs/forgetPassApi'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
const ForgetPassword = (props: verifyProps) => {
    console.log(props.style);
    
  const [forgetPass]=useForgetPassMutation(undefined)
  const navigaet=useNavigate()
  const [_, { language: lang }] = useTranslation()
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema:yup.object({
        email:yup
        .string()
        .email(lang === 'en' ? 'Invalid email' : 'بريد إلكتروني خاطئ')
        .required(
          lang === 'en' ? 'Email is required*' : '*البريد الإلكتروني مطلوب'
        ),
    }),
    onSubmit: (values) => {
        const data={
            username:values.email
        }

        forgetPass(data).unwrap().then((res)=>{
            props.setValue('2')
            toast.success(res[`success_${lang}`])
           
            
        }).catch((err)=>{
            toast.error(err?.data[`error_${lang}`])
            console.log(err?.data[`error_${lang}`]);
            
        })
    },
  })
  return (
    <Box
      sx={{
        minHeight: '90vh',
        direction: lang === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Box 
      sx={{
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: { xs: '100%', sm: '50%',lg:"35%",xl:"25%" },
        p: 2,
      }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <InputBase
            
                startAdornment={

                  <InputAdornment position="start">
                    <MailIcon sx={{
                        color:props.style.borderInputColor
                        ?props.style.backgroundColorBtn
                        :`black`,
                        mx:2
                    }} />
                  </InputAdornment>
                }
             
              placeholder={lang === 'en' ? 'Email' : 'البريد الإلكتروني'}
              type={'text'}
              sx={{
                borderRadius: props.style.borderRadius
                  ? '50px !important'
                  : '0 !important',
                mt: '20px',
                p: '15px 0px',
                width: '100%',
                border: `2px solid ${
                    props.style.borderInputColor
                    ?props.style.borderInputColor
                    : `2px solid black`
                }`,
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  opacity: 0,
                },
                '& input::placeholder': {
                  color: props.style.placeholderColor
                    ? props.style.placeholderColor
                    : 'gray',
                },
              }}
              name="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              
            />
            {formik.errors.email && formik.touched.email && (
              <Typography
                fontWeight={'bold'}
                fontSize={13}
                //   variant="p"
                color="red"
                sx={
                  {
                    // fontFamily: publicFontFamily,
                  }
                }
              >
                {formik.errors.email}
              </Typography>
            )}
          </Box>

          <Box
          mt={2}
          >
            <ButtonBase
            type="submit"
            sx={{
              color: props.style.colorBtn ? props.style.colorBtn : 'white',
              bgcolor: props.style.backgroundColorBtn
                ? props.style.backgroundColorBtn
                : 'gray',
              mt: '20px',
              fontSize: {
                xl: '35px',
                lg: '20px',

                xs: '18px',
              },

               p: {xs:"10px 30px",sm:'6px 30px'},
              width: '100%',
              // fontWeight: 'bold',
              borderRadius: props.style.borderRadius ? '50px' : '5px',
            }}
            >
                {lang === 'en' ?'Send Code':'ارسال الكود'}
            </ButtonBase>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

export default ForgetPassword

import { Box, Button, Container, Stack, Typography } from '@mui/material'
import React from 'react'

import { useTranslation } from 'react-i18next'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { use } from 'i18next'

type ThanksPageProps = {
  color?: {
    titleColor: string
    subTitleColor: string
    Btn?: {
      bgColor: string
      textColor: string
    }
  }
}

const ThanksPage = (props: ThanksPageProps) => {
  const param = new URLSearchParams(useLocation().search)
  const status = param.get('status')

  const message = param.get('message')

  const [_, { language }] = useTranslation()
  const navigate = useNavigate()
  return (
    <Container>
      <Stack
        height={'100vh'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box
          maxWidth={{
            xs: '100%',
            sm: '100%',
            md: '600px',
          }}
        >
          {status === 'paid' || status === null ? (
            <>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 'bold',
                  color: props.color?.titleColor || '#d55252',
                  textAlign: 'center',
                }}
              >
                {language === 'en' ? 'THANKS' : 'شكراً'}
              </Typography>
              <Typography
                
                sx={{
                  fontWeight: 'bold',
                  color: props.color?.titleColor || '#d55252',
                  textAlign: 'center',
                  fontSize: {
                    xs: '30px',
                    sm: '30px',
                    md: '50px',
                  }
                }}
              >
                {language === 'en' ? 'FOR THE ORDER' : 'علي الطلب'}
              </Typography>
            </>
          ) : (
            <Typography
               
              sx={{
                fontWeight: 'bold',
                color:  '#d55252',
                textAlign: 'center',
                fontSize: {
                  xs: '30px',
                  sm: '30px',
                  md: '50px',
                }
              }}
            >
              {language === 'en'
                ? 'SORRY YOUR PAYMENT FAILED'
                : 'عذراً فشلت عملية الدفع'}
            </Typography>
          )}

          <Stack justifyContent="center" gap="20px" mt="40px">
            <Button
              onClick={() => window.location.replace('/')}
              sx={{
                bgcolor:
                  `${props.color?.Btn?.bgColor}!important` ||
                  'black !important',
                color: props.color?.Btn?.textColor || '#fff',
                textTransform: 'capitalize',
              }}
            >
              {language === 'en' ? 'Go To Home Page' : 'إذهب الي الرئيسية'}
            </Button>
            {/* <Button
            sx={{
              ...publicButton,
              bgcolor: "transparent",
              color: colors.newMainColor,
              border: `1.5px solid ${colors.newMainColor}`,
            }}
            onClick={() => navigate("/cart")}
          >
            {language === "en" ? "Go To cart" : "إذهب إلي السلة"}
          </Button> */}
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export default ThanksPage

// import moment from 'moment'
type inputType = 
  {
    type: string
    name: 'name' | 'phone' | 'email' | 'password' | 'image'
    label_en: string
    label_ar: string
  }[]

export const inputs: inputType = [
  {
    type: 'text',
    name: 'name',
    label_en: 'Name',
    label_ar: 'الاسم',
  },
  {
    type: 'email',
    name: 'email',
    label_en: 'Email Address',
    label_ar: 'البريد الإلكتروني',
  },
  {
    type: 'number',
    name: 'phone',
    label_en: 'Phone number',
    label_ar: 'رقم الجوال',
  },

  {
    type: 'password',
    name: 'password',
    label_en: 'Password',
    label_ar: 'كلمة المرور',
  },
]

import { TabContext, TabPanel } from '@mui/lab'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import ForgetPassword from './ForgetPassword'
import { forgetPropsStyle } from './ForgetType'
import VerifyPassword from './VerifyPass/VerifyPassword'
import ResetPassword from './ResetPass/ResetPassword'

const ForgetPass = (props: forgetPropsStyle) => {
console.log(props);

  
  const [value, setValue] = useState('1')
  const [data,setData]=useState()
  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <TabPanel value="1">
          <ForgetPassword
          style={{
            borderInputColor:props.borderInputColor,
            placeholderColor:props.placeholderColor,
            backgroundColorBtn:props.backgroundColorBtn,
            colorBtn:props.colorBtn,
            borderRadius:props.borderRadius
            
          }}
            setValue={setValue}
            setData={setData}
          />
        </TabPanel>

        <TabPanel value="2">
          <VerifyPassword
            style={{
              borderInputColor:props.borderInputColor,
              placeholderColor:props.placeholderColor,
              backgroundColorBtn:props.backgroundColorBtn,
              colorBtn:props.colorBtn,
              borderRadius:props.borderRadius
  
            }}
            setValue={setValue}
            setData={setData}
          />
        </TabPanel>
        <TabPanel value="3">
          <ResetPassword
           style={{
            borderInputColor:props.borderInputColor,
            placeholderColor:props.placeholderColor,
            backgroundColorBtn:props.backgroundColorBtn,
            colorBtn:props.colorBtn,
            borderRadius:props.borderRadius

          }}
          setValue={setValue}
          data={data}
          setData={setData}
          />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default ForgetPass

import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useGetAllSlidersQuery } from '../../src/APIs/SectionApis'
import { heroVideoProps } from './HeroTypes'

// floating video with text

const Hero1 = (props: heroVideoProps) => {
  const [_, { language: lang }] = useTranslation()
  const { data, isError ,error,isLoading} = useGetAllSlidersQuery(undefined)
  const dataHero = data?.data.length - 1
  const colors = {
    primary: 'white',
    secondary: '#e3e3e3',
    backgroundPrimary: '#b0b4c5',
    backgroundSecondary: '#d2d9d5',
  }

  return (
    <Box minHeight={"100vh"}>
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isError && !isLoading && (

      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: { xs: '80vh', md: '100vh' },
         
        }}
      >
        {/* Video */}
        <Box>
          <video
            src={props.backgroundVideo}
            autoPlay
            muted
            loop
            style={{
              width: '100%',
              height: '100%',
              zIndex: -1,
              position: 'absolute',
              top: 0,
              objectFit: 'cover',
            }}
          />
        </Box>

        {/* layout */}
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.4)',
            
          }}
        />

        {/* title and description */}

        {/* Title and SubTitle */}
        <Box
          width={{ xs: 0.7, sm: 0.5, md: 0.9 }}
          sx={{
            position: 'absolute',
            top: '45%',
            left:lang==="en"? "50%": { xs: '50%', lg: '50%' },
            transform: 'translate(-50%,-50%)',
           
            direction:lang==="en"?"ltr":"rtl",
            boxSizing: 'border-box',
            padding: { xs: 2, sm: 3, md: 4 },
            
          }}
        >
          {/* Title */}
          <Typography
            variant="h1"
            sx={{
              position: 'relative',
              mb: 2,
              color: props.color?.titleColor,
              fontWeight: 'bold',
              fontSize: {
                xs: '1.5rem',
                sm: '3rem',
                md: '4rem',
                lg: '5rem',
                xl: '6rem',
              },
            }}
          >
            {data?.data[dataHero][`title_${lang}`]}
          </Typography>

          {/* sub title */}
          <Typography
            variant="h6"
            sx={{
              whiteSpace: 'normal',
              color: props.color?.subTitleColor,
              fontSize: {
                xs: '1rem',
                sm: '1.2rem',
                md: '1.3rem',
              },
              width: { xs: '100%', sm: '80%', md: '50%' },
              
              wordBreak: 'break-word',
            }}
          >
            {data?.data[dataHero][`description_${lang}`]}
          </Typography>
        </Box>
      </Box>
      )}
    </Box>
  )
}

export default Hero1

import * as yup from 'yup'

import { useTranslation } from 'react-i18next'
import { ProfileType } from './ProfileTypes'
import { useGetMeQuery } from '../src/APIs/UserApis'

export const ProfileFormik = () => {
  const [, { language: lang }] = useTranslation()
const {data,isSuccess} = useGetMeQuery(undefined)
  const profileData: ProfileType = {
    name:'',
    email: '',
    phone: '',
    password: '',
  }

  const profileValidation = yup.object<ProfileType>({
    name: yup.string(),
    email: yup
      .string()
      .email(lang === 'en' ? 'Email is invalid' : 'البريد الالكتروني غير صحيح'),
    phone: yup.string(),
    password: yup.string(),
  })

  return { profileData, profileValidation }
}

interface AmazonProps extends Omit<FooterProps, 'type' | 'information'> {}

import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FooterProps, KeyOfSocial } from '../FooterTypes'
import { useTranslation } from 'react-i18next'
import { ICategory, links } from '../Footer'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useGetAllCategoriesQuery } from '../../src/APIs/categoriesApi'
import Map from './Map'
import CallIcon from '@mui/icons-material/Call'

function Logo({
  logo,
  color,
  bgColor,
}: {
  logo: string
  color: AmazonProps['color']
  bgColor: AmazonProps['bgColor']
}) {
  const {
    i18n: { changeLanguage, language },
  } = useTranslation()
  const toggleLanguage = () => {
    if (language === 'en') {
      changeLanguage('ar')
    } else {
      changeLanguage('en')
    }
  }
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5,
      }}
    >
      <Box
        component={'img'}
        src={logo}
        sx={{
          width: '150px',
        }}
      />
      <select
        onChange={toggleLanguage}
        value={language}
        style={{
          display: 'block',
          color: color.primary,
          backgroundColor: bgColor.secondary,
          fontSize: '.9rem',
          padding: '5px 20px',
        }}
      >
        <option value="en">🌍English</option>
        <option value="ar">🌍Arabic</option>
      </select>
    </Box>
  )
}

function CategorySection({
  categories,
  color,
}: {
  categories: ICategory[]
  color: AmazonProps['color']
}) {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <>
      <Stack
        width={'100%'}
        direction={'column'}
        sx={{
          py: 4,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography
            sx={{
              color: color.primary,
              fontSize: 20,
              textAlign: { xs: 'center', md: 'start' },
            }}
          >
            {language === 'en' ? 'Categories' : 'الاقسام'}
          </Typography>
        </div>
        <Stack
          direction={'row'}
          gap={5}
          width={'100%'}
          sx={{
            pt: 2,
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {categories.map((category) => (
            <Box
              key={category._id}
              sx={{
                color: color.primary,
                textDecoration: 'none !important',
              }}
              component={Link}
              to={`/departments/${category._id}`}
            >
              {language === 'en' ? category.name_en : category.name_ar}
            </Box>
          ))}
        </Stack>
      </Stack>
    </>
  )
}

function ContentSection({ color }: { color: AmazonProps['color'] }) {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <Stack
      direction={'row'}
      gap={3}
      width={'100%'}
      justifyContent={'space-evenly'}
      alignItems={'center'}
      flexWrap={'wrap'}
      py={5}
    >
      {links.map((link) => (
        <Box
          key={link.path}
          sx={{
            color: color.primary,
            textDecoration: 'none !important',
            fontSize: 18,
            fontWeight: 'bold',
          }}
          component={Link}
          to={link.path}
        >
          {language === 'en' ? link.en : link.ar}
        </Box>
      ))}
    </Stack>
  )
}

import { FaFacebookF, FaLinkedinIn, FaPinterestP } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'
import { PiTwitterLogoFill } from 'react-icons/pi'
import { cloneElement } from 'react'

function SocialSection({ social }: { social: AmazonProps['social'] }) {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        py: 2,
        gap: 2,
        flexWrap: 'wrap',
      }}
    >
      {social?.phone && (
        <Box
          component={'a'}
          href={`tel:${social.phone}`}
          sx={{
            fontSize: 20,
            textAlign: { xs: 'center', md: 'start' },
            color: social?.color,
            textDecoration: 'none !important',
          }}
        >
          <CallIcon
            sx={{
              transform: 'translateY(5px)',
              width: '30px',
              height: '30px',
            }}
          />{' '}
          {social.phone}
        </Box>
      )}
      {social &&
        (Object.keys(social) as KeyOfSocial[]).map((key) => {
          const Icon = {
            facebook: FaFacebookF,
            instagram: FiInstagram,
            linkedin: FaLinkedinIn,
            twitter: PiTwitterLogoFill,
            pinterest: FaPinterestP,
          }[key]
          if (!Icon) return null
          return (
            <Box
              component={'a'}
              key={key}
              target="_blank"
              href={social[key]}
              sx={{
                color: social?.bgColor,
                fontSize: 20,
                textAlign: { xs: 'center', md: 'start' },
                backgroundColor: social?.color,
                borderRadius: '50%',
                width: '45px',
                height: '45px',
                display: 'grid',
                placeItems: 'center',
              }}
            >
              {cloneElement(<Icon />, {})}
            </Box>
          )
        })}
    </Stack>
  )
}

function Amazon({
  bgColor,
  color,
  variant,
  logo,
  map,
  footerText,
  social,
}: AmazonProps) {
  const {
    i18n: { language },
  } = useTranslation()
  const { data, isLoading, isSuccess, isError } = useGetAllCategoriesQuery(null)
  const { pathname } = useLocation()
  const categories = (data?.data || []) as ICategory[]

  const dir = language === 'ar' ? 'rtl' : 'ltr'
  return (
    <>
      <Box
        dir={dir}
        sx={{
          display:
            pathname === '/thankYou' ||
            pathname === '/sign-in' ||
            pathname === '/register'||
            pathname === '/forgetPassword'
              ? 'none'
              : 'block',
        }}
      >
        <Box
          sx={{
            backgroundColor: social?.bgColor,
            color: social?.color,
          }}
        >
          <SocialSection social={social} />
        </Box>
        <Box
          sx={{
            backgroundColor: bgColor?.secondary,
          }}
        >
          {isSuccess && (
            <CategorySection categories={categories} color={color} />
          )}
        </Box>
        <Box
          sx={{
            backgroundColor: bgColor?.secondary,
          }}
        >
          <Divider
            orientation="horizontal"
            style={{
              backgroundColor: color.primary,
            }}
          />
          <Logo logo={logo} bgColor={bgColor} color={color} />
          <Divider
            orientation="horizontal"
            style={{
              backgroundColor: color.primary,
            }}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: bgColor?.primary,
          }}
        >
          <ContentSection color={color} />
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              px: 10,
              pb: 5,
              height: 'fit-content',
              gap: 2,
              justifyContent:
                variant === 'form-map'
                  ? 'space-between'
                  : {
                      xs: 'center',
                      md: 'right',
                    },
            }}
          >
            {(variant === 'form' || variant === 'form-map') && (
              <Box>
                <Stack
                  sx={{
                    width: '100%',
                    gap: 2,
                    pt: 2,
                  }}
                >
                  <Box
                    component={'input'}
                    type="text"
                    placeholder={
                      language === 'en' ? 'Email' : 'البريد الالكتروني'
                    }
                    sx={{
                      color: color.primary,
                      '::placeholder': { color: color.secondary },
                      fontSize: {
                        xs: 10,
                        md: 20,
                      },
                      width: { xs: '100%', md: '50%' },
                      backgroundColor: 'transparent',
                      padding: '5px 10px',
                      border: 'none',
                      outline: 'none !important',
                      borderBottom: `1px solid ${color.primary}`,
                    }}
                  />
                  <Box
                    component={'textarea'}
                    placeholder={language === 'en' ? 'Write Here' : 'اكتب هنا'}
                    rows={5}
                    sx={{
                      color: color.primary,
                      '::placeholder': { color: color.secondary },
                      fontSize: {
                        xs: 10,
                        md: 20,
                      },
                      backgroundColor: 'transparent',
                      padding: '5px 10px',
                      outline: 'none !important',
                      border: `1px solid ${color.primary}`,
                    }}
                  />
                </Stack>
              </Box>
            )}
            {(variant === 'map' || variant === 'form-map') && (
              <Box
                sx={{
                  width: '300px',
                }}
              >
                <Map url={map?.url || ''} />
              </Box>
            )}
          </Box>

          <Typography
            sx={{
              color: color.secondary,
              fontSize: 18,
              textAlign: 'center',
              pb: 2,
            }}
          >
            {footerText}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default Amazon

// return (
//   <Stack
//     direction={'column'}
//     justifyContent={'center'}
//     alignItems={'center'}
//     sx={{
//       fontWeight: 'bold',
//     }}
//     dir={dir}
//   >
//     <Logo />
//     <Box bgcolor={bgColor.primary} width={'100%'}>
//       <Stack
//         direction={{
//           xs: 'column',
//           md: 'row',
//         }}
//         justifyContent={'center'}
//         gap={2}
//         pt={3}
//         px={2}
//         sx={{
//           height: '100%',
//         }}
//       >
//         <Stack
//           direction={'column'}
//           sx={{
//             width: {
//               xs: '100%',
//               md: '40%',
//             },
//             height: '100%',
//           }}
//         >
//           <Stack
//             direction={{
//               xs: 'column',
//               md: 'row',
//             }}
//             gap={2}
//             sx={{
//               width: '100%',
//               height: '100%',
//               justifyContent: 'space-between',
//               alignItems: { xs: 'center', md: 'flex-start' },
//               alignContent: 'flex-start',
//             }}
//           >
//             <ContentSection color={color} map={map} variant={variant} />
//             <Divider
//               orientation="vertical"
//               style={{
//                 backgroundColor: color.primary,
//               }}
//               flexItem
//             />
//             {isLoading && <div>loading...</div>}
//             {isSuccess && (
//               <CategorySection color={color} categories={categories} />
//             )}
//           </Stack>
//           {variant === 'form' && (
//             <Stack
//               sx={{
//                 width: '100%',
//                 gap: 2,
//                 pt: 2,
//               }}
//             >
//               <Box
//                 component={'input'}
//                 type="text"
//                 placeholder={
//                   language === 'en' ? 'Email' : 'البريد الالكتروني'
//                 }
//                 sx={{
//                   color: color.primary,
//                   '::placeholder': { color: color.secondary },
//                   fontSize: {
//                     xs: 10,
//                     md: 20,
//                   },
//                   backgroundColor: 'transparent',
//                   padding: '5px 10px',
//                   border: 'none',
//                   outline: 'none !important',
//                   borderBottom: `1px solid ${color.primary}`,
//                   width: {
//                     xs: '100%',
//                     md: '50%',
//                   },
//                 }}
//               />
//               <Box
//                 component={'textarea'}
//                 placeholder={language === 'en' ? 'Write Here' : 'اكتب هنا'}
//                 rows={5}
//                 sx={{
//                   color: color.primary,
//                   '::placeholder': { color: color.secondary },
//                   fontSize: {
//                     xs: 10,
//                     md: 20,
//                   },
//                   width: { xs: '100%', md: '90%' },
//                   backgroundColor: 'transparent',
//                   padding: '5px 10px',
//                   outline: 'none !important',
//                   border: `1px solid ${color.primary}`,
//                 }}
//               />
//             </Stack>
//           )}
//         </Stack>
//         <Divider
//           orientation="vertical"
//           style={{
//             backgroundColor: color.primary,
//           }}
//           flexItem
//         />
//         <SocialSection
//           variant={variant}
//           color={color}
//         />
//       </Stack>
//     </Box>
//     <Box
//       sx={{
//         backgroundColor: bgColor.secondary,
//         width: '100%',
//         minHeight: 60,
//         display: 'grid',
//         placeItems: 'center',
//       }}
//     >
//       <Typography
//         sx={{
//           color: color.primary,
//           fontSize: {
//             xs: 10,
//             md: 15,
//             lg: 20,
//           },
//         }}
//       >
//         {footerText}
//       </Typography>
//     </Box>
//   </Stack>
// )

import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import image from '../hero.jpg'
import { heroSolidProps } from './HeroTypes'
import { useNavigate } from 'react-router-dom'
import { imageBaseUrl } from '../../src/App'
import { useGetAllSlidersQuery } from '../../src/APIs/SectionApis'
import { useTranslation } from 'react-i18next'

const Hero5 = (props: heroSolidProps) => {
  const { data, isSuccess, isLoading, isError, error } =
    useGetAllSlidersQuery(undefined)
    const [_, { language: lang }] = useTranslation()
  const dataHero = data?.data.length - 1 //last item in array
  const navigate = useNavigate()
  const colors = {
    primary: 'gray',
    secondary: '#e3e3e3',
    backgroundPrimary: '#292a2d',
    backgroundSecondary: '#d2d9d5',
  }

  return (
    <Box>
      {isLoading && <span className="loader"></span>}
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}
      {!isLoading && isSuccess && (

      <Box
        sx={{
          height: { xs: '80vh', md: '100vh' },
          width: '100%',
          background: props.backgroundColor
            ? props.backgroundColor
            : 'linear-gradient(90deg ,#d3d3d3 30%,white 30%)',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          width={{ xs: 0.6, md: 0.4, xl: 0.5 }}
          height={{ xs: 0.5, sm: 0.8 }}
          sx={{}}
        >
          <img
            src={`${imageBaseUrl}${data?.data[dataHero].image}`}
            alt="hero"
            style={{ width: '100%', height: '100%' }}
          />
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            mt: 2,
          }}
        >
          <Button
            onClick={() => navigate('/departments')}
            variant="contained"
            sx={{
              mt: 2,
              color: props.Btn?.colorBtn
                ? props.Btn?.colorBtn
                : colors.secondary,
              backgroundColor: props.Btn?.backgroundColorBtn
                ? `${props.Btn?.backgroundColorBtn} !important`
                : `${colors.backgroundPrimary} !important`,
              p: '12px 50px',
              borderRadius: props.Btn?.borderRaduisBtn ? '25px' : '5px',
              textTransform: 'capitalize',
            }}
          >
            {props.Btn?.titleBtn ? props.Btn.titleBtn : 'lorem'}
          </Button>
        </Box>
      </Box>
      )}
    </Box>
  )
}

export default Hero5

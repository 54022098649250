import React, { useEffect } from 'react'
import { useGetAllCartsQuery } from '../src/APIs/cartApi'
import { Button, ButtonGroup, Box } from '@mui/material'
const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        p: {
          xs:1,
          md:5,
          lg:10
        },
        minHeight: "50vh"
      }}
    >
      {children}
    </Box>
  )
}
interface Data {
  metadata: {
    cart_id: string
    user_id: string
    total_quantity: number
  }
  amount: number
  description: string
  callback_url: string
}
const callback_url = 'https://bookstore.work/thankYou'
const publishable_api_key = 'pk_test_f9ReoAys1F41VUdEvfPQM9d3dRN1qV124habH74S'
const Creditcard = ({ amount, callback_url, description, metadata }: Data) => {
  useEffect(() => {
    // @ts-ignore
    Moyasar.init({
      element: '.creditcard',
      amount,
      currency: 'SAR',
      description,
      metadata,
      publishable_api_key,
      callback_url, 
      methods: ['creditcard'],
    })
  }, [])
  return <div className="creditcard"></div>
}
const Stcpay = ({ amount, callback_url, description, metadata }: Data) => {
  useEffect(() => {
    // @ts-ignore
    Moyasar.init({
      element: '.stcpay',
      amount,
      currency: 'SAR',
      description,
      publishable_api_key,
      callback_url,
      metadata,
      methods: ['stcpay'],
    })
  }, [])
  return <div className="stcpay"></div>
}

const Applepay = ({ amount, callback_url, description, metadata }: Data) => {
  useEffect(() => {
    // @ts-ignore
    Moyasar.init({
      element: '.applepay',
      amount,
      currency: 'SAR',
      description,
      publishable_api_key,
      callback_url,
      methods: ['applepay'],
      metadata,
      apple_pay: {
        country: 'SA',
        label: description,
        validate_merchant_url: 'https://api.moyasar.com/v1/applepay/initiate',
      },
    })
  }, [])
  return <div className="applepay"></div>
}

function PaymentMoyasar() {
  const [type, setType] = React.useState<'creditcard' | 'stcpay' | 'applepay'>(
    'creditcard'
  )

  const { data, isLoading, isSuccess } = useGetAllCartsQuery('')

  if (isLoading) {
    return <Box sx={{minHeight: "50vh"}}>loading ......</Box>
  }

  if (isSuccess) {
    const metadata = {
      cart_id: data._id,
      user_id: data?.data?.user?._id,
      total_quantity: data?.data?.onlineItems?.quantity,
    }
    const total = (data?.data?.onlineItems?.totalPrice || 0) * 100

    return (
      <Layout>
        <ButtonGroup
          variant="outlined" 
           
          sx={{
            color: '#896B60',
            bgColor: '#CC8241 !important',
            fontWeight: "bold",
            fontSize: '1rem',
            '&:hover': {bgColor: '#CC8241 '}
          }}
        >
          <Button
             sx={{
              color: '#896B60',
              bgColor: '#CC8241 !important',
              fontWeight: "bold",
              fontSize: '1rem',
              '&:hover': {bgColor: '#CC8241 '}
            }}
            disabled={type === 'creditcard'}
            onClick={() => setType('creditcard')}
          >
            creditcard
          </Button>
          <Button
           sx={{
            color: '#896B60',
            bgColor: '#CC8241 !important',
            fontWeight: "bold",
            fontSize: '1rem',
            '&:hover': {bgColor: '#CC8241 '}
          }}
            disabled={type === 'stcpay'}
            onClick={() => setType('stcpay')}
          >
            stcpay
          </Button>
          <Button
           sx={{
            color: '#896B60',
            bgColor: '#CC8241 !important',
            fontWeight: "bold",
            fontSize: '1rem',
            '&:hover': {bgColor: '#CC8241 '}
          }}
            disabled={type === 'applepay'}
            onClick={() => setType('applepay')}
          >
            applepay
          </Button>
        </ButtonGroup>
        <Box sx={{py: 10}}>
          {type === 'creditcard' && (
            <Creditcard
              amount={total}
              callback_url={callback_url}
              description={` pay ${total / 100}`}
              metadata={metadata}
            />
          )}
          {type === 'stcpay' && (
            <Stcpay
              amount={total}
              callback_url={callback_url}
              description={` pay ${total / 100}`}
              metadata={metadata}
            />
          )}
          {type === 'applepay' && (
            <Applepay
              amount={total}
              callback_url={callback_url}
              description={` pay ${total / 100}`}
              metadata={metadata}
            />
          )}
        </Box>
      </Layout>
    )
  }

  return <div className="">error</div>
}

export default PaymentMoyasar

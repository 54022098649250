import React, { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperCore } from 'swiper'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/free-mode'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/thumbs'

// eslint-disable-next-line import/no-unresolved
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
 

import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  Link,
  MenuItem,
  Rating,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { Attrs } from './Attrs'
import { TCartColorsData, TsingleProductColors } from './SingleProductTypes'
import { useParams } from 'react-router-dom'
import {
  useAddRatingMutation,
  useGetSingleProductQuery,
} from '../src/APIs/ProductApis'
import { useTranslation } from 'react-i18next'
import { imageBaseUrl } from '../src/App'
import { useAddToCartMutation } from '../src/APIs/cartApi'
import { toast } from 'react-toastify'
import { Breadcrumbs } from '@mui/joy'
import { NavLink } from 'react-router-dom'
import CustomBreadcrumb from './CustomBreadcrumb'
import CustomPaymentType from './CustomPaymentType'
import { useSelector } from 'react-redux'
const qty = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
]
export const JaririSingleProduct = ({ colors }: TsingleProductColors) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null)
  const { id } = useParams()
  const { data, isLoading, isError } = useGetSingleProductQuery(id)
  const [, { language: lng }] = useTranslation()
  const [cartData, setCartData] = useState<TCartColorsData>({
    quantity: 1,
    qualities: [],
    id: id,
  })
  const [addToCart] = useAddToCartMutation()
  const [rate] = useAddRatingMutation()
  const [qImage, setQimages] = useState([])

  const { currentUser } = useSelector((state) => state) as any

  const handleRating = (productId: string, rating: number | null) => {
    if (!currentUser) {
      toast.error(lng === 'en' ? 'Login first' : 'سجل دخول أولاً')
    } else {
      rate({ productId, rating })
        .unwrap()
        .then((res) => toast.success(res.success_en))
        .catch((e) => toast.error(e.data.error_en))
    }
  }

  useEffect(() => {
    const images = cartData.qualities.map((q) => {
      const images = data?.data.qualitiesImages.map((qi: any) => {
        const isSelect = qi.qualities.some((qiq: any) => {
          return qiq.value_en === q.value_en
        })

        if (isSelect) {
          return qi.image
        }
        return null
      })

      return images.filter(Boolean)
    })
    const images2 = images.filter((i) => i.length > 0).map((i) => i[0])

    setQimages(images2 as any)
  }, [cartData])
  const extraPrice = cartData.qualities
    .map((q) => {
      const price = data?.data.qualities
        .find((p: any) => p.key_en === q.key_en)
        ?.values.find((v: any) => v.value_en === q.value_en)?.price

      return price
    })
    .reduce((a, b) => a + b, 0)

  return (
    <>
      {isLoading && !data && !isError && (
        <Container sx={{ minHeight: '60vh' }}>
          <span className="loader" />
        </Container>
      )}
      {!isLoading && data && (
        <Grid
          container
          sx={{
            p: 5,
            direction: lng === 'en' ? 'rtl' : 'ltr',
          }}
        >
          <CustomBreadcrumb
            lng={lng}
            data={{
              title_ar: data?.data?.title_ar,
              title_en: data?.data?.title_en,
            }}
          />
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              textAlign: 'end',
              p: 5,
              border: '1px solid black',
              height: 'fit-content',
            }}
          >
            <Stack
              direction={{ xs: 'column', md: 'row-reverse' }}
              sx={{ gap: 3, justifyContent: 'end', alignItems: 'center' }}
            >
              <Box sx={{ width: 80 }}>
                <Select
                  sx={{ width: 80 }}
                  defaultValue={'1'}
                  onChange={(event: SelectChangeEvent<string>) =>
                    addToCart({
                      quantity: event.target.value,
                      id: data.data.id,
                    })
                      .unwrap()
                      .then((res) => {
                        toast.success(
                          res[`success_${lng === 'en' ? 'en' : 'ar'}`]
                        )
                      })
                      .catch((e) =>
                        toast.error(
                          e.data[`error_${lng === 'en' ? 'en' : 'ar'}`]
                        )
                      )
                  }
                >
                  {qty.map((q) => (
                    <MenuItem value={q} key={q} sx={{ color: 'black' }}>
                      {q}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Button
                variant="contained"
                sx={{
                  width: { md: '70%', xs: '100%' },
                  px: 2,
                  py: 1,
                  color: colors.buttonColor,
                  bgcolor: `${colors.buttonBgColor} !important`,
                }}
                onClick={() => {
                  const qualities =
                    cartData.qualities.length > 0
                      ? {
                          qualities: cartData.qualities,
                        }
                      : {}
                  addToCart({
                    quantity: cartData.quantity,
                    id: cartData.id,
                    ...qualities,
                  })
                    .unwrap()
                    .then((res) =>
                      toast.success(
                        res[`success_${lng === 'en' ? 'en' : 'ar'}`]
                      )
                    )
                    .catch((e) =>
                      toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
                    )
                }}
              >
                {lng === 'en' ? 'Add to cart' : 'أضف إلى سلة التسوق'}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: 'end', px: 5 }}>
            <Stack gap={2} alignItems={'end'}>
              <Typography
                sx={{
                  color: colors.titleColor,
                  fontSize: '1.7rem',
                  fontWeight: 'bold',
                  textAlign: lng === 'en' ? 'left' : 'right',
                }}
              >
                {data.data[`title_${lng === 'en' ? 'en' : 'ar'}`]}
              </Typography>

              {data.data.priceAfterDiscount > 0 && (
                <Typography
                  sx={{
                    color: colors.priceAfterColor,
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                  }}
                >
                  {data.data.priceAfterDiscount + extraPrice}{' '}
                  {lng === 'en' ? 'SAR' : 'ر.س'}
                </Typography>
              )}
              <Typography
                sx={{
                  color: colors.priceBeforeColor,
                  textDecoration:
                    data.data.priceAfterDiscount > 0 ? 'line-through' : 'none',
                  fontSize:
                    data.data.priceAfterDiscount > 0 ? '0.7rem' : '1.5rem',
                }}
              >
                {data.data.priceBeforeDiscount + extraPrice}{' '}
                {lng === 'en' ? 'SAR' : 'ر.س'}
              </Typography>
              {data.data.qualities.map(
                (quality: {
                  key_en: string
                  key_ar: string
                  values: { value_en: string; value_ar: string }[]
                  _id: string
                }) => (
                  <Attrs
                    key={quality._id}
                    colors={{
                      attrKeyColor: colors.attrKeyColor,
                      attrValueColor: colors.attrValueColor,
                      attrValueBgColor: colors.attrValueBgColor,
                      attrValueBorderColor: colors.attrValueBorderColor,
                    }}
                    attr={quality}
                    setCartData={setCartData}
                    cartData={cartData}
                  />
                )
              )}

              <Rating
                sx={{ direction: 'ltr' }}
                value={data.data.rating}
                onChange={(_, newValue) => {
                  // rate({ productId: data.data._id, rating: newValue })
                  handleRating(data.data._id, newValue)
                }}
              />
              <Box
                sx={{ color: colors.descColor,
                  
                  '& ul': { direction: lng === 'en' ? 'ltr' : 'rtl', textAlign: lng === 'en' ? 'left' : 'right' },
                  '& ol': { direction: lng === 'en' ? 'ltr' : 'rtl', textAlign: lng === 'en' ? 'left' : 'right' },
                  
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    data.data[`description_${lng === 'en' ? 'en' : 'ar'}`],
                }}
              />
              <CustomPaymentType type={data.data.paymentType} lang={lng} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} sx={{ order: { xs: -1, md: 'unset' } }}>
            {/* <Stack
              direction={'row'}
              sx={{
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            > */}
            <Box
              component={Swiper}
              sx={{
                width: '100%',
                m: 1,
                height: '50vh',
                direction: 'ltr',
                '--swiper-navigation-color': '#fff',
              }}
              // spaceBetween={10}
              // thumbs={{ swiper: thumbsSwiper }}
              // loop={true}
              // modules={[FreeMode, Thumbs]}

              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            >
              {qImage.length > 0
                ? qImage.map((img: string) => (
                    <SwiperSlide key={img}>
                      <Box
                        sx={{
                          background: `url("${
                            imageBaseUrl + img
                          }") center center`,
                          backgroundSize: ' contain',
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </SwiperSlide>
                  ))
                : data?.data?.images.map((img: string) => (
                    <SwiperSlide key={img}>
                      <Box
                        sx={{
                          background: `url("${
                            imageBaseUrl + img
                          }") center center`,
                          backgroundSize: ' contain',
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </SwiperSlide>
                  ))}
            </Box>
            <Box
              component={Swiper}
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper"
              sx={{
                py: 2,
              }}
            >
              {qImage.length > 0
                ? qImage.map((img: string) => (
                    <SwiperSlide key={img}>
                      <Box
                        component={'img'}
                        sx={{
                          width: '100%',
                          height: '100px',
                          objectFit: 'contain',
                        }}
                        src={imageBaseUrl + img}
                      />
                    </SwiperSlide>
                  ))
                : data?.data?.images.map((img: string) => (
                    <SwiperSlide key={img}>
                      <Box
                        component={'img'}
                        sx={{
                          width: '100%',
                          height: '100px',
                          objectFit: 'contain',
                        }}
                        src={imageBaseUrl + img}
                      />
                    </SwiperSlide>
                  ))}
            </Box>
            {/* </Stack> */}
          </Grid>
        </Grid>
      )}
    </>
  )
}

import { Box, Container } from '@mui/material'
import { PrivacyTypes } from './PrivacyTypes'
import { useTranslation } from 'react-i18next'

export const NoImage = ({ data }: PrivacyTypes) => {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <Container sx={{ my: 5, color: '#4f3124' }}>
      <Box
      component={'h1'}
        sx={{
           
          direction: language === 'en' ? 'ltr' : 'rtl',
          textAlign: language === 'en' ? 'left' : 'right',
          '& p': { textAlign: language === 'en' ? 'left' : 'right' },
          '& ul': { textAlign: language === 'en' ? 'left' : 'right' },
          '& ol': { textAlign: language === 'en' ? 'left' : 'right' },
        }}
        dangerouslySetInnerHTML={{
          __html:
            language === 'en' ? data?.title_en : data?.title_ar,
        }}
      />
      <Box
        sx={{
          lineHeight: 2,
          fontSize: 15,
          direction: language === 'en' ? 'ltr' : 'rtl',
          textAlign: language === 'en' ? 'left' : 'right',
          '& p': { textAlign: language === 'en' ? 'left' : 'right' },
          '& ul': { textAlign: language === 'en' ? 'left' : 'right' },
          '& ol': { textAlign: language === 'en' ? 'left' : 'right' },
        }}
        dangerouslySetInnerHTML={{
          __html:
            language === 'en' ? data?.description_en : data?.description_ar,
        }}
      />
    </Container>
  )
}
